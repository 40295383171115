import { Injectable } from '@angular/core';
import { SFLogger, KEYS } from 'sf-logger-javascript';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';
import { v1 as uuidv1 } from 'uuid';
import { splunk } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class SFLoggerService {

    private logger: any;
    private correlationId: string;
    private applicationId = 'repair-assistant'
    
    constructor(private cookieService: CookieService) {
        this.correlationId = uuidv1();

        const loggerConfig = {
            sparkl: {
                env: splunk.env,
                endpointType: 'public'
            },
            httpLoggingEnabled: true,
            consoleLoggingEnabled: false 
        }

        // creating a logger object, initial key/value pairs are stored in logger context keys
        const logger = SFLogger(loggerConfig, KEYS.APPLICATION_ID, this.applicationId, KEYS.CORRELATION_ID, this.correlationId);
        logger.setAuthToken(splunk.api);
        this.logger = logger;
    }

    setContext(details: any[]) {
        this.logger.setContext(...details);
    } 

    sendLog(messageId: string, message: string, details?: any[]) {
        if (details) {
            details.push(SFLoggerFields.AUTHENTICATION, this.retrieveTokenLevel());
            this.logger.info(messageId, message, ...details);
        } else {
            this.logger.info(messageId, message, SFLoggerFields.AUTHENTICATION, this.retrieveTokenLevel())
        }
    }

    getCorrelationId() {
        return this.correlationId;
    }

    retrieveTokenLevel(): string {
        let tokenLevel = '';

        if (this.cookieService.check('sf-cauth1') === true) {
            tokenLevel = 'OKTA LOA4';
        } else if (this.cookieService.check('sf-cauth-lite') === true) {
            tokenLevel = 'OKTA LOA2';
        } else {
            tokenLevel = 'UNKNOWN';
        }
      
        return tokenLevel;
    }
}