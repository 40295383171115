import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class RentalRatesInfoService {
    economyRatePriceInclusiveHertz: string;
    intermediateRatePriceInclusiveHertz: string;
    compactRatePriceInclusiveEnterprise: string;
    intermediateRatePriceInclusiveEnterprise: string;

    constructor (
        private storageService: StorageService
    ) {}

    getEconomyRatePriceInclusiveHertz() {
        if (this.economyRatePriceInclusiveHertz === undefined) {
            this.economyRatePriceInclusiveHertz = this.storageService.getSessionStorage('economyRatePriceInclusiveHertz');
        }
        return this.economyRatePriceInclusiveHertz;
    }

    setEconomyRatePriceInclusiveHertz(ratePrice: string) {
        this.economyRatePriceInclusiveHertz = ratePrice;
        this.storageService.setSessionStorage('economyRatePriceInclusiveHertz', this.economyRatePriceInclusiveHertz);
    }

    getIntermediateRatePriceInclusiveHertz() {
        if (this.intermediateRatePriceInclusiveHertz === undefined) {
            this.intermediateRatePriceInclusiveHertz = this.storageService.getSessionStorage('intermediateRatePriceInclusiveHertz');
        }
        return this.intermediateRatePriceInclusiveHertz;
    }

    setIntermediateRatePriceInclusiveHertz(ratePrice: string) {
        this.intermediateRatePriceInclusiveHertz = ratePrice;
        this.storageService.setSessionStorage('intermediateRatePriceInclusiveHertz', this.intermediateRatePriceInclusiveHertz);
    }

    getCompactRatePriceInclusiveEnterprise() {
        if (this.compactRatePriceInclusiveEnterprise === undefined) {
            this.compactRatePriceInclusiveEnterprise = this.storageService.getSessionStorage('compactRatePriceInclusiveEnterprise');
        }
        return this.compactRatePriceInclusiveEnterprise;
    }

    setCompactRatePriceInclusiveEnterprise(ratePrice: string) {
        this.compactRatePriceInclusiveEnterprise = ratePrice;
        this.storageService.setSessionStorage('compactRatePriceInclusiveEnterprise', this.compactRatePriceInclusiveEnterprise);
    }

    getIntermediateRatePriceInclusiveEnterprise() {
        if (this.intermediateRatePriceInclusiveEnterprise === undefined) {
            this.intermediateRatePriceInclusiveEnterprise = this.storageService.getSessionStorage('intermediateRatePriceInclusiveEnterprise');
        }
        return this.intermediateRatePriceInclusiveEnterprise;
    }

    setIntermediateRatePriceInclusiveEnterprise(ratePrice: string) {
        this.intermediateRatePriceInclusiveEnterprise = ratePrice;
        this.storageService.setSessionStorage('intermediateRatePriceInclusiveEnterprise', this.intermediateRatePriceInclusiveEnterprise);
    }
}
