import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Interactions } from 'app/model/interactions.model';
import { WindowReference } from '../model/window-reference.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class InteractionsService {

    private interactions: Interactions;

    constructor(
        private http: HttpClient,
        private windowRef: WindowReference,
        private cookieService: CookieService,
    ) {
        this.interactions = new Interactions();
    }

    sendInteractions(event: string, intent: string) {
        this.setTime();
        this.setIdentifiers();
        this.setReferralValues();
        this.interactions.enterprise_interaction_event_text = event;
        this.interactions.enterprise_interaction_intent_name = intent;
        const body = JSON.stringify(this.interactions);
        this.http
            .post(this.buildLogUrl(), body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'eventName': this.interactions.enterprise_interaction_event_text,

                })
            })
            .subscribe(res => { }, error => { });
    }

    setClaimData(externalClaimId: string, externalClientId: string, role: string) {
        this.interactions.CLM_ID = externalClaimId;
        this.interactions.CLNT_ID = externalClientId + '-CLMS MSGS 2 CUST';
        this.interactions.claims_role_name = (role === 'NI' ? 'NamedInsured' : 'Claimant');
    }

    setProducerData(participantId: string, vehicleNumber: string, make: string, model: string, year: string, claimStateCode: string) {
        this.interactions.participant_identifier = participantId;
        this.interactions.vehicle_number = vehicleNumber;
        this.interactions.vehicle_make_name = make;
        this.interactions.vehicle_model_name = model;
        this.interactions.vehicle_year_number = year;
        this.interactions.claim_state_number = claimStateCode;
    }

    setTime() {
        const currentDate = new Date();

        const currentDayOfMonth = String(currentDate.getUTCDate()).padStart(2, '0');
        const currentMonth = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
        const currentYear = currentDate.getUTCFullYear();
        const currentHour = String(currentDate.getUTCHours()).padStart(2, '0');
        const currentMinutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
        const currentSeconds = String(currentDate.getUTCSeconds()).padStart(2, '0');
        const currentMilliSeconds = String(currentDate.getUTCMilliseconds()).padStart(3, '0');

        const timeStamp = currentYear + '-' + currentMonth + '-' + currentDayOfMonth + '-' + currentHour + '.' + currentMinutes + '.' + currentSeconds + '.' + currentMilliSeconds;
        const timeZone = currentDate.toLocaleDateString(undefined, {
            day: '2-digit',
            timeZoneName: 'short'
        }).substring(4);
        this.interactions.producer_interaction_timestamp = timeStamp;
        this.interactions.producer_interaction_timezone = timeZone;
    }

    setIdentifiers() {
        this.interactions.enterprise_interaction_identifier = this.cookieService.get('enterprise_interaction_identifier');
        this.interactions.state_farm_user_identifier = this.cookieService.get('sfuid');
    }

    setLOA(loa: string) {
        this.interactions.level_of_assurance_number = loa;
    }

    setRepairShopId(repairShopId: string) {
        this.interactions.repair_shop_identifier = repairShopId;
    }

    setRentalCoverageIndicator(rentalCoverageIndicator: string) {
        this.interactions.rental_coverage_indicator = (rentalCoverageIndicator === 'true') ? 'eligible' : 'ineligible';
    }

    setRentalLocationIdentifier(rentalLocationIdentifier: string) {
        this.interactions.rental_location_identifier = rentalLocationIdentifier;
    }

    setReferralValues() {
        this.interactions.referral_page_name = this.windowRef.nativeWindow['dataLayer'].da.previousPageName ? this.windowRef.nativeWindow['dataLayer'].da.previousPageName : '';
        if(document.referrer.includes('statefarm.com')){
            this.interactions.referral_page_url_text = document.referrer;
        }
    }

    setDeviceTypeName(deviceTypeName: string) {
        this.interactions.device_type_name = deviceTypeName;
    }

    buildLogUrl() {
        return this.windowRef.nativeWindow._config.interactions.url;
    }
}
