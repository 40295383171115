export enum VehicleClassCode {
    NONE,
    ENTERPRISEINTERMEDIATE,
    HERTZINTERMEDIATE,
    COMPACT,
    ECONOMY
  }

  export const VehicleClass = new Map<number, string>([
    [VehicleClassCode.NONE, 'Decide on a vehicle later'],
    [VehicleClassCode.ENTERPRISEINTERMEDIATE, 'Enterprise intermediate vehicle'],
    [VehicleClassCode.HERTZINTERMEDIATE, 'Hertz intermediate vehicle'],
    [VehicleClassCode.COMPACT, 'Enterprise compact vehicle'],
    [VehicleClassCode.ECONOMY, 'Hertz economy vehicle']
  ]);

    // Class codes, their Hertz and SF codes as reference.
    // ECONOMY('A', 'EC'),
    // INTERMEDIATE('C', 'IC'),
    // COMPACT('B', 'CC'),
    // STANDARD('D', 'SC'),
    // FULL_SIZE('F', 'FC'),
    // PREMIUM('G', 'PC'),
    // LUXURY('I', 'LC'),
    // SUV('L', 'SS'),
    // CARGO_VAN('N4', 'PV'),
    // MIDSIZE_SUV('Q4', 'MS'),
    // MINIVAN('R', 'MV'),
    // LRG_PICKUP_TRK('S', 'PT'),
    // PREMIUM_SUV('T', 'FS');
    // TWELVE_PASS_VAN("M", "PW");
