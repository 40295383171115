import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const dataLayer = {
  title: '',
  pageName: '',
  pageChannel: '',
  screenId: '',
  screenChannel: '',
  product: '',
  visitor: '',
  vehicleCert: '',
  language: '',
  state: '',
  clientID: '',
  availableShop: '',
  peLinkOption: '',
  urlPath: {},
  queryParams: {},
  deploymentIds: [],
  vehicleMake: 'none'
};

@Injectable()
export class DataAnalyticsService {
  constructor(@Inject(DOCUMENT) private document: any) {
    window['dataLayer'] = window['dataLayer'] || dataLayer;
  }

  init(
    myChannel: string,
    authentication: string,
    externalClaimId: string,
    externalClientId: string,
    role: string,
    accessMode: string
  ) {
    window['dataLayer'].screenChannel = myChannel;
    window['dataLayer'].myChannel = myChannel;
    window['dataLayer'].authentication = authentication;
    window['dataLayer'].externalClaimId = externalClaimId;
    window['dataLayer'].externalClientId = externalClientId;
    window['dataLayer'].role = role;
    window['dataLayer'].roleType = role;
    window['dataLayer'].version = accessMode;
    console.log(
      'data to be initialized - extClaimId: ' +
      externalClaimId +
      ' extClientId: ' +
      externalClientId
    );
  }

  setClaimNumber(claimNumber: String) {
    window['dataLayer'].claimNumber = claimNumber;
    let maskedClaimNumber = '';
    if (!!claimNumber) {
      maskedClaimNumber = '*******';
    }
  }

  setCatastropheEligible(catastropheEligible: boolean) {
    window['dataLayer'].catatstropheEligible = catastropheEligible ? 'catastrophe' : 'regular';
  }

  sendPageData(myId: String): any {
    console.log('Sending data: ' + myId);
    window['dataLayer'].myId = myId;
    window['dataLayer'].screenId = myId;
    document.dispatchEvent(
      new CustomEvent('screenChange', { bubbles: true, cancelable: false })
    );
  }

  setPeLinkOption(peLinkOption: String) {
    window['dataLayer'].peLinkOption = peLinkOption;
  }

  setPageDataWithCount(page: string, count: number): string {
    switch (count) {
      case 1:
        page = 'second-' + page;
        break;
      case 2:
        page = 'third-' + page;
        break;
      default:
        page = page;
        break;
    }
    return page;
  }

  setScreenChannel(channel: string) {
    window['dataLayer'].screenChannel = channel;
  }

  setVehicleCert(vehicleCert: string) {
    window['dataLayer'].vehicleCert = vehicleCert;
  }

  setNationalCert(nationalCert: string) {
    window['dataLayer'].nationalCert = nationalCert;
  }

  setAuthLevel(loggedIn: boolean) {
    window['dataLayer'].authentication = loggedIn ? 'full' : 'partial';
  }

  setSearchDetails(searchType: string, distance: string) {
    window['dataLayer'].searchtype = searchType;
    window['dataLayer'].distance = distance;
  }

  setShopIndex(index: number) {
    window['dataLayer'].ordinalSearchRanking = index;
  }

  setShopUnavailability(shopUnavailability: boolean) {
    window['dataLayer'].availableShop = shopUnavailability ? 'notAvailable' : 'available';
  }

  setFilters(sort: string, certs: string[], nationalProviders: boolean) {
    let filters: string;
    filters = sort === 'PERFORMANCE' ? 'sort:featured|' : 'sort:distance|';
    if(certs.length > 0) {
      filters = filters + 'cert='
      certs.forEach((cert, index) => {
        if(index === (certs.length - 1)){
          filters = filters + cert.toLowerCase() + '|'
        } else{
          filters = filters + cert.toLowerCase() + ','
        }
      })
    }
    filters = nationalProviders ? filters + 'natprovider:yes' : filters + 'natprovider:no'
    window['dataLayer'].filters = filters;
  }

  setClaimStatus(closed:boolean){
    window['dataLayer'].claimsStatus = closed ? 'C' : 'O';
  }

  setVehicleMake(vehicleMake:string) {
    window['dataLayer'].vehicleMake = vehicleMake;
  }
}
