export class PlacesDetails {
    placeId?: string;
    placeName?: string;
    open_status?: string;
    open_now?: string;
    open_now_alt?: string;
    weekday_text?: string[];
    day?: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    dayIndexToday?: number;
    rating?: number;
    user_ratings_total?: number;
    website?: string;
    websiteUrl?: string;
    photo?: string;
}
