<!-- Navigation -->
<app-navigation-breadcrumb></app-navigation-breadcrumb>

<div class="pe-landing-page -oneX-container -oneX">
    <div class="-oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-1 -oneX-col-lg-6 -oneX-offset-xl-1 -oneX-col-xl-6">

        <!-- Comes From Non Program Flow Text -->
        <div *ngIf="isNonProgram">
            <div class="non-program-text -oneX-body--intro">Your vehicle has minor exterior damage, so we recommend using the Photo Estimate tool.</div>
            <hr class="-oneX-hr">
        </div>

        <!-- Photo Estimate Title -->
        <div class="-oneX-heading--h1 pe-landing-title">Photo Estimate</div>

        <!-- Skeleton Loader-->
        <app-skeleton *ngIf="!contentLoaded" class="generic-page-banner"></app-skeleton>

        <!-- Actual Content-->
        <div *ngIf="contentLoaded"></div>

        <!-- Regular landing page content -->
        <ng-container *ngIf="clientService.getHasPEAssignment() === false">
            <!-- How It Works -->
            <div class="-oneX-heading--h5 -oneX-font--semi">How it works</div>

            <ol class="how-it-works-content">
                <li class="how-it-works -oneX-font--med">We’ll send you a link
                    <br>
                    <span class="how-it-works-text -oneX-body--primary">We’ll send a text/email with a link to the Photo Estimate tool.</span>
                    <span class="picto -oneX-pictogram" data-pictogram="mobile-phone"></span>
                </li>
                <li class="how-it-works -oneX-font--med">Take photos
                    <br>
                    <span class="how-it-works-text -oneX-body--primary">Use the Photo Estimate tool on your mobile device to take guided photos of your vehicle damage.</span>
                    <span class="picto -oneX-pictogram" data-pictogram="car-on-phone"></span>
                </li>
                <li class="how-it-works -oneX-font--med">Receive an {{(complianceVerbiage$ | async)}} and get paid
                    <br>
                    <span class="how-it-works-text -oneX-body--primary">You'll receive an {{(complianceVerbiage$ | async)}} and payment within 48 hours that you can take to the shop of your choice for repairs.</span>
                    <span class="picto -oneX-pictogram" data-pictogram="pay"></span>
                </li>
                <li class="how-it-works -oneX-font--med">Get a supplemental {{(complianceVerbiage$ | async)}}
                    <br>
                    <span class="how-it-works-text -oneX-body--primary">If your repair shop finds additional damage, we’ll work directly with them to provide an {{(complianceVerbiage$ | async)}} and payment.</span>
                    <span class="picto -oneX-pictogram" data-pictogram="tools"></span>
                </li>
            </ol>

            <!-- Request A Link Button / Use Select Service Link -->
            <div class="btn-link-container">
                <!-- Request A Link Button -->
                <!-- ID added for data tagging - do not change without contacting Data Capture  -->
                <button id="pe-request-link-btn"
                        class="link-btn -oneX-btn-primary"
                        (click)="goToPhotoEstimateContact()">Request a link
                </button>

                <!-- Select Service Shop Link -->
                <!-- ID added for data tagging - do not change without contacting Data Capture  -->
                <a id="pe-landing-select-service-link"
                class="-oneX-link--block"
                (click)="goToShopSearch()"
                (keydown.enter)="goToShopSearch()"
                tabindex="0">Use a Select Service shop instead</a>
            </div>
        </ng-container>

        <!-- Assignment already exists content -->
        <ng-container *ngIf="clientService.getHasPEAssignment() === true">
            <div class="-oneX-heading--h3 -oneX-font--semi">You’ve already requested a link</div>

            <div class="-oneX-body--primary request-new-link-body">If you’d like us to send you a new one, please contact your claim handler at <a *ngIf="isDeviceMobile" href="tel: 800-SF-CLAIM" class="-oneX-link--inline"><span>800-SF-CLAIM</span></a> <span *ngIf="!isDeviceMobile">800-SF-CLAIM</span>.</div>

        </ng-container>
    </div>
</div>
