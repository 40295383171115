import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// SERVICES
import { SearchResultsService } from 'app/service/search-results.service';
import { ServiceClientsService } from 'app/service/service-clients.service';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { WINDOW } from '../service/window.service';

// MODELS
import { WindowReference } from '../model/window-reference.model';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

@Component({
  selector: 'app-non-program',
  templateUrl: './non-program.component.html',
  styleUrls: ['./non-program.component.css']
})
export class NonProgramComponent implements OnInit, AfterViewInit, OnDestroy {
    externalClaimId: string;
    externalClientId: string;
    role: string;

    screen: string;
    shopName: string;

    complianceVerbiage$: Observable<string>;

    constructor(
      @Inject(WINDOW) private window: Window,
      private router: Router,
      private windowRef: WindowReference,
      private clientService: ServiceClientsService,
      private searchResultService: SearchResultsService,
      private dataAnalyticsService: DataAnalyticsService,
      private urlResolverService: UrlResolverService,
    ) { }

    ngOnInit(): void {
      this.screen = 'non-program';

      this.complianceVerbiage$ = this.clientService.getComplianceVerbiage();

      this.externalClaimId = this.urlResolverService.getClaimId();
      this.externalClientId = this.urlResolverService.getClientId();
      this.role = this.urlResolverService.getRole();

      let searchTerm = this.searchResultService.getSearchedCriteria().searchTerm;
      this.shopName = searchTerm.substring(0, searchTerm.indexOf(","));
    }

    ngAfterViewInit(): void {
      this.window['oneX'].addElement(document.querySelector(`#go-back`));
      this.window['oneX'].addElement(document.querySelector(`#repair-tool`));
      this.dataAnalyticsService.sendPageData('photo-estimate-option');
      if(this.screen == 'damage-question'){
        this.dataAnalyticsService.sendPageData('photo-estimate-vehicle-conditions');
      } else if(this.screen == 'major-damage') {
        this.dataAnalyticsService.sendPageData('photo-estimate-vehicle-conditions-indicated');
      }
    }

    back() {
      switch(this.screen) {
        case 'non-program':
          this.goToRepairLanding();
          break;
        case 'damage-question':
          this.screen = 'non-program';
          this.dataAnalyticsService.sendPageData('photo-estimate-option');
          break;
        case 'major-damage':
          this.screen = 'damage-question';
          this.dataAnalyticsService.sendPageData('photo-estimate-vehicle-conditions');
          break;
        default:
          this.screen = 'non-program';
          break;
      }
    }

    goToRepairLanding() {
      this.windowRef.navigateTo(this.urlResolverService.getRAUrl());
    }

    goToDamageQuestion() {
      this.screen = 'damage-question';
      this.dataAnalyticsService.sendPageData('photo-estimate-vehicle-conditions');
    }

    goToMajorDamage() {
      this.screen = 'major-damage';
      this.dataAnalyticsService.sendPageData('photo-estimate-vehicle-conditions-indicated');
    }

    goToPhotoEstimateLanding() {
      this.router.navigate([
        'photoestimate/claim/' + this.externalClaimId + '/role/' + this.role + '/client/' + this.externalClientId
      ])
    }

    ngOnDestroy(): void {
      this.window['oneX'].removeElement(document.querySelector(`#go-back`));
      this.window['oneX'].removeElement(document.querySelector(`#repair-tool`));
    }
}
