import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
  public visible = false;
  visibleAnimate = false;
  isAlternateModal = false;
  modalId: string;

  constructor(private elem: ElementRef) {}

  public showAlternateModal(id: string): void {
    this.modalId = id;
    this.isAlternateModal = true;
    this.show();
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => (this.visibleAnimate = true), 100);
    document.getElementById('app-modal').focus();
  }

  public hide(): void {
    this.visible = false;
    setTimeout(() => (this.visibleAnimate = false), 300);
    this.isAlternateModal = false;
  }

  public isHidden(): boolean {
    return (!this.visible);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }
}
