import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UrlValidationGuard  {
    regExClmId = new RegExp('^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$');
    regExClientId = new RegExp('^[a-zA-Z0-9]{20}$');
    regExRoleType = new RegExp('^NI$|^C$');

    constructor(
        private router: Router,
    ) { }

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const extClmId: string = next.queryParams['claim'];
        const extClientId: string = next.queryParams['client'];
        const roleType: string = next.queryParams['role'];
        if (extClmId && extClientId && roleType) {
            return of((this.checkExternalClaimId(extClmId.trim()) && this.checkExternalClientId(extClientId.trim()) && this.checkRoleType(roleType.trim())) ? true : this.router.createUrlTree(['/shopsearch']));
        } else {
            return of(true);
        }

    }

    checkExternalClaimId(extClmId: string): boolean {
        return this.regExClmId.test(extClmId);
    }

    checkExternalClientId(extClientId: string): boolean {
        return this.regExClientId.test(extClientId);
    }

    checkRoleType(roleType: string): boolean {
        return this.regExRoleType.test(roleType);
    }
}

