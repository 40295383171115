<div
  class="faq-center help-still-have-questions -oneX-heading--h4"
  id="help-still-have-questions-text"
>
  Still have questions?
</div>
<div
  [ngClass]="
    faqPage
      ? '-oneX-row faq-center help-give-us-a-call-tablet help-give-us-a-call-mobile'
      : '-oneX-row help-still-have-questions-padding help-give-us-a-call-tablet help-give-us-a-call-mobile'
  "
>
  <div>
    <div class="help-give-us-a-call-icon">
      <div
        class="hide-on-mobile -oneX-icon-container"
        id="help-phone-icon-container"
      >
        <a
          class="-oneX-icon -oneX-icon--variant1"
          data-icon="phone_24"
          id="phone-icon-tablet"
          href="tel:18007325246"
          role="button"
          aria-label="give us a call"
        >
    </a>
      </div>
      <div
        tabindex="0"
        class="hide-on-tablet-and-desktop -oneX-icon-container"
        id="help-phone-icon-container-mobile"
      >
        <div
          class="-oneX-icon -oneX-icon--variant1"
          data-icon="phone_24"
          id="phone-icon-mobile"
        ></div>
      </div>
    </div>
  </div>
  <span
    class="
      hide-on-mobile
      help-give-us-a-call-padding help-give-us-a-call-text
      -oneX-body--primary
    "
    >Give us a call at 800-732-5246</span
  >
  <!-- Do not change the id below - it is being used by analytics to track clicks -->
  <a
    href="tel:18007325246"
    class="
      hide-on-tablet-and-desktop
      help-give-us-a-call-padding
      help-give-us-a-call-text-mobile
      -oneX-link--inline -oneX-link--block
    "
    id="help-give-us-a-call"
    role="button"
    aria-label="give us a call"
    >Give us a call</a
  >
</div>
