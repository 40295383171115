<div [id]="drawerid" class="-oneX -oneX-drawer-container">
  <div role="dialog" class="-oneX-drawer-content" [attr.aria-labelledby]="drawerid+'_heading'">
      <div [id]="drawerid+'_action'" class="-oneX-drawer-heading" tabindex="-1">
          <ng-content select=".drawer-head"></ng-content>
      </div>
      <div class="-oneX-drawer-body">
          <ng-content [ngIf]="isClaimsHelp"></ng-content>
          <ng-content [ngIf]="!isClaimsHelp" select=".drawer-body"></ng-content>
      </div>
      <div class="-oneX-drawer-footer">
          <ng-content select=".drawer-footer"></ng-content>
      </div>
      <button id="drawer-close" type="button" class="-oneX-close-X-button" aria-label="Close Drawer" onclick="oneX.Drawer.hideDrawer(this)" (click)="closeevent()">
          <span class="-oneX-icon--close"></span>
      </button>
  </div>
</div>
