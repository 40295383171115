export class Certifications {
    public static CERTIFICATIONS: Map<string, string[]> = new Map<string, string[]>([
        ['Tesla',['TESLA AUTHORIZED COLLISION CENTER']],
        ['Rivian',['RIVIAN CERTIFIED FLEET CENTER', 'RIVIAN CERTIFIED COLLISION CENTER']]
    ]);

    public static MSO_ID: Map<string, string> = new Map<string, string>([
        ['Camper/RV','00001131'],
    ]);
}
