<section class="-oneX-breadcrumbs help-breadcrumb-padding">
  <div class="help-breadcrumb-margins">
    <nav aria-label="breadcrumb">
      <ul itemscope="" itemtype="http://schema.org/BreadcrumbList">
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" [routerLink]="['/', { outlets: { help: ['sub-category', majorGroup] } }]" queryParamsHandling="merge"
            aria-label="back">
            <span class="-oneX-breadcrumbs-chevron" style="transform: scaleX(-1)"></span>
            <span itemprop="name" class="help-large-breadcrumb-nowrap">
              Deductible Recovery Help
            </span>
            <meta itemprop="position" content="0">
          </a>
        </li>
        <!-- The following "empty" <li> is needed so the breadcrumbs do not disappear in mobile view -->
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" [routerLink]="['/', { outlets: { help: ['sub-category', majorGroup] } }]" queryParamsHandling="merge"
            aria-label="back">
            <span itemprop="name">
            </span>
            <meta itemprop="position" content="1">
          </a>
        </li>
      </ul>
    </nav>
  </div>
</section>

<div>
  <div class="-oneX-heading--h2 help-video-title">
    {{ videoTitle }}
  </div>
  <div class="video-container">
    <iframe
      class="video"
      [src]="videoUrl"
      [title]="videoTitle"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>
<hr class="-oneX-hr"/>
<div>
  <div class="-oneX-panel--expansion">
    <div
      class="-oneX-panel-control"
      style="padding-top: 13px; padding-bottom: 13px"
      (click)="isExpanded = !isExpanded"
    >
      <button
        id="view-transcript-button-id"
        type="button"
        [attr.aria-expanded]="isExpanded"
        aria-controls="video-transcript-id"
        class="-oneX-panel-button -oneX-body--primary"
      >
        View transcript
      </button>
      <span class="-oneX-panel__chevron-down" style="position: static"></span>
    </div>
    <div
      id="video-transcript-id"
      tabindex="0"
      class="transcript-text -oneX-panel-content"
      [class.-oneX-hidden]="!isExpanded"
      [innerHTML]="transcriptText"
    ></div>
  </div>
</div>
<hr class="-oneX-hr"/>
