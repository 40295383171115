export enum SFLoggerMessageIds {
    RA_INITIALIZED = 'RA_INITIALIZED',
    RA_CUSTOMER_DETAILS = 'RA_CUSTOMER_DETAILS',

    // PHOTO ESTIMATE
    RA_ROUTE_TO_PE = 'RA_ROUTE_TO_PE',
    RA_PE_LANDING = 'RA_PE_LANDING',

    // SHOP SELECTION 
    RA_MORE_SHOPS_CLICKED = 'RA_MORE_SHOPS_CLICKED',
    RA_PREV_SHOPS_CLICKED = 'RA_PREV_SHOPS_CLICKED',

    RA_SHOP_AVAILABILITY_TOGGLE_CLICKED = 'RA_SHOP_AVAILABILITY_TOGGLE_CLICKED',

    RA_SORT_FILTER_CLICKED = 'RA_SORT_FILTER_CLICKED',
    RA_SHOP_WEBSITE_CLICKED = 'RA_SHOP_WEBSITE_CLICKED',
    RA_GOOGLE_REVIEWS_CLICKED = 'RA_GOOGLE_REVIEWS_CLICKED',

    RA_PROMPTED_TO_CONFIRM = 'RA_PROMPTED_TO_CONFIRM',
    RA_CONFIRMATION_LOGS = 'RA_CONFIRMATION_LOGS',

    // DOWNSTREAM CALLS
    RA_DOWNSTREAM_INIT = 'RA_DOWNSTREAM_INIT',
    RA_DOWNSTREAM_SUCCESS = 'RA_DOWNSTREAM_SUCCESS',
    RA_DOWNSTREAM_ERROR = 'RA_DOWNSTREAM_ERROR',
}