<div class="-oneX">
  <div class="header-section-background">
    <div class="container">
      <div class="subnav">
        <section class="claims-section -oneX-breadcrumbs">
          <div class="claims-header">
            <nav aria-label="breadcrumb" class="nav-section">
              <ul itemscope itemtype="https://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemtype="https://schema.org/ListItem">
                  <a [href]=claimsHub itemprop="item">
                    <div class="mobile-display breadcrumb-chevron">
                      <div class="-oneX-icon -oneX-icon--bg1 -oneX-icon--variant3" data-icon="chevron_24"></div>
                    </div>
                    <span class="-oneX-breadcrumbs-link-name -oneX-typography-variant2" itemprop="name">
                      Claim Overview
                    </span>
                    <div class="desktop-display breadcrumb-chevron">
                      <div class="-oneX-icon -oneX-icon--bg1 -oneX-icon--variant3" data-icon="chevron_24"></div>
                    </div>
                    <meta itemprop="position" />
                  </a>
                </li>
                <li itemprop="itemListElement" itemtype="https://schema.org/ListItem">
                  <a href="javascript:void(0)" aria-current="page" itemprop="item">
                    <span class="desktop-display -oneX-typography-variant2" itemprop="name">
                      Estimate
                    </span>
                    <meta itemprop="position" />
                  </a>
                </li>
              </ul>
              <ul>
                <li class="right-alignment -oneX-offset-xl-9 -oneX-offset-lg-8 -oneX-offset-md-7" itemprop="itemListElement" itemtype="https://schema.org/ListItem">
                  <a itemprop="item" id="ra-claim-help" class="soft-link"
                    queryParamsHandling="merge"
                    [routerLink]="['/', { outlets: { help: ['category'] } }]"
                    [attr.data-oneX-opensDrawer]="'claims-help-drawer'"
                    onclick="oneX.Drawer.showDrawer(this.id)"
                    >
                    <span class="-oneX-typography-variant2" itemprop="name">
                      Claims FAQs
                    </span>
                    <meta itemprop="position"/>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </div>
    </div>

    <div class="maintanance-banner">
      <div *ngIf="(maintenance$ | async)?.maintenanceOutage_during_flag" class="maintenance-border -oneX -oneX-notification">
        <section class="banner -oneX-notification__icon -oneX-notification--warning">
          <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
        </section>
        <section class="notif-background -oneX-notification__text">
          {{(maintenance$ | async)?.maintenanceOutage_during}}
        </section>
      </div>
      <div *ngIf="(maintenance$ | async)?.maintenanceOutage_prior_flag" class="maintenance-border -oneX -oneX-notification">
        <section class="banner -oneX-notification__icon -oneX-notification--warning">
          <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
        </section>
        <section class="notif-background -oneX-notification__text">
          {{(maintenance$ | async)?.maintenanceOutage_prior}}
        </section>
      </div>
    </div>

    <!--Landing Action Requested Header / Landing Search content-->
    <div id="landing-page-repair-header" class="landing-page-repair-header-section -oneX-container">
      <div class="-oneX-row">
        <div class="-oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12 header-column-section">
          <div class="action-requested-section">
            <span id="action-requested" class="-oneX-body--secondary red ">Action requested</span>
          </div>
          <div role="heading" aria-label="Estimate: Choose an in-network Select Service® repair shop now to write the estimate and get repairs" class="-oneX-heading--h2 -oneX-font--med estimate-heading">Estimate: Choose an in-network Select Service® repair shop now to write the estimate and get repairs.</div>
          <div class="takes-time-section">
            <div class="icon-style">
              <div class="-oneX-icon -oneX-icon--bg1 -oneX-icon--variant3" data-icon="clock_24"></div>
            </div>
            <div class="-oneX-body--secondary -oneX-font--med takes-time"> Takes 5-10 mins</div>
          </div>

          <!--Shop Search Component-->
          <div class="-oneX-primary-merna-03">
            <app-search [BLandingPage]="true" [currentPage]="currentPage" [isViewStatic]="isViewStatic" (changetoResultView)="changetoResultView($event)"></app-search>
          </div>

          <!-- Current Shop Link -->
          <div *ngIf="(currentShop$ | async)?.ShopInfo as shop">
            <!-- Contextual Help -->
            <span *ngIf="shop.name" class="-oneX-contextual-help-container -oneX-standalone-help -oneX-help-bottom">
              <button class="-oneX-contextual-help -oneX-link--inline -oneX-link-primary"
                (focus)="initializeContextualHelp()"
                (mouseenter)="initializeContextualHelp()">
                Current shop selection
                <div class="-oneX-notch"></div>
              </button>
              <span class="-oneX-contextual-help--text">
                <!-- Shop Details -->
                <div class="-oneX-body--secondary">
                  <div>{{ shop.name| titlecase }}</div>
                  <div>{{ this.shopInfoService.correctShopAddressFormat(shop.address) }},
                    {{ shop.state }}
                    {{ shop.zipcode | slice:0:5 }}
                  </div>
                  <div>{{ shop.phone | slice: 0:3 }}-{{ shop.phone | slice:3:6 }}-{{ shop.phone | slice:6 }}</div>
                </div>
                <button type="button" aria-label="Close Help" class="-oneX-close-X-button"><span class="-oneX-icon--close"></span></button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!--Select Service benefits section title / section content-->
    <div id="select-service-section" class="select-service-container -oneX-container">
      <div class="-oneX-row">
        <div role="heading" aria-level="2" class="select-section-title -oneX-heading--h4 -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          Select Service: Our trusted network of repair shops
          <span *ngIf="this.adspLanguage$ | async" (click)="scrollToAdsp()"><sup class="one-top">1</sup></span>
        </div>
      </div>
      <div class="select-service-content-section">
        <div class="-oneX-row">
          <div class="select-service-horizontal-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <div class="before-text -oneX-container">
              <div class="icon-dimensions-quote -oneX-pictogram" data-pictogram="get-a-quote"> </div>
            </div>
            <div class="select-service-content -oneX-body--primary">Hassle-free {{(complianceVerbiage$ | async)}}—no further approval needed.</div>
          </div>
          <div class="select-service-horizontal-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <div class="before-text -oneX-container">
              <div class="icon-dimensions-vehicle -oneX-pictogram" data-pictogram="suv"> </div>
            </div>
            <div class="select-service-content -oneX-body--primary">Vehicle pick up/delivery upon request.</div>
          </div>
          <div class="select-service-horizontal-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <div class="before-text -oneX-container">
              <div class="icon-dimensions-calender -oneX-pictogram" data-pictogram="calendar"> </div>
            </div>
            <div class="select-service-content -oneX-body--primary">Guaranteed completion date.</div>
          </div>
          <div class="select-service-horizontal-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <div class="before-text -oneX-container">
              <div class="icon-dimensions-savings -oneX-pictogram" data-pictogram="education-savings"> </div>
            </div>
            <div class="select-service-content -oneX-body--primary">Limited lifetime warranty from the shop.</div>
          </div>
          <div class="select-service-horizontal-content content-last -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <div class="before-text -oneX-container">
              <div class="icon-dimensions-funds -oneX-pictogram" data-pictogram="funds-transfer"> </div>
            </div>
            <div class="select-service-content -oneX-body--primary">We pay the shop directly for repairs.</div>
          </div>
        </div>
      </div>
    </div>

    <!--How it works section title / section content-->
    <div id="how-it-works-section" class="how-it-works-container -oneX-container">
      <div class="-oneX-row">
        <div class="-oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <div role="heading" aria-level="2" class="how-section-title -oneX-heading--h4">
            How it works
          </div>
        </div>
      </div>
      <div class="how-it-works-content-section">
        <div class="-oneX-row">
          <div class ="how-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <span class="section-subtitle -oneX-heading--h3">01</span>
            <div><br class="desktop">Search for and confirm shop online.</div>
          </div>
          <div class ="how-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <span class="section-subtitle">02</span>
            <div><br class="desktop">Schedule an {{(complianceVerbiage$ | async)}} with your shop.</div>
          </div>
          <div class ="how-content -oneX-body--primary -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <span class="section-subtitle">03</span>
            <div><br class="desktop">Approve the {{(complianceVerbiage$ | async)}} and authorize repairs.</div>
          </div>
          <div class ="-oneX-body--primary -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
            <span class="section-subtitle">04</span>
            <div><br class="desktop">Pay your deductible to the shop (if applicable).</div>
          </div>
        </div>
      </div>
    </div>

    <!--Help Topics section title / section content-->
   <div id="help-topics-section" class="help-topics-container -oneX-container">
      <div class="-oneX-row help-topics-header">
        <div class="-oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <div role="heading" aria-level="2" class="help-section-title -oneX-heading--h4">
            Need help?
          </div>
        </div>
      </div>
      <div class="-oneX-row">
        <div class="help-topic-links-content -oneX-body-primary -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <div data-onex-opensdrawer="help-drawer-section" class="help-links -oneX-link--inline">
            <a id="btn-preferred-shop"
              class=" -oneX-link--inline clickable"
              data-oneX-opensDrawer="help-drawer-section"
              onclick="oneX.Drawer.showDrawer(this.id)"
              tabIndex="0"
              role="link">What if my preferred shop is not listed?
           </a>
          </div>
        </div>
        <div class="help-topic-links-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <p data-onex-opensdrawer="help-drawer-section" class="help-links -oneX-link--inline" >
            <a id="btn-repair-process"
              class="-oneX-link--inline clickable"
              data-oneX-opensDrawer="help-drawer-section"
              onclick="oneX.Drawer.showDrawer(this.id)"
              (click)="scrollToHelpTopic('repair-process')"
              tabIndex="0"
              role="link">What's the repair process?
            </a>
          </p>
        </div>
        <div class="help-topic-links-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <p data-onex-opensdrawer="help-drawer-section" class="help-links -oneX-link--inline" >
            <a id="btn-repairs-now"
              class="-oneX-link--inline clickable"
              data-oneX-opensDrawer="help-drawer-section"
              onclick="oneX.Drawer.showDrawer(this.id)"
              (click)="scrollToHelpTopic('repairs-now')"
              tabIndex="0"
              role="link">What if I don't need repairs right now?
            </a>
          </p>
        </div>
        <div class="help-topic-links-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <p data-onex-opensdrawer="help-drawer-section" class="help-links -oneX-link--inline" >
            <a id="btn-selected-shop"
              class="-oneX-link--inline clickable"
              data-oneX-opensDrawer="help-drawer-section"
              onclick="oneX.Drawer.showDrawer(this.id)"
              (click)="scrollToHelpTopic('selected-shop')"
              tabIndex="0"
              role="link">How can I change my selected shop?
            </a>
          </p>
        </div>
        <div class="help-topic-links-content -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12">
          <p data-onex-opensdrawer="help-drawer-section" class="help-links-last -oneX-link--inline">
            <a id="btn-repair-costs"
              class="-oneX-link--inline clickable"
              data-oneX-opensDrawer="help-drawer-section"
              onclick="oneX.Drawer.showDrawer(this.id)"
              (click)="scrollToHelpTopic('repair-costs')"
              tabIndex="0"
              role="link">What are my repair costs?
            </a>
          </p>
        </div>
      </div>
    </div>

    <!--Back to top arrow -->
    <div class="-oneX-container">
      <div id="back-to-top-button-section" class="-oneX-row -oneX-offset-lg-10 -oneX-offset-md-9 -oneX-offset-sm-9 back-to-top">
        <button id="back-to-top-button" type="button" (click)= "mainTop()" class="main-top-button">Back to top</button>
        <div class="icon-arrow">
          <div class="-oneX-icon" data-icon="arrow_up_24" (click)= "mainTop()"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="adsp" class="-oneX-container adsp-language-section">
  <!-- ADSP Legal Language -->
  <div class="-oneX-row">
    <div *ngIf="this.adspLanguage$ | async" class="adsp-language -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-offset-md-1 -oneX-col-xl-10 -oneX-col-lg-10 -oneX-col-md-10 -oneX-col-sm-12 -oneX-body--footnote">
      <span>
        <span class="one-down" (click)= "scrollToSSS()">1</span>{{ this.adspLanguage$ | async }}
      </span>
    </div>
  </div>
</div>

<section id="help-drawer-section" class=" -oneX-drawer-container">
  <div class="help-panel">
    <hr class="-oneX-hr"/>
    <div class="-oneX-panel--expansion">
      <div class="-oneX-panel-control">
        <button type="button" aria-expanded="false" aria-controls="detailsDiv" class="-oneX-panel-button ">Help Topics</button>
        <span class="collapse-button -oneX-panel__chevron-down"></span>
      </div>
      <div id="detailsDiv" tabindex="0" class="-oneX-panel-content -oneX-hidden">
        <p><a class="-oneX-link--inline help-topics-panel" (click)= "scrollToHelpTopic('preferred-shop')">What if my preferred shop is not listed?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)= "scrollToHelpTopic('repair-process')">What’s the repair process?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)= "scrollToHelpTopic('repairs-now')">What if I don’t need repairs right now?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)= "scrollToHelpTopic('selected-shop')">How can I change my selected shop?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)= "scrollToHelpTopic('repair-costs')">What are my repair costs?</a></p>
      </div>
    </div>
    <hr class="-oneX-hr"/>
  </div>

  <div role="dialog" aria-labelledby="dialogLnkHdr">
    <div id="dialogLnkHdrPreferredShop" class="drawer-headings -oneX-header--h3">
      <a id="preferred-shop">What if my preferred shop is not listed?</a>
    </div>
    <div class="-oneX-body--primary help-drawer-body">
      Our Select Service program offers you many exclusive conveniences—plus, the peace of mind of a warranty provided by the repairer. You may select any repair facility that meets your needs. If you prefer to work with a non-participating shop, please contact your claim handler to set up an {{(complianceVerbiage$ | async)}}. Not all repair facilities charge the same amount for vehicle repairs. You may be responsible for any amounts not agreed to by State Farm. This may include, but may not be limited to, storage fees, and rental expenses.
    </div>
    <div id="dialogLnkHdrRepairProcess" class="drawer-headings -oneX-header--h3 repair-process-text">
      <a id="repair-process">What’s the repair process?</a>
    </div>
    <div class="-oneX-body--primary repair-process-list">
      <ol class="help-drawer-body">
        <li><p class="-oneX-body--primary">Choose a repair shop online and submit a request for an {{complianceVerbiage$ | async}}. Contact your State Farm handler if you prefer to work with a shop that’s not part of the Select Service program.</p></li>
        <li><p class="-oneX-body--primary">Schedule an {{complianceVerbiage$ | async}} with the repair shop and/or schedule to have your non-drivable vehicle transported to the shop. </p></li>
        <li><p class="-oneX-body--primary">Approve the {{complianceVerbiage$ | async}} and authorize repairs.</p></li>
        <li><p class="-oneX-body--primary">You can pay your deductible, if applicable, to the repair facility when repairs are complete. </p></li>
      </ol>
    </div>

    <div id="dialogLnkHdrRepairsNow"  class="drawer-headings -oneX-header--h3"><a id="repairs-now">What if I don’t need repairs right now?</a></div>
    <div class="-oneX-body--primary help-drawer-body">
      You may receive reminders about scheduling repairs, but don’t worry—you can schedule them when it’s convenient for you.
    </div>
    <div id="dialogLnkHdr selected-shop" class="drawer-headings -oneX-header--h3">
      <a id="selected-shop">How can I change my selected shop?</a>
    </div>
    <div class="-oneX-body--primary help-drawer-body">If you need to change your selected shop, you can change it online, contact your claim handler, or use the online chat feature.</div>
    <div id="dialogLnkHdrRepairCosts"  class="drawer-headings -oneX-header--h3">
      <a id="repair-costs">What are my repair costs?</a>
    </div>
    <div class="-oneX-body--primary repair-shop-drawer-body">
      After confirming your repairer, the Select Service shop will provide you with an {{complianceVerbiage$ | async}} for the claim-related damages. Keep in mind that if they identify additional damage during the repair, the shop will bill us directly for the approved repair {{(complianceVerbiage$ | async)}} balance. You will pay your deductible to the shop, if applicable, when repairs are complete.
    </div>
    <button onclick="oneX.Drawer.hideDrawer()" class="-oneX-close-X-button" aria-label="Close Drawer">
      <span class="-oneX-icon--close help-close"></span>
    </button>
  </div>
</section>
