import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ServiceClientsService } from '../service/service-clients.service';

@Component({
  selector: 'app-adsp',
  templateUrl: './adsp.component.html',
  styleUrls: ['./adsp.component.css'],
})
export class AdspComponent implements OnInit {
  adspLanguage$: Observable<string>;
  constructor(private clientService: ServiceClientsService) {}

  ngOnInit() {
    this.adspLanguage$ = this.clientService.getADSPLanguage();
  }
}
