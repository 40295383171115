import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

// SERVICES
import { SearchResultsService } from '../service/search-results.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { SFLoggerService } from 'app/service/sf-logger.service';
import { StorageService } from 'app/service/storage.service';
import { UrlResolverService } from '../service/url-resolver.service';

// MODELS
import { ChangeView } from '../model/change-view.model';
import { LatLongModel } from 'app/model/latLong.model';
import { WindowReference } from '../model/window-reference.model';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

export abstract class BaseComponent {

  protected abstract isViewStatic: boolean;
  protected abstract currentPage: string;
  protected externalClaimId: string;
  protected externalClientId: string;
  protected role: string;

  protected loggingDetails: any;
  private encodedSearchTerm: string;

  fileName = 'base.component.ts';

  constructor(
    protected __zone: NgZone,
    protected router: Router,
    protected urlResolver: UrlResolverService,
    protected clientService: ServiceClientsService,
    protected searchResultsService: SearchResultsService,
    protected windowRef: WindowReference,
    protected storageService: StorageService,
    protected sfLoggerService: SFLoggerService) {
      this.loggingDetails = [
        SFLoggerFields.DEV_REPORTING_FILE, this.fileName
      ];
  }

  changetoResultView(changeView?: ChangeView) {
    if (changeView.shouldChange) {
      this.isViewStatic = true;
      // if no provider is found and is eligible for pca
      // this.saveToLocalStorage();
      if (changeView.destination === 'PCA') {
        this.encodeSearchTerm();
        this.toPCA();
      } else if (changeView.destination === 'estimateAssist') {
        this.encodeSearchTerm();
        this.__zone.run(() => {
          this.toEstimateOptions();
        });
      } else {
        this.__zone.run(() => {
          this.toSearchResult(changeView.latlng);
        });
      }
    }
  }

  private encodeSearchTerm() {
    let searchTermEncoding = encodeURI(this.searchResultsService.getSearchedCriteria().searchTerm);
    searchTermEncoding = searchTermEncoding.replace(/'/g, '%27');
    searchTermEncoding = searchTermEncoding.replace(/,/g, '%2C');
    this.encodedSearchTerm = searchTermEncoding;
  }

  private toEstimateOptions() {
    this.router.navigate(['estimateoptions'], { queryParams: this.urlResolver.getQueryParamsForUrl() });
  }

  private toPCA() {
    const photoEstimateEligibility = this.clientService.getPhotoEstimateEligibility();

    if (photoEstimateEligibility) {
      if (photoEstimateEligibility.eligible === true) {
        // New PE
        this.loggingDetails.push(SFLoggerFields.ROUTING_TO, 'New PE');
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_ROUTE_TO_PE, "Routing to NEW PE", this.loggingDetails);
        this.router.navigate(['nonprogram']);
      } else {
        // Old PE
        this.loggingDetails.push(SFLoggerFields.ROUTING_TO, 'Old PE');
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_ROUTE_TO_PE, "Routing to OLD PE", this.loggingDetails);
        this.windowRef.navigateTo(
          this.urlResolver.getPCAUrl(
            this.clientService.getVehicleNumber(),
            this.searchResultsService.getRequestPostalCode(), '',
            this.encodedSearchTerm
          )
        );
      }
    }
  }

  private toSearchResult(latlng: LatLongModel) {
    this.router.navigate(['repairshops'], {
      queryParams: {
        claim: this.urlResolver.getClaimId(),
        client: this.urlResolver.getClientId(),
        role: this.role,
        lat: latlng.lat,
        lng: latlng.lng,
        term: this.urlResolver.getStringifiedProviderRequest(
          this.searchResultsService.getProviderRequest()
        ),
      },
    });
  }

}
