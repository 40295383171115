import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService {
  DXL_GRID_XS = 375;
  DXL_GRID_SM = 540;
  DXL_GRID_MD = 720;
  DXL_GRID_LG = 960;
  DXL_GRID_XL = 1140;

  isDeviceMobile(pageWidth, pageHeight) {

    let isDeviceMobile = false;

    if (pageWidth - pageHeight > 0) { // landscape
      isDeviceMobile = (pageHeight < this.DXL_GRID_XS || pageHeight < this.DXL_GRID_SM) ? true : false;
    } else { // portrait
      isDeviceMobile = (pageWidth < this.DXL_GRID_XS || pageWidth < this.DXL_GRID_SM) ? true : false;
    }

    return isDeviceMobile;
  }

}
