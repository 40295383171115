import { Input } from '@angular/core';
import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { WINDOW } from 'app/service/window.service';

@Component({
  selector: 'app-one-x-footer',
  templateUrl: './one-x-footer.component.html',
  styleUrls: ['./one-x-footer.component.css']
})
export class OneXFooterComponent implements AfterViewInit, OnDestroy {
  @Input() limited: boolean;

  constructor(@Inject(WINDOW) private window: Window,
  ) { }

  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#repair-footer'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#repair-footer'));
  }

}
