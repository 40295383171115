import { Component, Input, ViewChild, OnInit, OnChanges, Output, ViewChildren, QueryList, EventEmitter, SimpleChanges } from '@angular/core';
import { Shop } from '../model/shop.model';
import { Marker } from 'app/model/map-marker.model';
import { Subject } from 'rxjs';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-shop-map',
  templateUrl: './shop-map.component.html',
  styleUrls: ['./shop-map.component.css'],
})
export class ShopMapComponent implements OnInit, OnChanges {
  @ViewChildren('shopMarker') shopMarkers: QueryList<MapMarker>;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @Input() sortedShops: Shop[];
  @Input() youAreHereMarker: Marker;
  @Input() shopIndexStart: number;
  @Input() shopIndexEnd: number;
  @Input() updatedShopIndex: number;
  @Input() selectedShopIndex: number;
  @Input() nextPageSubject: Subject<void>;
  @Output() private markerSelectionIndex = new EventEmitter<number>();

  shopInfo: Shop;
  starIndex: number;
  options: google.maps.MapOptions = {
    zoomControl: true,
    streetViewControl: false,
    disableDefaultUI: true,
  };
  youAreHereMarkeroptions: google.maps.MarkerOptions = {
    animation: google.maps.Animation.DROP,
    icon: './assets/pin_center.svg'
  };

  fitBound = true;

  ngOnInit() {
    this.nextPageSubject.subscribe(() => { this.scrollIntoViewShop(); });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updatedShopIndex && !changes.shopIndexStart) {
      setTimeout(() => {
        const markerIndex = changes.updatedShopIndex.currentValue;
        const markerRef = this.shopMarkers.toArray()[markerIndex];
        this.openInfoWindow(markerRef, markerIndex);
      }, 100);
    }
    if ((changes.sortedShops && !changes.shopIndexStart) || (!changes.sortedShops && changes.shopIndexStart)) {
      setTimeout(() => {
        this.infoWindow.close();
        this.fitBound = true;
        this.setFitBounds();
      }, 1000);
    }
  }


  openInfoWindow(marker: any, index: number) {
    this.shopInfo = this.sortedShops[index + this.shopIndexStart];
    this.starIndex = index + 1;
    this.infoWindow.open(marker);
    this.markerSelectionIndex.emit(index);
    const card: HTMLElement = document.getElementById('shop-' + (index + this.shopIndexStart));
    card.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  setFitBounds() {
    if (this.fitBound) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(this.youAreHereMarker.latlng);
      if (this.sortedShops.length > 1) {
        for (const shopmarker of this.shopMarkers.toArray()) {
          bounds.extend(shopmarker.getPosition());
        }
        this.map.fitBounds(bounds);
      }
      this.fitBound = false;
    }
  }

  scrollIntoViewShop() {
    const card: HTMLElement = document.getElementById('shop-' + (this.shopIndexStart));
    card.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

}
