export enum ViewState {
    DESKTOP_SPLITSCREEN = 'desktop_splitscreen',
    DESKTOP_MAPONLY = 'desktop_maponly',
    MOBILE_PORTRAIT_MAPONLY = 'mobile_portrait_maponly',
    MOBILE_PORTRAIT_SPLITSCREEN = 'mobile_portrait_splitscreen',
    MOBILE_PORTRAIT_MAPINTERMEDIATE = 'mobile_portrait_mapintermediate',
    MOBILE_PORTRAIT_LISTONLY = 'mobile_portrait_listonly',
    MOBILE_LANDSCAPE_MAPONLY = 'mobile_landscape_maponly',
    MOBILE_LANDSCAPE_SPLITSCREEN = 'mobile_landscape_splitscreen'
}
