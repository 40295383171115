import { Injectable } from '@angular/core';
import { StorageService } from '../service/storage.service';

@Injectable()
export class RentalService {
  deliveryPreference: boolean;
  customerPhone: string;
  vehiclePreference: string;
  vendorType: string;

  constructor (
    private storageService: StorageService,
  ) { }

  setDeliveryPreference(deliveryPreference: boolean) {
    this.deliveryPreference = deliveryPreference;
    this.storageService.setSessionStorage('deliveryPreference', JSON.stringify(this.deliveryPreference));
  }

  getDeliveryPreference() {
    if (this.deliveryPreference === undefined) {
      this.deliveryPreference = JSON.parse(this.storageService.getSessionStorage('deliveryPreference'));
    }
    return this.deliveryPreference;
  }

  setCustomerPhone(customerPhone: string) {
    this.customerPhone = customerPhone;
    this.storageService.setSessionStorage('customerPhone', this.customerPhone);
  }

  getCustomerPhone() {
    if (this.customerPhone === undefined) {
      this.customerPhone = this.storageService.getSessionStorage('customerPhone');
    }
    return this.customerPhone;
  }

  setVehiclePreference(vehiclePreference: string) {
    this.vehiclePreference = vehiclePreference;
    this.storageService.setSessionStorage('vehiclePreference', this.vehiclePreference);
  }

  getVehiclePreference() {
    if (this.vehiclePreference === undefined) {
      this.vehiclePreference = this.storageService.getSessionStorage('vehiclePreference');
    }
    return this.vehiclePreference;
  }

  setVendorType(vendorType: string) {
    this.vendorType = vendorType;
    this.storageService.setSessionStorage('vendorType', this.vendorType);
  }

  getVendorType() {
    if (this.vendorType === undefined) {
      this.vendorType = this.storageService.getSessionStorage('vendorType');
    }
    return this.vendorType;
  }

}
