import { AfterViewInit, Component, EventEmitter, ElementRef, Inject, Input, Output, OnInit, ViewChild } from '@angular/core';
import { validPhoneNumberRegex, validEmailRegex } from '../constant/regex-checker-constants';

// SERVICES
import { WINDOW } from '../service/window.service';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

@Component({
  selector: 'app-photo-estimate-edit-contact',
  templateUrl: './photo-estimate-edit-contact.component.html',
  styleUrls: ['./photo-estimate-edit-contact.component.css'],
})

export class PhotoEstimateEditContactComponent implements OnInit, AfterViewInit {
  @Input() contactType: string;
  @Input() initialContactValue: string;
  @Output() saveContactCallback: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelEditCallback = new EventEmitter();

  @ViewChild('phoneInputContainer', { static: false }) phoneInputContainer: ElementRef;
  @ViewChild('phoneInputLabel', { static: false }) phoneInputLabel: ElementRef;
  @ViewChild('phoneInput', { static: false }) phoneInput: ElementRef;
  @ViewChild('emailInputContainer', { static: false }) emailInputContainer: ElementRef;
  @ViewChild('emailInputLabel', { static: false }) emailInputLabel: ElementRef;
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
  

  oneX: any;
  contactTypeTitleDisplayText: string;
  contactTypeInfoDisplayText: string;

  isPhone: boolean;
  isEmail: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    private dataAnalyticsService: DataAnalyticsService
  ){
    this.oneX = this.window['oneX'];
  }

  ngOnInit(): void {
    this.oneX.FloatingLabel.init();
    this.oneX.AutoFormat.init();
    this.oneX.Textfield.init();

    this.isPhone = this.contactType === 'phone';
    this.isEmail = this.contactType === 'email';

    if (this.isPhone) {
      this.contactTypeTitleDisplayText = 'a mobile number';
      this.contactTypeInfoDisplayText = 'number';
    }

    if (this.isEmail) {
      this.contactTypeTitleDisplayText = 'an email address';
      this.contactTypeInfoDisplayText = 'email address';
    }
  }

  ngAfterViewInit() {
    if (this.initialContactValue) {
      if (this.isPhone) {
        this.phoneInput.nativeElement.value = this.initialContactValue;
        this.phoneInputLabel.nativeElement.classList.add('-oneX--floatUp');
        this.dataAnalyticsService.sendPageData('photo-estimate-edit-phone-contact');
      } else {
        this.emailInput.nativeElement.value = this.initialContactValue;
        this.emailInputLabel.nativeElement.classList.add('-oneX--floatUp');
        this.dataAnalyticsService.sendPageData('photo-estimate-edit-email-contact');
      }
    }
  }

  saveContact() {
    let value;

    if (this.isPhone) {
      value = this.checkContact(this.phoneInput, this.phoneInputContainer, validPhoneNumberRegex, 'phone-err');
    } else {
      value = this.checkContact(this.emailInput, this.emailInputContainer, validEmailRegex, 'email-err');
    }

    if (value) {
      this.saveContactCallback.emit(value);
    }
    this.dataAnalyticsService.sendPageData('photo-estimate-contact');
  }

  cancelSaveContact() {
    this.cancelEditCallback.emit();
    this.dataAnalyticsService.sendPageData('photo-estimate-contact');
  }

  checkContact(inputElement: ElementRef, containerElement: ElementRef, regExChecker: RegExp, errorTextElementId: string): string {
    let value = inputElement.nativeElement.value.trim();

    if (!value || (regExChecker.test(value) === false)) {
      containerElement.nativeElement.classList.add('-oneX-widget--err');
      this.oneX.$(`#${errorTextElementId}`).show();

      value = null;
    }

    return value;
  }
}
