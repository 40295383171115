<div class="rating-card" id="shop-rating">
  <div [style.display]="getRating() ? 'flex' : 'none'" class="ratings-and-reviews">
    <div class="ratings-outline" role="img"
      attr.aria-label="google-shop-rating {{getFormattedRating(getRating())}} out of 5">
      <span [ngStyle]="{'width': getRating() + '%'}" class="ratings-fill"></span>
    </div>
    <div class="reviews oneX-Hyperlink-inline--tertiary">
      <a *ngIf="areReviewsClickable" id="googleReviewsLink" [attr.href]="'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + getPlaceId()"
        target="_blank" class="-oneX-link--inline -oneX-link-tertiary" (click)="onReviewsClick()"
        [attr.tabindex]="isSidebarVisabile ? '0' : '-1'">{{getUserRatings()}} Google reviews</a>

      <a *ngIf="!areReviewsClickable" class="-oneX-body--tertiary">{{getUserRatings()}} Google reviews</a>        
    </div>
  </div>
</div>