<app-navigation-breadcrumb>
</app-navigation-breadcrumb>

<div class="-oneX -oneX-container top-container" id="rental-vehicle-preference-container">

  <!-- Title -->
  <div class="title">
    <div *ngIf="deliveryPreference" class="-oneX-col-lg-8 no-padding -oneX-heading--h1 margin-bottom-eight">Enterprise can pick you up from the
      repair shop, or Hertz can deliver a rental to you</div>
    <div *ngIf="!deliveryPreference" class="-oneX-col-lg-8 no-padding -oneX-heading--h1 margin-bottom-eight">You’ll choose how you get your
      rental when Enterprise or Hertz calls</div>
    <div class="-oneX-col-lg-8 no-padding -oneX-body--intro">{{ rentalPolicyText }}</div>
  </div>

  <!-- Vehicle Selection -->
  <div [@vehiclesTrigger]="vehicleSelectionView">
    <div [ngClass]="!isDefaultStateMinnesota ? 'select-container-minn' : '' ">
      <div class="-oneX-heading--h4" [ngClass]="{'margin-bottom-eight': isDefaultStateMinnesota}">Select your vehicle
        preference:</div>
      <div *ngIf="isDefaultStateMinnesota" class=" -oneX-body--primary">Minnesota law gives you the right to choose any
        rental
        vehicle company, and prohibits us from requiring you to choose a particular vendor.</div>

      <!-- Contextal Help -->
      <div class="understand-cost">
        <span class="-oneX-contextual-help-container -oneX-standalone-help">
          <button type="button" class="-oneX-btn-control -oneX-contextual-help no-padding"
            aria-label="understand your costs" aria-controls="help">
            <div class="understand-cost">
              <div class="margin-right-eight understand-cost-text -oneX-body--tertiary">Understand your costs</div>
              <div class="-oneX-icon help-icon" data-icon="help_24" style="height:16px;width:16px"> </div>
            </div>
            <div class="-oneX-notch"></div>
          </button>
          <span id="help" class="-oneX-contextual-help--text">
            <div class="-oneX-body--primary">This is an estimated cost that includes taxes and fees. Final costs may
              change based on local taxes, fees, and any upgrades you request.</div>
            <br>
            <div class="-oneX-body--primary">Enterprise or Hertz will discuss your daily cost when they call you.
            </div>
            <button type="button" aria-label="Close Help" class="-oneX-close-X-button">
              <span class="-oneX-icon--close"></span>
            </button>
          </span>
        </span>
      </div>
    </div>

    <!-- Vehicle Card -->
    <div class="row-cards-new">
      <!-- Intermediate Enterprise-->
      <div class="box-container margin-right margin-bottom-sixteen" tabindex="0" (click)="onVehicleSelection(1)"
        (keydown.enter)="onVehicleSelection(1)" id="oneX-cards">
        <div class="first-row">
          <div class="card-layout">
            <div class="badge -oneX-body--footnote -oneX-font-Mecherle -oneX-font--semi">GOOD VALUE</div>
            <div class="margin-top-bottom">
              <a class="-oneX-link--block" role="link" tabindex="0" (click)="onVehicleSelection(1)"
                (keydown.enter)="onVehicleSelection(1)">Intermediate</a>
              <div class="-oneX-body--tertiary -oneX-font--regular">Hyundai Elantra or similar</div>
            </div>
            <div class="enterprise-logo"></div>
          </div>
          <div class="amount -oneX-heading--h3 -oneX-font-Mecherle -oneX-font--semi">${{
            dailyCostIntermediateEnterprise }}<span
              class="-oneX-body--secondary -oneX-font-Mecherle -oneX-font--semi">/day*</span></div>
        </div>
        <div class="-oneX-body--secondary margin-left-right margin-top-bottom-eight">Coverage max will be reached in {{
          daysUntilCoverageMaxIntermediateEnterprise }} days, then you’ll pay ${{ rentalIntermediateRateEnterprise
          }}/day.</div>
        <div class="-oneX-body--footnote margin-left-right margin-bottom-sixteen">* this may change based on taxes, fees
          or other local charges. The
          policy maximum will remain the same.</div>
      </div>

      <!-- Intermediate Hertz-->
      <div class="box-container margin-bottom-sixteen" tabindex="0" (click)="onVehicleSelection(2)"
        (keydown.enter)="onVehicleSelection(2)" id="oneX-cards">
        <div class="first-row">
          <div class="card-layout">
            <div class="badge -oneX-body--footnote -oneX-font-Mecherle -oneX-font--semi">GOOD VALUE</div>
            <div class="margin-top-bottom">
              <a class="-oneX-link--block" role="link" tabindex="0" (click)="onVehicleSelection(2)"
                (keydown.enter)="onVehicleSelection(2)">Intermediate</a>
              <div class="-oneX-body--tertiary -oneX-font--regular">Mazda 3 4-door or similar</div>
            </div>
            <div class="hertz-logo"></div>
          </div>
          <div class="amount -oneX-heading--h3 -oneX-font-Mecherle -oneX-font--semi">${{ dailyCostIntermediateHertz
            }}<span class="-oneX-body--secondary -oneX-font-Mecherle -oneX-font--semi">/day*</span></div>
        </div>
        <div class="-oneX-body--secondary margin-left-right margin-top-bottom-eight">Coverage max will be reached in {{
          daysUntilCoverageMaxIntermediateHertz }} days,
          then you’ll pay ${{ rentalIntermediateRateHertz }}/day.</div>
        <div class="-oneX-body--footnote margin-left-right margin-bottom-sixteen">* this may change based on taxes, fees
          or other local charges. The
          policy
          maximum will remain the same.</div>
      </div>
    </div>

    <div class="row-cards-new">
      <!-- Economy Enterprise-->
      <div class="box-container margin-right margin-bottom-sixteen" tabindex="0" (click)="onVehicleSelection(3)"
        (keydown.enter)="onVehicleSelection(3)" id="oneX-cards">
        <div class="first-row">
          <div class="card-layout">
            <div class="badge -oneX-body--footnote -oneX-font-Mecherle -oneX-font--semi">LOW RATE</div>
            <div class="margin-top-bottom">
              <a class="-oneX-link--block" role="link" tabindex="0" (click)="onVehicleSelection(3)"
                (keydown.enter)="onVehicleSelection(3)">Compact</a>
              <div class="-oneX-body--tertiary -oneX-font--regular">Nissan Versa or similar</div>
            </div>
            <div class="enterprise-logo"></div>
          </div>
          <div class="amount -oneX-heading--h3 -oneX-font-Mecherle -oneX-font--semi">${{
            dailyCostCompactEnterprise}}
            <span class="-oneX-body--secondary -oneX-font-Mecherle -oneX-font--semi">/day*</span>
          </div>
        </div>
        <div class="-oneX-body--secondary margin-left-right margin-top-bottom-eight">Coverage max will be reached in
          {{daysUntilCoverageMaxCompactEnterprise }} days then you’ll pay ${{ rentalCompactRateEnterprise }}/day.
        </div>
        <div class="-oneX-body--footnote margin-left-right margin-bottom-sixteen">* this may change based on taxes, fees
          or other local charges. The
          policy
          maximum will remain the same.</div>
      </div>

      <!-- Economy Hertz-->
      <div class="box-container margin-bottom-sixteen" tabindex="0" (click)="onVehicleSelection(4)"
        (keydown.enter)="onVehicleSelection(4)" id="oneX-cards">
        <div class="first-row">
          <div class="card-layout">
            <div class="badge -oneX-body--footnote -oneX-font-Mecherle -oneX-font--semi">LOW RATE</div>
            <div class="margin-top-bottom">
              <a class="-oneX-link--block" role="link" tabindex="0" (click)="onVehicleSelection(4)"
                (keydown.enter)="onVehicleSelection(4)">Economy</a>
              <div class="-oneX-body--tertiary -oneX-font--regular">Chevrolet Spark or similar</div>
            </div>
            <div class="hertz-logo"></div>
          </div>
          <div class="amount -oneX-heading--h3 -oneX-font-Mecherle -oneX-font--semi">${{ dailyCostEconomyHertz
            }}<span class="-oneX-body--secondary -oneX-font-Mecherle -oneX-font--semi">/day*</span></div>
        </div>
        <div class="-oneX-body--secondary margin-left-right margin-top-bottom-eight">Coverage max will be reached in {{
          daysUntilCoverageMaxEconomyHertz }} days, then you’ll pay {{ rentalEconomyRateHertz }}/day.
        </div>
        <div class="-oneX-body--footnote margin-left-right margin-bottom-sixteen">* this may change based on taxes, fees
          or other local charges. The
          policy
          maximum will remain the same.</div>
      </div>
    </div>
  </div>

  <!-- Decide Later Link -->
  <div [@vehiclesTrigger]="vehicleSelectionView">
    <a class="-oneX-link--block" role="link" tabindex="0" (click)="onVehicleSelection(0)"
      (keydown.enter)="onVehicleSelection(0)">Decide on a vehicle later
    </a>
  </div>

  <!-- Vendor Selection -->

  <div class="-oneX-col-md-6 -oneX-col-lg-6 no-padding" [@vendorTrigger]="vendorSelectionView">
    <div class="-oneX-row edit-vehicleselection-gap">
      <div class="-oneX-col">
        <a class="-oneX-link--block edit-icon" role="link" tabindex="0" (click)="editVehicleSelection()"
          (keydown.enter)="editVehicleSelection()">
          <span class="margin-right-eight">{{ vehicleSelection }}</span>
          <div class="-oneX-icon -oneX-icon--variant1" data-icon="edit_24" style="height:24px;width:24px"></div>
        </a>
      </div>
    </div>

    <div class="edit-vehicleselection-gap">
      <div class="-oneX-heading--h4 margin-bottom-sixteen">Which rental provider would you like to use?</div>
      <div class="-oneX">
        <div class="enterprise-button">
          <button id='enterprise'
            class="-oneX-btn-auto-advance-selector enterprise-button-one -oneX-body--primary margin-bottom-sixteen"
            (click)="onVendorSelection($event.target.id)" (keydown.enter)="onVendorSelection($event.target.id)">
            Enterprise
          </button>
          <button id='hertz' class="-oneX-btn-auto-advance-selector enterprise-button-one -oneX-body--primary"
            (click)="onVendorSelection($event.target.id)" (keydown.enter)="onVendorSelection($event.target.id)">
            Hertz
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- Progressive Disclosure -->

  <div class="-oneX-col-md-6 -oneX-col-lg-6 no-padding" [@phoneTrigger]="phoneFieldView">
    <div class="-oneX-row edit-vehicleselection-gap">
      <div class="-oneX-col">
        <a class="-oneX-link--block edit-icon" role="link" tabindex="0" (click)="editVehicleSelection()"
          (keydown.enter)="editVehicleSelection()">
          <span class="margin-right-eight">{{ vehicleSelection }}</span>
          <div class="-oneX-icon -oneX-icon--variant1" data-icon="edit_24" style="height:24px;width:24px"></div>
        </a>
      </div>
    </div>

    <div class="edit-vehicleselection-gap">
      <div class="-oneX-heading--h4 margin-bottom-sixteen">What number should {{vendorType}} call to finalize your
        request?</div>
      <div class="-oneX">
        <label for="phoneMasked" class="-oneX-textfield__floatingLabel">Phone number</label>
        <input id="phoneMasked" type="tel" class="-oneX-textfield--floating-input" data-formatter="phone"
          (keyup)="phoneValidator($event)" />
      </div>
    </div>

    <div class="send-request-button">
      <button type="submit" class="-oneX-btn-primary" (click)="requestRentalButton()"
        [disabled]="isRentalSubmitDisabled" (keydown.enter)="requestRentalButton()">Send request</button>
    </div>

  </div>


  <!-- Rental Assignments Error Modal -->
  <div id="rental-assignments-retry-modal" class="-oneX -oneX-modal__container rental-assignments-modal-container"
    style="display:none">
    <div class="-oneX-modal-content rental-assignments-modal-content" aria-labelledby="dialogNoAction" role="dialog">
      <div>
        <div class="icon-header">
          <div tabindex="0" class="-oneX-icon-container">
            <div class="-oneX-icon icon-error" data-icon="warning_fill_32"></div>
          </div>
          <div *ngIf="!isRepeatRetry()" id="dialogNoAction"
            class="error-message-header -oneX-modal-heading -oneX-heading--h2" role="heading" aria-level="2"
            tabindex="-1">Something went wrong</div>
          <div *ngIf="isRepeatRetry()" id="dialogNoAction"
            class="error-message-header -oneX-modal-heading -oneX-heading--h2" role="heading" aria-level="2"
            tabindex="-1">There's still a problem</div>
        </div>
        <div *ngIf="!isRepeatRetry()" class="-oneX-body--intro-md">We’re unable to process your rental request at this
          time. Please try again or contact your claim handler for assistance.</div>
        <div *ngIf="isRepeatRetry()" class="-oneX-body--intro-md">We’re unable to process your rental request at this
          time. Contact your claim handler for assistance.</div>
      </div>

      <div class="-oneX-modal-footer rental-assignments-modal-footer">
        <div class="-oneX-row">
          <div class="-oneX-modal-primary__div -oneX-col">
            <button id="oneX-modal-primary-action-1" class="-oneX-btn-primary rental-assignments-modal-btn clickable"
              (keydown.enter)="requestRentalButton()" (click)="requestRentalButton()"
              [disabled]="isRentalSubmitDisabled">Try Again</button>
          </div>
        </div>
      </div>
      <button type="button" class="-oneX-close-X-button clickable" aria-label="Close Modal">
        <span class="-oneX-icon--close"></span>
      </button>
    </div>
  </div>

</div>
