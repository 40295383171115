import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})

export class PhoneNumberPipe implements PipeTransform {

  transform(phoneNumber: string, args?: string) {
    const LOCAL = 7;
    const DOMESTIC = 10;
    const INTERNATIONAL = 11;

    const noHyphens = phoneNumber.replace(/[^\d]/g, '');

    let formatted = '';  // default to no phone number if not local, domestic, or international.

    if (noHyphens.length === LOCAL) {
      const prefix = noHyphens.substring(0, 3);
      const lineNumber = noHyphens.substr(3);
      formatted = prefix + '-' + lineNumber;
    }

    if (noHyphens.length === DOMESTIC) {
      const areaCode = noHyphens.substr(0, 3);
      const prefix = noHyphens.substr(3, 3);
      const lineNumber = noHyphens.substr(6, 4);
      formatted = areaCode + '-' + prefix + '-' + lineNumber;
    }

    if (noHyphens.length === INTERNATIONAL) {
      const countryCode = noHyphens.substr(0, 1);
      const areaCode = noHyphens.substr(1, 3);
      const prefix = noHyphens.substr(4, 3);
      const lineNumber = noHyphens.substr(7);
      formatted = countryCode + '-' + areaCode + '-' + prefix + '-' + lineNumber;
    }

    return formatted;
  }
}
