<div class="pe-error-page -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Error Icon -->
            <div id="error" class="icon-container -oneX-icon-container">
                <div class="-oneX-icon" data-icon="warning_fill_32" style="height:48px;width:48px"> </div>
            </div>

            <!-- Header -->
            <h2 *ngIf="peError === 'assignment failed'" class="pe-error-header -oneX-heading--h2">Request failed to send</h2>
            <h2 *ngIf="peError === 'assignment exists'" class="pe-error-header -oneX-heading--h2">You’ve already requested a link</h2>

            <!-- Description -->
            <div *ngIf="peError === 'assignment failed'" class="-oneX-body--intro-md">Sorry, we could not send your request due to technical issues. You can try again later.</div>
            <div *ngIf="peError === 'assignment exists'" class="-oneX-body--intro-md">If you’d like us to send you a new one, please contact your claim handler at 
                <span *ngIf="!isDeviceMobile">800-SF-CLAIM</span>
                <a *ngIf="isDeviceMobile" href="tel:800-SF-CLAIM" class="-oneX-link--inline" id="800-SF-CLAIM">800-SF-CLAIM</a>.
            </div>

            <!-- Send Me The Link Button -->
            <div class="btn-container">
                <button id="pe-error-claim-overview"
                        class="-oneX-btn-primary"
                        (click)="goToClaimOverview()">Go to Claim Overview</button>
            </div>
        </div>
    </div>
</div>