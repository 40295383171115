<div #shopSelector>
<!-- Navigation Breadcrumb -->
<app-navigation-breadcrumb (goBackCallback)="goBack()"></app-navigation-breadcrumb>

<!-- Maintenance Banner - During -->
<div *ngIf="(maintenance$ | async)?.maintenanceOutage_during_flag" class="-oneX -oneX-notification">
  <section class="-oneX-notification__icon -oneX-notification--warning -oneX-offset-xl-2 -oneX-offset-lg-1">
    <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
  </section>
  <section class="notif-background -oneX-notification__text">
    {{(maintenance$ | async)?.maintenanceOutage_during}}
  </section>
</div>

<!-- Maintenance Banner - Prior -->
<div *ngIf="(maintenance$ | async)?.maintenanceOutage_prior_flag" class="-oneX -oneX-notification">
  <section class="-oneX-notification__icon -oneX-notification--warning -oneX-offset-xl-1 -oneX-offset-lg-1">
    <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
  </section>
  <section class="notif-background -oneX-notification__text -oneX-col-lg-10">
    {{(maintenance$ | async)?.maintenanceOutage_prior}}
  </section>
</div>

<!-- Shop Selector Page -->
<div class="-oneX -oneX-container-fluid container">
  <!-- Search Bar Container for Mobile -->
  <div
    class="search-container"
    [ngClass]="{'desktop-visibility': showShopDetails}"
    style="position: absolute; top: 0px; left: 0; right: 0"
    *ngIf="isMobile"
  >
    <app-search
      *ngIf="!showShopDetails"
      [currentPage]="currentPage"
      [isViewStatic]="isViewStatic"
      (changetoResultView)="changetoResultView($event)"
      [searchResultsPage]="true"
    ></app-search>
  </div>
  <div class="-oneX-row shop-map-container">
    <div
      id="map-visability-toggle"
      *ngIf="isMobile"
      role="button"
      tabindex="0"
      (keydown.enter)="toggleSidebarVisability()"
      #showList
      (click)="toggleSidebarVisability()"
      [ngStyle]="{ display: isSidebarVisabile ? 'none' : 'flex' }"
    >
      Show List
    </div>
    <!-- Sidebar -->
    <div
      id="list-resize-handle"
      class="shop-results"
      [@shops]="pageState"
      [style.height]="heightList + 'px'"
      [ngStyle]="{ 'aria-hidden': isSidebarVisabile ? 'false' : 'true',
                   'display': showShopDetails ? 'none' : '' }"
    >
      <!-- Search -->
      <div
        class="search-container"
        *ngIf="
          pageState === 'desktop_splitscreen' ||
          pageState === 'desktop_maponly' ||
          pageState === 'mobile_landscape_splitscreen'
        "
      >
        <app-search
          *ngIf="!showShopDetails"
          [currentPage]="currentPage"
          [isViewStatic]="isViewStatic"
          (changetoResultView)="changetoResultView($event)"
          [isSidebarVisabile]="isSidebarVisabile"
          [searchResultsPage]="true"
        >
        </app-search>
      </div>
      <div class="search-conatiner-divider"></div>

      <ng-container *ngIf="!isShopNotFound">
        <!-- DO NOT REFACTOR: this condition must check for string value due to bookmarking; value on qs is a string which overrides the defined type of the variable  -->
        <div class="shop-results-message">
          <div
            class="shop-results-fluid-content-noshops"
            *ngIf="sortedShops.length === 0 || sortedShops.length === undefined"
          >
            <div id="no-shop-max-radius">
              <p *ngIf="!isEstimateAssist">
                No
                <span
                  class="ss-details -oneX-link--inline clickable"
                  role="link"
                  [attr.tabindex]="isSidebarVisabile ? '0' : '-1'"
                  (keydown.enter)="showSelectServiceModal()"
                  (click)="showSelectServiceModal()"
                >
                  Select Service&#174;</span
                >
                in-network shops found.
              </p>
              <p *ngIf="isEstimateAssist">
                No
                <span
                  class="ss-details -oneX-link--inline clickable"
                  role="link"
                  [attr.tabindex]="isSidebarVisabile ? '0' : '-1'"
                  (keydown.enter)="showEstimateAssistModal()"
                  (click)="showEstimateAssistModal()"
                >
                  Estimate Assist Network</span
                >
                shops found.
              </p>
            </div>
            <hr />
            <p>
              Change your search criteria and try again, use our online chat
              feature, or contact your claim handler.
            </p>
          </div>
        </div>
      </ng-container>


      <div
        class="shop-results-fixed"
        *ngIf="sortedShops.length > 0"
        (panstart)="onPanStart($event)"
        (panmove)="onPan($event)"
        (panend)="onPanEnd($event)"
      >
        <div class="search-config-item clickable">
          <div class="drag-bar" *ngIf="getDragBarVisiblility()"></div>
        </div>

              <!-- Shop Name not found; PCA Ineligible -->
      <div
      id="non-sss"
      class="shop-results-message"
      *ngIf="(isShopNotFound || isIIOFlow) && sortedShops.length > 0"
    >
      <div
        id="shop-name-expanded-results-non-sss"
        class="shop-results-fluid-content-noshops"
      >
        <p>
          Sorry, we're unable to find
          <span class="search-term-provided">{{ searchTermError }}</span>
          as you entered in the search.
          You can try to search again using the shop city/state or ZIP code.<br><br>
          Note: The shop you entered may not participate in the Select Service® program.
        </p>
      </div>
    </div>

    <div
      id="non-sss-ie"
      class="shop-results-message"
      *ngIf="isShopNotFound && sortedShops.length > 0"
    >
      <div
        id="shop-name-expanded-results-non-sss-ie"
        class="shop-results-fluid-content-noshops"
      >
        <p>
          We're unable to find your body shop as entered in your search criteria.
          You can try your search again using the shop location or ZIP code instead.
        </p>
      </div>
    </div>

    <!-- Expanded Results messaging  -->
    <ng-container *ngIf="!isShopNotFound">
      <!-- DO NOT REFACTOR: this condition must check for string value due to bookmarking; value on qs is a string which overrides the defined type of the variable  -->
      <div class="shop-results-message">
        <div
          id="expanded-results"
          class="shop-results-fluid-content-noshops"
          *ngIf="sortedShops.length > 0 && isExpandSearch && !isEstimateAssist"
        >
          <p>
            We’ve expanded your search to locate additional shops near you since less than 3 shops were found.  Choose from the list below or try searching again.
          </p>
        </div>
        <!-- This EA result message shows when EA shops are returned from search -->
        <div
          id="expanded-results"
          class="shop-results-fluid-content-noshops"
          *ngIf="sortedShops.length > 0 && isEstimateAssist"
        >
          <p>
            No Select Service&#174; shops found within
            50 miles of
            <span class="search-term-provided">{{ searchTermError }}</span
            >.
          </p>
          <hr />
          <p>
            Choose an Estimate Assist shop from the list below or try searching again.
          </p>
        </div>
      </div>
    </ng-container>

        <div class="search-count-container">
          <div *ngIf="count >= 4 && shopIndexStart + 1 !== shopIndexEnd" class="-oneX-body--secondary search-count-text">{{
            shopIndexStart + 1 }}-{{ shopIndexEnd }} of {{ count }}
          </div>
          <div *ngIf="count >= 4 && shopIndexStart + 1 === shopIndexEnd" class="-oneX-body--secondary search-count-text">{{
            count }} of {{ count }}
          </div>
          <div *ngIf="count < 4" class="-oneX-body--secondary search-count-text">{{ count }}
          </div>
          <div *ngIf="!isEstimateAssist" class="-oneX-link--inline -oneX-link-secondary search-count-link-text" role="link"
            [attr.tabindex]="isSidebarVisabile ? '0' : '-1'" (keydown.enter)="showSelectServiceModal()"
            (click)="showSelectServiceModal()">
            Select Service shops
          </div>
          <div *ngIf="isEstimateAssist" class="-oneX-link--inline -oneX-link-secondary search-count-link-text" role="link"
            [attr.tabindex]="isSidebarVisabile ? '0' : '-1'" (keydown.enter)="showEstimateAssistModal()"
            (click)="showEstimateAssistModal()">
            Estimate Assist Network shops
          </div>
        
        </div>
        
        <div class="search-filters-container">
          <div class="sort-text-container">
            <div class="-oneX-body--secondary sorted-by">
              Sorted by:
            </div>
            <div class="-oneX-body--secondary sort-type">
              {{ selectedSortBy }}
            </div>
        
          </div>
          <div class="sort-filter-container" id="filter-btn" tabindex="0" data-onex-opensdrawer="sort-filter-drawer"
            onclick="oneX.Drawer.showDrawer(this.id)" (click)="setAnalyticsScreenId('sort-and-filter')"
            (focus)="isDrawerOpen ? setBackScreenId() : null">
            <div class="sort-and-filter-text -oneX-link--block -oneX-body--secondary">
              Sort & Filter {{resultFilterNo > 0 ? '('+resultFilterNo+')':''}}
            </div>
            <div class="filter-icon -oneX-icon -oneX-icon--variant1" data-icon="settings_24"></div>
          </div>
        
        </div>
        
      </div>

      <!-- Sidebar | Shop results -->
      <div class="shop-results-fluid">
        <!-- B2B Toggle -->
        <div class="b2b-toggle-container" *ngIf="sortedShops.length > 0">
          <div class="b2b-toggle-text -oneX-body--secondary">Only shops with availability</div>
          <!-- NOTE: Do not remove the B2B id, as that will break data tagging -->
          <input 
            [id]="b2bToggle" 
            class="-oneX-toggle-switch"
            type="checkbox" 
            role="switch"
            [attr.checked]="this.b2bToggle === 'b2bToggleOn' ? true : null"
            (click)="toggleB2B()"  
            (keydown.enter)="toggleB2B()"
            #toggleb2b
          />
        </div>
        <div *ngIf="isCertShopsSearch" class="cert-expansion-message">
          We've expanded your search and have located few shops that meet your certification criteria.
        </div>
        <!-- NOTE: shop-index is zero based -->
        <ul style="padding: 0; margin: 0; width: 100%; display:block">
        	<li style="padding: 0; margin: 0; width: 100%; display:block"  [attr.tabindex]="0" *ngFor="
        		let shop of sortedShops | slice: shopIndexStart:shopIndexEnd;
        		let i = index">
        <app-shop-card
          class="shop-card-wrapper"
          
          [attr.id]="'shop-' + (i + shopIndexStart)"
          [shop]="shop"
          [index]="i + 1 + shopIndexStart"
          [isSelected]="i === currentPageShopIndex"
          (click)="onShopCardClicked(i)"
          (keydown.enter)="onShopCardClicked(i)"
          (selection)="onShopSelected($event, i)"
          [isSidebarVisabile]="isSidebarVisabile"
          [isEstimateAssist]="isEstimateAssist"
          (useForClaim)="onShopUseForClaim($event, activeShopIndex)"
        >
        </app-shop-card>
        </li>
      </ul>
        <!-- Back / More Shops Button(s) -->
        <div *ngIf="sortedShops.length > 0" class="shops-btn-container-container" >
          <div class="shops-btn-container">
            <button
              *ngIf="currentPageIndex !== 1"
              class="-oneX-btn-large -oneX-btn-secondary"
              (click)="setShopIndex(false)"
            >
              Back
            </button>
            <button
              *ngIf="currentPageIndex !== pageCount"
              class="-oneX-btn-large -oneX-btn-secondary"
              (click)="setShopIndex(true)"
            >
              More shops
            </button>
          </div>
        </div>
        <div *ngIf="sortedShops.length > 0" class="google-terms-container">
          <div class="google-terms-text oneX-Hyperlink-inline--tertiary">
            © Google Terms of Service and Privacy Policy
          </div>
        </div>
      </div>

      <div class="shop-results-fluid-after">
        <!-- Select a shop later -->
        <ng-container *ngIf="shouldDisplaySelectAShopLater">
          <div class="footer-select-a-shop-later">
            <span
              class="-oneX-link--block--variant clickable"
              role="link"
              (click)="navigateBackToReferer()"
              (keydown.enter)="navigateBackToReferer()"
              [attr.tabindex]="isSidebarVisabile ? '0' : '-1'"
              >Select A Shop Later
            </span>
          </div>
        </ng-container>
        <!-- Footer / legal toggle -->
        <div class="footer-legal" #MockFooter>
          <span
            class="-oneX-link--block--variant clickable"
            role="link"
            (click)="showFooter()"
            (keydown.enter)="showFooter()"
            [attr.tabindex]="isSidebarVisabile ? '0' : '-1'"
            >&copy; Terms, Privacy, and Copyright
          </span>
        </div>
      </div>
    </div>

    <!-- Shop details for single shop -->
    <div *ngIf="showShopDetails && selectedShop"
         class="shop-details"
         [ngStyle]="{ display: isSidebarVisabile ? 'flex' : 'none' }">
      <app-shop-details-card
        [shop]="selectedShop"
        [isViewStatic]="isViewStatic"
        [currentPage]="currentPage"
        [isEstimateAssist]="isEstimateAssist"
        [isMobile]="isMobile"
        (returnToListView)="returnToListView()"
        (changetoResultView)="changetoResultView($event)"
        (useForClaim)="onShopUseForClaim($event, activeShopIndex)"
      >
      </app-shop-details-card>
    </div>

    <!-- Map -->
    <div
      *ngIf="pageState !== 'mobile_portrait_listonly'"
      class="shop-map"
      [ngClass]="{'desktop-visibility': showShopDetails}"
      [@map]="pageState"
      [style.height]="heightMap + 'px'"
    >
      <app-shop-map
        [sortedShops]="sortedShops"
        [youAreHereMarker]="youAreHereMarker"
        [shopIndexStart]="shopIndexStart"
        [shopIndexEnd]="shopIndexEnd"
        [updatedShopIndex]="currentPageShopIndex"
        [selectedShopIndex]="showShopDetails ? activeShopIndex : -1"
        [nextPageSubject]="nextPageSubject"
        (geolocate)="onGeolocation($event)"
        (markerSelectionIndex)="onShopCardClicked($event)"
        (queryResult)="onQuery($event)"
      >
      </app-shop-map>
    </div>
  </div>
  <button
    id="map-only-chevron-btn"
    aria-label="map-only-chevron-btn"
    *ngIf="getMapChevronVisibility()"
    class="map-only-chevron-position -oneX-icon--chevron--left"
    (click)="toggleSidebarVisability()"
    [attr.tabindex]="0"
    [ngClass]="{
      '-oneX-icon--chevron--left': isSidebarVisabile,
      '-oneX-icon--chevron--right': !isSidebarVisabile,
      'collapse-btn-no-sidebar': !isSidebarVisabile
    }"
  ></button>
</div>

<!-- Sort & Filter Drawer -->
<section id="sort-filter-drawer" class="-oneX-drawer-container">
  <div role="dialog" aria-labelledby="dialogLnkHdr">
    <h2 id="dialogLnkHdr" tabindex="-1" class="-oneX-drawer-header sort-filter-header">
      Sort & Filter  
   </h2>
    <div class="sort-filter-content">
      <div class="-oneX-chip-menu chip-menu">
        <button chiptype="select" class="-oneX-chip-button" aria-expanded="false" aria-controls="sort-chip" type="button">Sort by: Featured</button>
        <div id="sort-chip" tabindex="0" class="-oneX-chip-menu-content -oneX-hidden">
          <fieldset class="-oneX-fieldset">
            <legend class="-oneX-legend -oneX-clipped">Sort by</legend>
            <div class="-oneX-radio-group -oneX--stacked">
              <div class="-oneX-radio-item">
                <input (input)="sortByPerformance($event)" checked="checked" id="featured-sort" value="Sort by: Featured" class="-oneX-radio" name="sort-by" onclick="(function(){
                  oneX.$('[aria-controls=' + '\'' + 'sort-chip' + '\'' + ']').html('Sort by: Featured')
                })()" type="radio">
                <label for="featured-sort">Featured</label>
              </div>
              <div class="-oneX-radio-item">
                <input (input)="sortByDistance($event)" id="distance-sort" value="Sort by: Distance" class="-oneX-radio" name="sort-by" onclick="(function(){
                  oneX.$('[aria-controls=' + '\'' + 'sort-chip' + '\'' + ']').html('Sort by: Distance')
                })()" type="radio">
                <label for="distance-sort">Distance</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <hr class="-oneX-hr filter-row">
      <div class="-oneX-filter__container">
        <div class="-oneX-cards-container cards-container">
          <div class="-oneX-filter__header">
            <a (click)="resetAll()" (keydown.enter)="resetAll()" class="-oneX-link--block" tabindex="0" role="link" data-filter="reset">Reset All</a>
            <div>Filters (0)</div>
          </div>
          <hr class="-oneX-hr filter-row">
          <div class="-oneX-filter__section" data-close-filters="true"></div>
          <div *ngIf="certificates?.length > 0" class="-oneX-panel--expansion">
            <div class="-oneX-panel-control">
              <button type="button" aria-expanded="false" aria-controls="manufacturer-cert-panel" class="-oneX-panel-button">Manufacturer certification</button>
              <span class="-oneX-panel__chevron-down"></span>
            </div>
            <div id="manufacturer-cert-panel" class="-oneX-panel-content -oneX-hidden">
              <div class="-oneX-checkbox-group -oneX--stacked ">
                <div *ngFor="let certificate of certificates; index as i" class="-oneX-checkbox-item">
                  <input (input)="onCertificateSelection($event)" [attr.id]="'stacked'+(i+1)" type="checkbox" class="-oneX-checkbox" [attr.value]="certificate">
                  <label [attr.for]="'stacked'+(i+1)">{{certificate}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr *ngIf="certificates?.length > 0" class="-oneX-hr filter-row">
          <div class="-oneX-panel--expansion">
            <div class="-oneX-panel-control">
              <button type="button" aria-expanded="false" aria-controls="national-provider-panel" class="-oneX-panel-button">National provider</button>
              <span class="-oneX-panel__chevron-down"></span>
            </div>
            <div id="national-provider-panel" class="-oneX-panel-content -oneX-hidden">
              <div class="national-provider-copy -oneX-body--primary">
                National providers are recognized for their performance and service and have multiple locations available.
              </div>
              <div class="-oneX-checkbox-group -oneX--stacked ">
                <div class="-oneX-checkbox-item">
                  <input (input)="onNationalProviderSelection($event)" id="national-provider-yes" type="checkbox" class="-oneX-checkbox" value="National provider">
                  <label for="national-provider-yes">Show only national providers</label>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-result-btn">
            <hr class="-oneX-hr filter-row">
            <button *ngIf="filteredCount > 0" (click)="filterShops()" id="show-results" onclick="oneX.Drawer.hideDrawer()"
              type="submit" class="-oneX-btn-secondary -oneX-btn-medium">Show {{filteredCount}} results</button>
            <button *ngIf="filteredCount === 0" id="no-results" class="-oneX-btn-secondary -oneX-btn-medium" disabled>0 results
              found
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="-oneX-close-X-button" aria-label="Close Drawer">
      <span class="-oneX-icon--close">
      </span>
    </button>
  </div>
</section>

<!-- "Why cant I use my preferred shop" Modal -->
<app-modal #SelectService>
  <div
    tabindex="-1"
    id="modal-select-service"
    class="modal-body -oneX -oneX-body--secondary"
    style="border-radius: 4px"
    aria-labelledby="select-service-heading"
    role="dialog"
  >
    <button
      #selectServiceFocusElem
      type="button"
      class="-oneX-close-X-button modal-x-cancel"
      aria-label="Close Modal"
      (click)="hideSelectServiceModal()"
    >
      <span class="-oneX-icon--close clickable"></span>
    </button>
    <br />
    <h1 id="select-service-heading" style="font-size: 28px; text-align: center">
      State Farm Select Service
    </h1>
    <p style="text-align: center" class="select-margin">
      Get a reliable and quick {{ verbiage$ | async }} and repairs from an
      in-network shop.
    </p>
    <div id="explainer">
      <div id="clipboard" class="explainer-icon"></div>
      <div id="warranty" class="explainer-icon"></div>
      <div id="hand" class="explainer-icon"></div>
      <div id="convenience" class="explainer-text">
        <p class="heading">We'll Take Care of It</p>
        <p class="text">
          Get an {{ verbiage$ | async }}, complete repairs, and we will pay the
          shop directly.
        </p>
      </div>
      <div id="guarantee" class="explainer-text">
        <p class="heading">Limited Repair Warranty</p>
        <p class="text">
          You'll get a guaranteed completion date and limited lifetime warranty.
        </p>
      </div>
      <div id="shuttle" class="explainer-text">
        <p class="heading">Pick-up and Delivery</p>
        <p class="text">
          The shop can pick up your vehicle and deliver it back to you upon
          request.
        </p>
      </div>
    </div>
    <app-adsp></app-adsp>
  </div>
</app-modal>

<!-- EA Modal -->
<div id="modal-estimate-assist" class="-oneX-modal__container" style="display:none">
  <div class="-oneX-modal-content ea-modal-content" role="dialog">
    <!-- EA Modal Header -->
    <h1 *ngIf="isMobile" role="heading" aria-level="2" class="-oneX-modal-heading--h1 ea-modal-heading">Estimate Assist<br>Network</h1>
    <h3 *ngIf="!isMobile" role="heading" aria-level="2" class="-oneX-modal-heading--h3 ea-modal-heading">Estimate Assist Network</h3>
    <!-- EA Modal Intro -->
    <div class="-oneX-body--intro ea-modal-intro">
      You may not get the benefits of a Select Service shop, but we’ll help get you back on the road.<sup class="superscript">1</sup>
    </div>
    <!-- EA Modal Body -->
    <div class="-oneX-icon-container ea-modal-body-container">
      <div class="ea-modal-body">
        <div class="-oneX-icon ea-modal-icon" data-icon="locate_32"></div>
        <div class="-oneX-body--primary ea-modal-text">We help you find a shop nearby</div>
      </div>
      <div class="ea-modal-body">
        <div class="-oneX-icon ea-modal-icon" data-icon="repair_32"></div>
        <div class="-oneX-body--primary ea-modal-text">You’re free to choose any listed shop</div>
      </div>
      <div class="ea-modal-body">
        <div class="-oneX-icon ea-modal-icon" data-icon="payment_32"></div>
        <div class="-oneX-body--primary ea-modal-text">We’ll pay you directly</div>
      </div>
    </div>
    <!-- EA Modal Footnote -->
    <div class="-oneX-body--footnote ea-modal-footnote">
      Not all repair facilities outside of our Select Service shops charge the same. Repair {{ verbiage$ | async }} can change once work begins. If there are additional damages found during repair, you may be responsible for any costs not agreed to by State Farm. This may include, but not limited to, storage fees and rental expenses.<br>
      <br>
      <sup class="superscript">1</sup> Estimate Assist shops may not include written national lifetime repair warranties, guaranteed completion dates, vehicle wash and vacuum, and pick-up and delivery upon request.
    </div>
      <button type="button" class="-oneX-close-X-button" aria-label="Close Modal">
        <span class="-oneX-icon--close">
        </span>
      </button>
  </div>
</div>

<!-- B2B Modal -->
<app-modal #B2B>
  <div id="modal-b2b" class="modal-body">
    <!-- B2B Modal Header -->
    <h2 role="heading" aria-level="2" class="b2b-modal-heading -oneX-modal-heading--h2">{{ sortedShops[activeShopIndex]?.name | titlecase }} only has appointments available for drivable vehicles!</h2>
    <!-- B2B Modal Body -->
    <div class="-oneX-body--primary">If your vehicle is not drivable, {{ sortedShops[activeShopIndex]?.name | titlecase }} is not currently accepting new appointments due to longer wait times. Would you still like to use this shop?</div>
    <!-- B2B Buttons -->
    <!-- NOTE: Do not remove the B2B button ids, as that will break data tagging -->
    <div class="b2b-button-container">
      <button id="b2b-use-shop-anyway-btn" 
              class="b2b-btn -oneX-btn-secondary"
              (keydown.enter)="useShopAnyways()"
              (click)="useShopAnyways()">Use this shop
      </button>
      <button #b2bFocusElem
              id="b2b-continue-search-btn" 
              class="b2b-btn -oneX-btn-primary"
              (keydown.enter)="continueSearch()"
              (click)="continueSearch()">Continue Search
      </button>
    </div>
  </div>
</app-modal>

<!-- PCA Redirect Modal -->
<app-modal
  #PCARedirect
  class="modal-body -oneX -oneX-body--secondary"
  style="border-radius: 4px"
>
  <div
    id="modal-pca"
    class="modal-body -oneX -oneX-body--secondary"
    aria-labelledby="notInSelectService"
    role="dialog"
  >
    <div class="-oneX-row-fluid">
      <p class="modal-text" id="notInSelectService">
        We found your shop, but it's not in our Select Service Program. Choose
        from the list or try searching again.
      </p>
      <button
        class="-oneX-btn-primary -oneX-btn-primary"
        (click)="PCARedirect.hide()"
      >
        Continue
      </button>
    </div>
  </div>
</app-modal>

<!-- Confirmation Modal -->
<app-modal #Confirmation>
  <div id="modal-confirmation" class="modal-body -oneX-font-Mecherle" aria-labelledby="confirmReviewHeader" role="dialog">
    <div class="-oneX-row-fluid">
      <div class="modal-shop-details">
        <!-- Confirmation Header -->
        <h3 id="confirmReviewHeader" class="modal-title -oneX-heading--h3">Review and confirm</h3>
        <div class="modal-body-alignment -oneX-body--intro -oneX-col-sm-8 -oneX-col-md-12 -oneX-col-lg-12 -oneX-col-xl-12">
          <p *ngIf="!(currentShop$ | async) else secondarySubheader">Don’t worry if you change your mind—you can make changes later if needed.</p>
          <ng-template #secondarySubheader><p>You can make additional changes later if needed.</p></ng-template>
        </div>
        <!-- Failed Confirmation -->
        <div *ngIf="isFailedConfirmation" class="-oneX -oneX-notification">
          <section class="-oneX-notification__icon -oneX-notification--error">
            <span class="-oneX-icon--error--inverse" aria-label="error" role="img"></span>
          </section>
          <section class="-oneX-notification__text">
            The confirmation did not go through. If problem persists, please call <br />800-SF-CLAIM
          </section>
        </div>
      </div>
    </div>
    <!-- Selection Information -->
    <div class="-oneX-row-fluid">
      <div class="modal-info-alignment -oneX-offset-sm-2 -oneX-col-sm-8">
        <!-- Vehicle -->
        
        <p *ngIf="vehicleYear && vehicleMake && vehicleModel" class="vehicle-spacing" >
          <span class="vehicle-claim-info -oneX-body--primary -oneX-heading--h6 -oneX-offset-md-2 -oneX-offset-xl-2 -oneX-offset-lg-2">
            Vehicle Info:
          </span>
          <span class="vehicle-padding -oneX-font--semi -oneX-heading--h6 -oneX-offset-sm-1">
            <br class="mobile" />{{ vehicleYear }} {{ vehicleMake }} {{ vehicleModel }}
          </span>
        </p>
      
        <!-- Claim Number -->
        <p *ngIf="!(currentShop$ | async)" class="claim-spacing">
          <span class="vehicle-claim-info -oneX-body--primary -oneX-heading--h6 -oneX-offset-md-2 -oneX-offset-xl-2 -oneX-offset-lg-2">
            Claim Number:
          </span>
          <span class="claim-padding -oneX-font--semi -oneX-heading--h6 -oneX-offset-sm-1">
            <br class="mobile" />{{ claimNumber$ | async }}
          </span>
          <br />
        </p>
        <!-- Current Repair Shop Assignment -->
        <div *ngIf="(currentShop$ | async)?.shopInfo as currentShop">
          <div *ngIf="currentShop.lastName" class="current-shop-spacing repair-shop-list -oneX-offset-md-2 -oneX-offset-xl-2 -oneX-offset-lg-2"> 
              <div class="modal-subtitle -oneX-body--primary -oneX-heading--h6">
                Change from:
              </div>
              <!-- Repair Shop Information -->
              <div class="current-shop-padding -oneX-font--semi -oneX-heading--h6 -oneX-offset-md-1 -oneX-offset-xl-1">
                {{ currentShop.lastName }}
                <br />{{ currentShop.address.line }}
                <br />{{ currentShop.address.city }},
                {{ currentShop.address.state }}
                {{ currentShop.address.postalCode | slice: 0:5 }}
              </div>
          </div>
        </div>
        <!-- Selected Repair Shop -->
        <div *ngIf="sortedShops.length > 0" class="repair-shop-spacing -oneX-offset-md-2 -oneX-offset-xl-2 -oneX-offset-lg-2">
          <div class="repair-shop-list">
            <div class="modal-subtitle -oneX-body--primary -oneX-heading--h6">
              <p *ngIf="!(currentShop$ | async) else secondaryRepairText">Repair Shop:</p>
              <ng-template #secondaryRepairText><p>Change to:</p></ng-template>
            </div>
            <!-- Repair Shop Information -->
            <div [ngClass]="{
                  'repair-padding': !(currentShop$ | async),
                  'repair-padding-secondary': (currentShop$ | async)
                 }"
                 class="-oneX-font--semi -oneX-heading--h6 -oneX-offset-md-1 -oneX-offset-xl-1">
              {{ sortedShops[activeShopIndex]?.name | titlecase }}
              <br />{{ sortedShops[activeShopIndex]?.address.toUpperCase() | titlecase }}
              <br />{{ sortedShops[activeShopIndex]?.city | titlecase}},
              {{ sortedShops[activeShopIndex]?.state | titlecase }}
              {{ sortedShops[activeShopIndex].postalCode | slice: 0:5 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Confirm / Cancel Buttons -->
    <div>
      <button
        #confirmationFocusElem
        id="confirmBtn"
        class="-oneX-btn-large -oneX-btn-primary"
        [disabled]="isConfirmation"
        (click)="onShopConfirmation()">
        <p *ngIf="!(currentShop$ | async) else secondaryConfirmBtn">Confirm shop</p>
        <ng-template #secondaryConfirmBtn><p>Confirm change</p></ng-template>
      </button>
    </div>
    <div class="cancel-link -oneX-link--inline"
         role="link"
         tabindex="0"
         (click)="hideConfirmationModal()"
         onclick="return false;">Cancel
    </div>
  </div>
</app-modal>
</div>