import { ShopName } from '../model/shop-name.model';

export class ShopsConstants {
    public static shop_names = [
        new ShopName('CALIBER COLLISION', 'CALIBER COLLISION'),
        new ShopName('JOE HUDSON COLL CTR', 'JOE HUDSON\'S COLLISION CENTER'),
        new ShopName('SERVICE KING', 'SERVICE KING COLLISION'),
        new ShopName('CARSTAR', 'CARSTAR'),
        new ShopName('GERBER COLLISION & GLASS', 'GERBER COLLISION & GLASS'),
        new ShopName('FIX AUTO', 'FIX AUTO'),
        new ShopName('CRASH CHAMPIONS', 'CRASH CHAMPIONS'),
        new ShopName('KAIZEN COLLISON CENTER', 'KAIZEN COLLISON CENTER')
    ];
}
