import {
    Component,
    EventEmitter,
    OnInit,
    Inject,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { shopHours, shopHoursToggle } from './shop-details-card.component.animations';

// SERVICES
import { DataAnalyticsService } from '../service/data-analytics.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { SFLoggerService } from 'app/service/sf-logger.service';
import { ShopInfoService } from '../service/shop-info.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { WINDOW } from '../service/window.service';

// MODELS
import { ChangeView } from 'app/model/change-view.model';
import { Shop } from 'app/model/shop.model';
import { WindowReference } from 'app/model/window-reference.model';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

  @Component({
    selector: 'app-shop-details-card',
    templateUrl: './shop-details-card.component.html',
    styleUrls: ['./shop-details-card.component.css'],
    animations: [shopHours, shopHoursToggle],
  })
  export class ShopDetailsCardComponent implements OnInit, OnDestroy {
    @Input() shop: Shop;
    @Input() isViewStatic: boolean;
    @Input() currentPage: string;
    @Input() isEstimateAssist: boolean;
    @Input() isMobile: boolean;
    @Output() returnToListView = new EventEmitter();
    @Output() changetoResultView = new EventEmitter<ChangeView>();
    @Output() useForClaim: EventEmitter<Shop> = new EventEmitter<Shop>();

    oneX: any;
    loggingDetails: any;
    userIsEligible$: Observable<boolean>;
    isSecondaryAssignment: boolean;
    isShopHoursVisible: boolean;
    actorId: String;
    fileName = 'shop-details-card.component.ts';
    
    private ngUnsubscribe = new Subject();

  
    constructor(
      @Inject(WINDOW) private window: Window,
      private dataAnalyticsService: DataAnalyticsService,
      private clientService: ServiceClientsService,
      private sfLoggerService: SFLoggerService,
      private shopInfoService: ShopInfoService,
      private urlResolverService: UrlResolverService,
      private windowRef: WindowReference
    ) {
        this.oneX = this.window['oneX'];
        this.loggingDetails = [ SFLoggerFields.DEV_REPORTING_FILE, this.fileName ];
    }
  
    ngOnInit() {
      this.oneX.Icons.init();
      
      this.userIsEligible$ = this.clientService.isEligibleForRepairAssignment();
      this.clientService.getCurrentShopDetail()
        .pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(res => {
          if (res) {
            this.actorId = res.ActorID;
            this.isSecondaryAssignment = true;
          } else {
            this.isSecondaryAssignment = false;
          }
      });
  
      this.shop.address = this.shopInfoService.correctShopAddressFormat(this.shop.address);
    }
  
    getCertifications() {
      const certificationsArray: string[] = [];
      let certifications: string;

      if (this.shop.certifications) {
        for (let i = 0; i < this.shop.certifications.length; i++) {
          certificationsArray.push(Object.values(this.shop.certifications[i])[0]);
        }
        certifications = certificationsArray.sort().join(', ');
      }

      return certifications;
    }

    closeShopDetails() {
      this.returnToListView.emit();
    }
  
    toggleShopHoursVisability() {
      this.isShopHoursVisible = !this.isShopHoursVisible;
    }

    navigateToShopWebsite() {
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_SHOP_WEBSITE_CLICKED, 'Customer clicked shop website link', this.loggingDetails);
      this.windowRef.open(this.shop.placeDetails.websiteUrl, '_blank');
    }
  
    promptForUserConfirmation() {
      this.dataAnalyticsService.setShopUnavailability(this.shop.unavailable);

      if (this.actorId === this.shop.id) {
        this.windowRef.navigateTo(this.urlResolverService.buildHubRedirectUrl());
      } else {
        this.useForClaim.emit(this.shop);

        // SPARKL Logging
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_PROMPTED_TO_CONFIRM, 'Customer prompted to confirm', this.loggingDetails);

        if (this.isEstimateAssist) {
          this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('estimate-assist-use-this-shop', this.clientService.assignmentCount));
        } else {
          if (this.shop.unavailable) {
            this.dataAnalyticsService.sendPageData('still-use-this-shop-modal');
          } else {
            this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('use-this-shop', this.clientService.assignmentCount));
          }
        }
        if (this.shop.certifications) {
          this.dataAnalyticsService.setVehicleCert('yes');
        } else {
          this.dataAnalyticsService.setVehicleCert('no');
        }
        if (this.shop.nationalProvider) {
          this.dataAnalyticsService.setNationalCert('yes');
        } else {
          this.dataAnalyticsService.setNationalCert('no');
        }
      }
    }
  
    ngOnDestroy() {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }