import { Injectable } from '@angular/core';
import { ShopInfo } from '../model/shop-info.model';
import { StorageService } from './storage.service';

@Injectable()
export class ShopInfoService {
  shopInfo: ShopInfo;

  constructor (
    private storageService: StorageService,
  ) { }

  setShopInfo(info: ShopInfo) {
    this.shopInfo = info;
    this.storageService.setSessionStorage('shopInfo', JSON.stringify(this.shopInfo));
  }

  getShopInfo() {
    if (this.shopInfo === undefined) {
      this.shopInfo = JSON.parse(this.storageService.getSessionStorage('shopInfo'));
    }
    return this.shopInfo;
  }

  correctShopAddressFormat(address: string): string {
    const cardinalDir = ['n', 's', 'e', 'w', 'nw', 'ne', 'se', 'sw'];
    const alphaOnlyRegex = /^[a-zA-Z]+$/;
    if (address) {
      address = address.toLowerCase();

      const splitAddr = address.split(' ');

      for (let i = 0; i < splitAddr.length; i++) {
        if ((alphaOnlyRegex.test(splitAddr[i]) && splitAddr[i].length > 2) ||
            (alphaOnlyRegex.test(splitAddr[i]) && !cardinalDir.includes(splitAddr[i]))) {
          splitAddr[i] = this.capitalizeFirstLetter(splitAddr[i]);
        } else if (alphaOnlyRegex.test(splitAddr[i])) {
          splitAddr[i] = splitAddr[i].toUpperCase();
        }
      }
      address = splitAddr.join(' ');
    }

    return address;
  }

  private capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
