<app-navigation-breadcrumb>
</app-navigation-breadcrumb>

<div class="-oneX-container -oneX center">

  <div class="-oneX-row-fluid">
    <div class="title-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Icon & Request Sent -->
      <div class="icon-title-container">
        <img class="icon-check" src="assets/confirmation.svg" alt="" />
        <h1 class="title-header">Request Sent</h1>
      </div>

      <!-- Shop Information Text: Select Service -->
      <div *ngIf="!isEstimateAssist" class="title-content -oneX-heading--h4">
          <!-- Shop Name -->
          <span>
            <a class="-oneX-link--block  title-content-link"
              id="repairShopName"
              role="link"
              [attr.href]="'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + shopInfo?.placeId"
              target="_blank">{{shopInfo?.shopName | titlecase}}
            </a>
          </span>
          <span> will call you to schedule the {{(verbiage$ | async)}}, repairs, and tow, if needed – or call them at </span>
          <!-- Phone Number-->
          <span *ngIf="isDeviceMobile">
            <a class="-oneX-link--inline -oneX-hyperlink--block  title-content-link"
              role="link"
              href="tel:{{shopInfo?.phone}}">
              {{shopInfo?.phone | phoneNumber}}
            </a>.
          </span>
          <span class="title-content-link" *ngIf="!isDeviceMobile">{{shopInfo?.phone | phoneNumber}}.</span>
        </div>

        <!-- Shop Information Text: Estimate Assist -->
        <div *ngIf="isEstimateAssist" class="title-content -oneX-heading--h4">
          <!-- Shop Name -->
          <span>Call
            <a class="-oneX-link--block  title-content-link"
              id="repairShopName"
              role="link"
              [attr.href]="'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + shopInfo?.placeId"
              target="_blank">{{shopInfo?.shopName | titlecase}}
            </a> at
          </span>
          <!-- Phone Number-->
          <span *ngIf="isDeviceMobile">
            <a class="-oneX-link--inline -oneX-hyperlink--block  title-content-link"
              role="link"
              href="tel:{{shopInfo?.phone}}">
              {{shopInfo?.phone | phoneNumber}}
            </a>
          </span>
          <span class="title-content-link" *ngIf="!isDeviceMobile">{{shopInfo?.phone | phoneNumber}}</span>
          <span> to schedule your {{(verbiage$ | async)}}, repairs, and tow.  It may take 4-6 business days to process
                 after receiving your {{(verbiage$ | async)}} from the shop.
          </span>
          <!-- Contextual Help -->
          <span class="-oneX-contextual-help-container -oneX-standalone-help">
            <!-- ID added for data tagging - do not change without contacting Data Capture  -->
            <button id="confirmation-estimate-assist-tooltip"
                    type="button"
                    class="-oneX-btn-control -oneX-contextual-help"
                    aria-label="help for estimate assist"
                    aria-controls="helpContainer"
                    (focus)="initializeContextualHelp()"
                    (mouseenter)="initializeContextualHelp()">
              <div class="-oneX-icon--controls--question -onex-standalone-help-button-16px" role="img"></div>
              <div class="-oneX-notch"></div>
            </button>
            <span class="-oneX-contextual-help--text" id="helpContainer">
              <div>
                A repair estimate and photos are required from your repair facility. We will send an electronic request to your facility.
                Once received, your repair estimate and photos will be reviewed by a State Farm appraiser.
              </div>
              <button type="button" aria-label="Close Help" class="-oneX-close-X-button">
                <span class="-oneX-icon--close"></span>
              </button>
            </span>
          </span>
        </div>
    </div>
  </div>

  <div *ngIf="rentalEligible && !isClaimant() && rentalOptOut" class="claim-overview-button">
    <button id="go-to-claim-overview" (keydown.enter)="redirect()" (click)="redirect()" class="-oneX-btn-primary">Go to Claim Overview</button>
  </div>

  <!-- Rental Block (rental coverage eligible and rental opt in) -->
  <div class="-oneX-row-fluid" *ngIf="rentalEligible && !isClaimant() && !rentalOptOut">
    <div class="rental-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Box -->
      <div class="rental-block">
        <div class="white-box-eligible">
          <div class="-oneX-heading--h3 rental-title-eligible">Request rental now, save time later.</div>
        </div>
        <div class="rental-content-1">Enterprise or Hertz will meet you at the repair shop when you drop your vehicle off for repairs. Learn more about
          <button id="ni-rental-coverage-link"
            class="-oneX-link--inline--variant rental-coverage"
            data-oneX-opensDrawer="NIcoverageDrawer"
            onclick="oneX.Drawer.showDrawer(this.id)"
            (click)="rentalFAQ()"
            (focus)="drawerStatus === 'block' ? setScreenId() : null"
            tabIndex="0"
            role="link">
            rental coverage
        </button>.
        </div>
        <div class="rental-button">
          <button id="ni-request-my-rental-btn" class="-oneX-btn-primary"
                  (click)="routeToRentalVehiclePreference(true)"
                  (keydown.enter)="routeToRentalVehiclePreference(true)"
                  style="cursor: pointer">Request my rental</button>
        </div>
        <div class="rental-content-2">
          <span>Want them to meet you somewhere else?<br>
          <a
            id="choose-your-vehicle-link"
            class="-oneX-link--inline--variant clickable"
            role="link"
            tabindex="0"
            (click)="routeToRentalVehiclePreference(false)"
            (keydown.enter)="routeToRentalVehiclePreference(false)"> Choose your vehicle now</a>
            and discuss options later.
          </span>
        </div>
      </div>
      <!-- Claim Status -->
      <div class="claim-status">I'm not ready.
        <a
          id="ni-my-claim-link-rental-eligible"
          class="-oneX-link--inline"
          role="link"
          tabIndex="0"
          (keydown.enter)="redirect()"
          (click)="redirect()"
          style="cursor: pointer">
          Continue to my claim.
        </a>
      </div>
    </div>
  </div>

  <!-- Rental Block (rental coverage eligible and rental opt out) -->
  <div class="-oneX-row-fluid" *ngIf="rentalEligible && !isClaimant() && rentalOptOut">
    <div class="rental-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Box -->
      <div class="rental-opt-out-block">
        <div class="white-box-rental-opt-out">
          <div class="-oneX-heading--h3 rental-title-opt-out">You have opted out of rental</div>
        </div>
        <div class="-oneX-body--primary rental-opt-out-content">If you need a rental, you can opt back in and request one now to save time later. Learn more about
          <button id="ni-roo-rental-coverage-link"
            class="-oneX-link--inline rental-coverage"
            data-oneX-opensDrawer="NIcoverageDrawer"
            onclick="oneX.Drawer.showDrawer(this.id)"
            (click)="rentalFAQ()"
            (focus)="drawerStatus === 'block' ? setScreenId() : null"
            tabIndex="0"
            role="link">
            rental coverage
        </button>.
        </div>
        <div class="rental-button-link">
          <a id="ni-roo-request-my-rental-btn" (click)="routeToRentalVehiclePreference(true)"
          (keydown.enter)="routeToRentalVehiclePreference(true)"
          style="cursor: pointer" class="-oneX-link--block">Request my rental</a>
        </div>
      </div>
    </div>
  </div>



  <!-- Rental Block (Named Insured rental not eligible) -->
  <div class="-oneX-row-fluid" *ngIf="!rentalEligible && !isClaimant() && !rentalCompleted">
    <div class="rental-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Box -->
      <div *ngIf="!rentalCompleted" class="rental-block">
        <div class="white-box-not-eligible">
          <div class="-oneX-heading--h3 rental-title-not-eligible">Will you need a rental?</div>
        </div>
        <div class="-oneX-body--primary rental-not-eligible-content-1">
          Enterprise and Hertz provide State Farm customers with discounted rates. Call Enterprise at
          <a *ngIf="isDeviceMobile"
            href="tel:855-266-9565"
            class="-oneX-link--inline">
            855-266-9565
          </a>
          <a *ngIf="!isDeviceMobile"
            style="
              font-weight: 500;
              color: #313131;
            ">
            855-266-9565
          </a>or Hertz at
          <a *ngIf="isDeviceMobile"
            href="tel:800-582-7499"
            class="-oneX-link--inline">
            800-582-7499
          </a>
          <a *ngIf="!isDeviceMobile"
            style="
              font-weight: 500;
              color: #313131;
            ">
            800-582-7499
          </a>
            to request your rental today.
        </div>
        <div class="-oneX-body--primary rental-not-eligible-content-2">No thanks - view
          <span>
            <a
              id="ni-my-claim-link-rental-not-eligible"
              class="-oneX-link--inline"
              role="link"
              tabIndex="0"
              (keydown.enter)="redirect()"
              (click)="redirect()"
              style="cursor: pointer; display: inline-block">
              my claim
            </a>
          </span> instead.
        </div>
      </div>
    </div>
  </div>

  <!-- Rental help topics -->

  <section  id="NIcoverageDrawer" class=" -oneX-drawer-container">
    <!-- Rental help topics drawer (Named Insured) -->
    <div >
      <div  class="help-panel">
        <hr class="-oneX-hr"/>
        <div   class="-oneX-panel--expansion">
          <div class="-oneX-panel-control">
            <button type="button" aria-expanded="false" aria-controls="NiDetailsDiv" class="-oneX-panel-button ">Help Topics</button>
            <span class="collapse-button -oneX-panel__chevron-down"></span>
          </div>
          <div  id="NiDetailsDiv" tabindex="0" class="-oneX-panel-content -oneX-hidden">
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-preferred-shop')">Can I use a different rental provider?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-coverage-what')">What does State Farm cover?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-cost')">Who pays additional costs?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-extend')">How do I extend my rental if repairs take longer?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-vehicle')">What happens if my vehicle is totaled?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-ni-coverage-when')">When does rental coverage begin?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-not-required')">What if I don’t need a rental?</a></p>
          </div>
        </div>
        <hr class="-oneX-hr"/>
      </div>
    </div>


    <div  role="dialog" aria-labelledby="dialogLnkHdr">

      <div id="dialogLnkHdr" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-preferred-shop" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">Can I use a different rental provider?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        Yes, you can choose any rental provider. However, State Farm has an agreement with Enterprise and Hertz, which may provide lower rates than those available in the retail market. If you use a rental provider other than Enterprise and Hertz, you may incur out of pocket costs.<br>
        <br>
        To request a rental provider other than Enterprise or Hertz, contact your claim handler or use the online chat feature. </div>
      <div id="dialogLnkHdr" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-coverage-what" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What does State Farm cover?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        We have worked with Enterprise and Hertz to provide rental vehicles with rates that may be lower than other rental providers. We will pay the rental partner directly.<br>
        <br>
        State Farm will pay the daily rental rate per vehicle per authorized day. If you reach your coverage limit, you are responsible for additional costs.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-cost" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">Who pays additional costs?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        Enterprise and Hertz may require a security deposit from you. Any additional fees and costs, which may include gas, security deposit, fuel deposit, tolls, collision damage waiver (CDW), and insurance, will be at your expense.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-extend" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">How do I extend my rental if repairs take longer?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        If repairs take longer than expected, your repair shop may discuss additional repair timelines with your rental vendor.  The cost for your rental vehicle will be covered up to your policy coverage limits or until repairs are complete.
      </div>
      <div id="dialogLnkHdr selected-shop" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-vehicle" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What happens if my vehicle is totaled?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        If the vehicle is determined to be a total loss, you may have up to 7 additional days of rental car coverage, within your policy limits, from the date the settlement valuation is reviewed with you. The additional coverage is intended to assist you during the next steps of the claims process while searching for a replacement vehicle.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-ni-coverage-when" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">When does rental coverage begin?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        Rental coverage is available when your vehicle is left at the repair facility for scheduled repairs. If your vehicle is non-drivable, coverage starts immediately.
        <br><br>
        Coverage ends when repairs are complete, or when you reach the maximum payable amount provided by your policy for rental.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-not-required" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What if I don’t need a rental?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body-last">
        You may receive some reminders about setting up your rental, but don’t worry, you can set it up when it is convenient for you.
      </div>
      <button  onclick="oneX.Drawer.hideDrawer()"  class="-oneX-close-X-button" aria-label="Close Drawer">
        <span class="-oneX-icon--close help-close">
        </span>
      </button>
    </div>

    <!-- Rental help topics drawer (Claimant) -->


  </section>

  <section  id="coverageDrawer" class=" -oneX-drawer-container">
    <div >
      <div  class="help-panel">
        <hr class="-oneX-hr"/>
        <div class="-oneX-panel--expansion">
          <div class="-oneX-panel-control">
            <button type="button" aria-expanded="false" aria-controls="ClaimantDetailsDiv" class="-oneX-panel-button ">Help Topics</button>
            <span class="collapse-button -oneX-panel__chevron-down"></span>
          </div>
          <div  id="ClaimantDetailsDiv" tabindex="0" class="-oneX-panel-content -oneX-hidden">
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-coverage-what')">What does State Farm cover?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-cost')">Who pays additional costs?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-diff-vehicle')">What if I need a different vehicle?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-diff-company')">Can I use a different rental company?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-extend')">How do I extend my rental if repairs take longer?</a></p>
            <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('rental-claimant-totaled-vehicle')">What happens if my vehicle is totaled?</a></p>
          </div>
        </div>
        <hr class="-oneX-hr"/>
      </div>
    </div>


    <div  role="dialog" aria-labelledby="dialogLnkHdr">

      <div id="dialogLnkHdr" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-coverage-what" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What does State Farm cover?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        We cover your rental vehicle with Enterprise and Hertz and will pay them directly. Your rental coverage begins when your car is left at the repair shop – or immediately when your vehicle is not drivable.</div>
      <div id="dialogLnkHdr" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-cost" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">Who pays additional costs?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        Enterprise and Hertz may require a security deposit from you. Any additional fees and costs, which may include gas, security deposit, fuel deposit, tolls, collision damage waiver (CDW), and insurance, will be at your expense.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-diff-vehicle" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What if I need a different vehicle?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        To request a different vehicle, contact your claim handler or use the online chat feature.
      </div>
      <div id="dialogLnkHdr selected-shop" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-diff-company" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">Can I use a different rental company?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        To request a rental provider other than Enterprise or Hertz, contact your claim handler or use the online chat feature.
      </div>
      <div id="dialogLnkHdr selected-shop" class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-extend" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">How do I extend my rental if repairs take longer?</div>
      </div>
      <div class="-oneX-body--primary help-drawer-body">
        If repairs take longer than expected, your repair shop may discuss additional repair timelines with your rental vendor.  The cost for your rental vehicle will be covered up to your policy coverage limits or until repairs are complete.
      </div>
      <div id="dialogLnkHdr"  class="drawer-headings -oneX-font-Mecherle">
        <div id="rental-claimant-totaled-vehicle" role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--med">What happens if my vehicle is totaled?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body-last">
        If the vehicle is determined to be a total loss, your rental car may be authorized up to 5 additional days from the date the settlement valuation is reviewed with you. The 5 added days are intended to help you during the next steps of the claims process while searching for a replacement vehicle.
      </div>
      <button  onclick="oneX.Drawer.hideDrawer()"  class="-oneX-close-X-button" aria-label="Close Drawer">
        <span class="-oneX-icon--close help-close">
        </span>
      </button>
    </div>

  </section>

  <!-- Rental Block (claimant rental coverage eligible) -->
  <div class="-oneX-row-fluid" *ngIf="rentalEligible && isClaimant()">
    <div class="rental-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Box -->
      <div class="rental-block">
        <div class="claimant-white-box-eligible">
          <div class="-oneX-heading--h3 claimant-rental-title-eligible">Need a rental?</div>
        </div>
        <div class="-oneX-body--primary claimant-rental-content-1">
          You can request a rental from Enterprise or Hertz and they’ll call you within 4 hours to confirm the details. Learn more about
          <button id="claimant-rental-coverage-link"
            class="-oneX-link--inline--variant claimant-rental-coverage"
            data-oneX-opensDrawer="coverageDrawer"
            onclick="oneX.Drawer.showDrawer(this.id)"
            (click)="claimantRentalFAQ()"
            (focus)="drawerStatus === 'block' ? setScreenId() : null"
            tabIndex="0"
            >rental coverage
        </button>.
        </div>
        <div class="claimant-rental-button">
            <button
              id="claimant-request-my-rental-btn"
              class="-oneX-btn-primary"
              style="cursor: pointer"
              (keydown.enter)="redirect()"
              (click)="redirect()">Request my rental
            </button>
        </div>
      </div>
      <div class="-oneX-body--primary claimant-rental-content-2">No thanks - view
        <span>
          <a
            id="claimant-my-claim-link-rental-eligible"
            class="-oneX-link--inline"
            role="link"
            tabIndex="0"
            (keydown.enter)="redirect()"
            (click)="redirect()"
            style="cursor: pointer;">
            my claim
          </a> instead.
        </span>
      </div>
    </div>
  </div>



  <!-- Rental Block (Named Insured rental already completed or Claimant rental not eligible / already completed) -->
  <div class="-oneX-row-fluid" *ngIf="!rentalEligible && isClaimant() || rentalCompleted && !isClaimant()">
    <div class="rental-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Box -->
      <div class='claim-button'>
        <button
          class="-oneX-btn-secondary"
          style="cursor: pointer"
          (keydown.enter)="redirect()"
          (click)="redirect()">Continue to your claim</button>
      </div>
    </div>
  </div>

  <!-- Claimants Rental Coverage Drawer -->
  <section id="claimantCoverageDrawer" class="-oneX-drawer-container claimant-drawer-container">
    <div class="claimant-help-panel">
      <hr class="-oneX-hr"/>
      <div class="-oneX-panel--expansion">
        <div class="-oneX-panel-control">
          <button type="button" aria-expanded="false" aria-controls="detailsDiv" class="-oneX-panel-button clickable">Help Topics</button>
          <span class="-oneX-panel__chevron-down collapse-button"></span>
        </div>
        <div id="detailsDiv" class="-oneX-panel-content -oneX-hidden">
          <p><a tabindex="0" class="-oneX-link--inline help-topics-panel clickable" (keydown.enter)="scrollToHelpTopic('cover')" (click)="scrollToHelpTopic('cover')">What does State Farm cover?</a></p>
          <p><a tabindex="0" class="-oneX-link--inline help-topics-panel clickable" (keydown.enter)="scrollToHelpTopic('costs')" (click)="scrollToHelpTopic('costs')">Who pays additional costs?</a></p>
          <p><a tabindex="0" class="-oneX-link--inline help-topics-panel clickable" (keydown.enter)="scrollToHelpTopic('vehicle')" (click)="scrollToHelpTopic('vehicle')">What if I need a different vehicle?</a></p>
          <p><a tabindex="0" class="-oneX-link--inline help-topics-panel clickable" (keydown.enter)="scrollToHelpTopic('company')" (click)="scrollToHelpTopic('company')">Can I use a different rental company?</a></p>
          <p><a tabindex="0" class="-oneX-link--inline help-topics-panel clickable" (keydown.enter)="scrollToHelpTopic('totaled')" (click)="scrollToHelpTopic('totaled')">What happens if my vehicle is totaled?</a></p>
        </div>
      </div>
      <hr class="-oneX-hr"/>
    </div>
    <div role="dialog" aria-labelledby="dialogLnkHdr">
      <div id="dialogLnkHdr" class="-oneX-heading--h3 claimant-drawer-headings">
        <div id="cover">What does State Farm cover?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body">
        We cover your rental vehicle with Hertz and will pay them directly. Your rental coverage begins when your car is left at the repair shop– or immediately when your vehicle is not drivable.
      </div>
      <div id="dialogLnkHdr" class="-oneX-heading--h3 claimant-drawer-headings">
        <div id="costs">Who pays additional costs?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body">
        Hertz may require a security deposit from you. Any additional fees and costs, which may include gas, security deposit, fuel deposit, tolls, collision damage waiver (CDW), and insurance, will be at your expense.
      </div>
      <div id="dialogLnkHdr"  class="-oneX-heading--h3 claimant-drawer-headings">
        <div id="vehicle">What if I need a different vehicle?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body">
        To request a different vehicle, contact your claim handler or use the online chat feature.
      </div>
      <div id="dialogLnkHdr selected-shop" class="-oneX-heading--h3 claimant-drawer-headings">
        <div id="company">Can I use a different rental company?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body">
        To request a rental provider other than Hertz, contact your claim handler or use the online chat feature.
      </div>
      <div id="dialogLnkHdr"  class="-oneX-heading--h3 claimant-drawer-headings">
        <div id="totaled">What happens if my vehicle is totaled?</div>
      </div>
      <div class="-oneX-body--primary claimant-help-drawer-body-last">
        If the vehicle is determined to be a total loss, your rental car may be authorized up to 5 additional days from the date the settlement valuation is reviewed with you. The 5 added days are intended to help you during the next steps of the claims process while searching for a replacement vehicle.
      </div>
      <button class="-oneX-close-X-button clickable" aria-label="Close Drawer">
        <span class="-oneX-icon--close"></span>
      </button>
    </div>
  </section>

</div>


