export class MsoConstant {
    public static mso_ids = [
        '00000003',
        '00000005',
        '00000040',
        '00000041',
        '00000056',
        '00000100',
        '00000699',
        '00000850',
    ];
}
