import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ShopSelectorManagementService implements OnDestroy {

  private isPanMoved: BehaviorSubject<boolean>;
  private ngUnsubscribe = new Subject();

  constructor() {
    this.isPanMoved = new BehaviorSubject<boolean>(false);
  }

  setIsPanMoved(val: boolean) {
    this.isPanMoved.next(val);
  }

  getIsPanMoved(): Observable<boolean> {
    return this.isPanMoved;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

  }
}
