import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { DataAnalyticsService } from 'app/service/data-analytics.service';
import { HelpService } from 'app/service/help.service';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

@Component({
  selector: 'app-help-subcategory',
  templateUrl: './help-subcategory.component.html',
  styleUrls: ['./help-subcategory.component.css']
})
export class HelpSubcategoryComponent implements OnInit, OnDestroy {
  category: Params;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subCategory: any = {};
  subsink = new SubSink();
  observer = interval(1000);
  helpData;

  constructor(public route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private dataAnalyticsService: DataAnalyticsService,
              private helpService: HelpService) {}

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  ngOnInit() {
    this.subsink.add(
      this.route.params.subscribe(() => {
        this.helpData = this.helpService.getHelpDataFromSession();

        if (this.helpData) {
          this.route.params.subscribe((res: Params) => {
            this.category = res;
            this.subCategory = this.helpData.categories.filter((x) => x.name === this.category.name)[0];
          })
        }

        const routeArr = this.router.url.split('?claim');
        this.location.replaceState('/landing?claim'+routeArr[1]);
        this.setAnalytics(this.category.name);
      })
    );
  }

  displayHelpCategories(category) {
    if (category && !category.name) {
      return '';
    }
    //  make milestone sentence - Keep first letter capiitalized, lowercase rest of words, replace ALL (global) underscores with a space
    return `${category.name.charAt(0).toUpperCase()}${category.name.slice(1).toLowerCase().replace(/_/g, ' ').replace(' i ', ' I ')}`;
  }

  displayHelpCategoriesInBreadcrumbs(category) {
    if (category && !category.name) {
      return '';
    }
    return category.name.replace(/_/g, ' ');
  }

  setAnalytics(categoryName) {
    const newName = `${categoryName.toLowerCase().replace(/_/g, '-')}`;

    this.dataAnalyticsService.sendPageData(`${newName}-help`);
  }

  setAnalyticsIdScreenId(analyticsId) {
    this.dataAnalyticsService.sendPageData(`${analyticsId}-help`);
  }

}
