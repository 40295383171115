import { Injectable } from '@angular/core';
import { WindowReference } from 'app/model/window-reference.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

// SERVICES
import { InteractionsService } from './interactions.service';
import { RepairExperienceService } from './repair-experience.service';
import { SFLoggerService } from './sf-logger.service';
import { UrlResolverService } from './url-resolver.service';

// MODELS
import { UrlParams } from 'app/model/url-params.model';
import { UserDetailsResponse } from 'app/model/user-details-response.model';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

@Injectable()
export class AuthService {

  // a state
  private userLoggedIn$: BehaviorSubject<boolean>;

  constructor(
    private windowRef: WindowReference,
    private http: HttpClient,
    private repairExperienceService: RepairExperienceService,
    private urlResolverService: UrlResolverService,
    private urlParams: UrlParams,
    private interactionsService: InteractionsService,
    private sfLoggerService: SFLoggerService
  ) {
    this.userLoggedIn$ = new BehaviorSubject(false);
  }

  public setUserLoggedIn(status: boolean) {
    this.urlParams.authentication = status ? 'full' : 'partial';
    this.userLoggedIn$.next(status);
    this.urlResolverService.buildHubRedirectUrl();
  }

  public isUserLoggedIn(): BehaviorSubject<boolean> {
    return this.userLoggedIn$;
  }

  public authenticateUser(
    externalClaimId: string,
    externalClientId: string
  ): Observable<HttpResponse<UserDetailsResponse>> {
    return this.repairExperienceService.getUserDetails(externalClaimId, externalClientId).pipe(
      map(response => {
        if(response.body['Identity'].loa){
          this.interactionsService.setLOA(response.body['Identity'].loa.toString());
        }

        // SPARKL Logging
        const loggingDetails = [
          SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
          SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_USER,
        ]
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - RE API - POST /user", loggingDetails)

        return response;
      }),
      catchError(error => {
        // SPARKL Logging
        const loggingDetails = [
          SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
          SFLoggerFields.ENDPOINT, error.url,
          SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
          SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_USER,
          SFLoggerFields.HTTP_RESPONSE, error.status.toString()
        ];
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - RE API - POST /user", loggingDetails);

        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      })
    );
  }
}
