export class Address {
 line: string;
 line2: string;
 city: string;
 state: string;
 postalCode: string;
 latitude: string;
 longitude: string;
 distance: number;

constructor( line: string, city: string, state: string, postalCode: string) {
    this.line = line;
    this.city = city;
    this.state = state;
    this.postalCode = postalCode;
  }

}
