import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Maintenance } from '../model/maintenance.model';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MaintenanceResolverService implements OnDestroy {
  // default maintenance to return if there is an error with the http get call
  private maintenance: Maintenance = {
    maintenanceOutage_prior: '',
    maintenanceOutage_prior_flag: false,
    maintenanceOutage_during: '',
    maintenanceOutage_during_flag: false,
  };

  private flags = new BehaviorSubject(this.maintenance);
  private ngUnsubscribe = new Subject();
  constructor(private http: HttpClient) {
    const url = '/assets/maintenance/maintenance.json';
    this.http
      .get<Maintenance>(url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        l => this.flags.next(l),
        err =>
          console.log(
            'error',
            `Failed to resolve maintenance file: ${err.toString()}`
          )
      );
  }
  /**
   * Makes call to retrieve Maintenance Mode content and returns the Observable.
   * If there is an error with the http get call, the default Maintenance content defined above
   * are returned instead.
   */
  public getMaintenance(): Observable<Maintenance> {
    return this.flags.pipe(takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
