import { Injectable } from '@angular/core';
import { CoverageInfo } from 'app/model/coverage-info.model';
import { StorageService } from '../service/storage.service';

@Injectable()
export class CoverageInfoService {
  coverageInfo: CoverageInfo;

  constructor (
    private storageService: StorageService,
  ) { }

  setCoverageInfo(info: CoverageInfo) {
    this.coverageInfo = info;
    this.storageService.setSessionStorage('coverageInfo', JSON.stringify(this.coverageInfo));
  }

  getCoverageInfo() {
    if (this.coverageInfo === undefined) {
      this.coverageInfo = JSON.parse(this.storageService.getSessionStorage('coverageInfo'));
    }
    return this.coverageInfo;
  }

  getCoverageType() {
    this.getCoverageInfo();
    return this.coverageInfo.coverageValues['Type'];
  }

  getCoverageLimit() {
    this.getCoverageInfo();
    return this.coverageInfo.coverageValues['Limit'];
  }

  getCoverageDailyLimit() {
    this.getCoverageInfo();
    const dailyLimit = this.coverageInfo.coverageValues['DailyLimit'];
    return dailyLimit;
  }

  getCoveragePercent() {
    this.getCoverageInfo();
    let coveragePercent = null;
    if (this.coverageInfo.coverageValues['Type'] === 'P') {
      coveragePercent = this.getCoverageDailyLimit(); // This is not valid for VA and will be overriden
    }
    return coveragePercent;
  }

}
