import { Injectable } from '@angular/core';

@Injectable()
export class ClaimInfo {
  claimNumber: string;
  vehicleNum: string;
  vehicleInfo: string;
  externalClaimId: string;
  externalClientId: string;
  roleType: string;
  authentication: string;
}
