import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataAnalyticsService } from 'app/service/data-analytics.service';
import { HelpService } from 'app/service/help.service';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { UrlParams } from 'app/model/url-params.model';

@Component({
  selector: 'app-help-category',
  templateUrl: './help-category.component.html',
  styleUrls: ['./help-category.component.css']
})
export class HelpCategoryComponent implements OnInit, OnDestroy {
  helpData;
  statusArray;
  categoryArray;
  category = {};
  subsink = new SubSink();
  observer = interval(1000);
  urlParams: UrlParams;

  constructor(private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private router: Router,
              private location: Location,
              private dataAnalyticsService: DataAnalyticsService,
              private helpService: HelpService) {}

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  ngOnInit() {
    this.subsink.add(
      this.route.params.subscribe(() => {
        this.helpData = this.helpService.getHelpDataFromSession();

        if (this.helpData) {
          this.statusArray = this.helpData.status;
          this.categoryArray = this.helpData.categories;
          this.changeDetector.detectChanges();
        }

        const routeArr = this.router.url.replace('(help:category)',"");
        this.location.replaceState(routeArr);
        this.setAnalytics();
      })
    );
  }

  setAnalytics() {
    this.dataAnalyticsService.sendPageData('claims-help');
  }

  displayStatusQuestions(doc) {
    return doc.question;
  }

  displayHelpCategories(category) {
    //  make milestone sentence - Keep first letter capiitalized, lowercase rest of words, replace ALL (global) underscores with a space
    return `${category.name.charAt(0).toUpperCase()}${category.name.slice(1).toLowerCase().replace(/_/g, ' ')}`;
  }

  setAnalyticsIdScreenId(analyticsId) {
    this.dataAnalyticsService.sendPageData(`${analyticsId}-help`);
  }
}
