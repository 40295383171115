import { trigger, transition, animate, style, state } from '@angular/animations';

export const vehiclesTrigger =
trigger('vehiclesTrigger', [
  state('open', style({
    opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('.2s',
    style ({
      transform: 'scale(.5) translateY(-150px)'
    })
  )]),
]);

export const vendorTrigger =
trigger('vendorTrigger', [
  state('open', style({
    opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('.2s',
    style ({
      transform: 'scale(.5) translateY(-100px)'
    })
  )]),
]);

export const phoneTrigger =
trigger('phoneTrigger', [
  state('open', style({
    opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('.2s',
    style ({
      transform: 'scale(.5) translateY(-100px)'
    })
  )]),
]);
