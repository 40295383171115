import { Injectable } from '@angular/core';
import { WindowReference } from '../model/window-reference.model';

@Injectable()
export class AuthSessionService {

  constructor(
    private windowRef: WindowReference,
  ) { }

  public keepAliveHighToken() {
    let keepAliveHandle;

    function callKeepAliveURL(windowRef: WindowReference) {
      const id = 'keepAliveImage';
      const prev = document.getElementById(id);
      if (prev) {
        prev.remove();
      }
      const KEEPALIVE_SERVICE_URL = windowRef.nativeWindow._config['keepAlive'].url;
      const imgNode = document.createElement('img');
      imgNode.id = id;
      imgNode.src = KEEPALIVE_SERVICE_URL;
      document.body.appendChild(imgNode);
    }

    function startKeepAlive(windowRef: WindowReference) {
      keepAliveHandle = callKeepAliveURL(windowRef);
    }

    startKeepAlive(this.windowRef);
  }
}
