import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help-drawer',
  templateUrl: './help-drawer.component.html',
  styleUrls: ['./help-drawer.component.css']
})
export class HelpDrawerComponent implements OnInit, AfterViewChecked {
  @Input() drawerid: string;
  @Input() isClaimsHelp = false;
  @Output() drawerClose = new EventEmitter();

  constructor() { }

  ngOnInit() {
    window['oneX'].Drawer.init();
  }

  ngAfterViewChecked() {
    window['oneX'].Drawer.init();
  }

  closeevent() {
    this.drawerClose.emit();
  }
}
