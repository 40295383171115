import { AfterViewInit, Component, EventEmitter, OnInit, Output, Inject, SecurityContext } from '@angular/core';
import { UrlResolverService } from '../service/url-resolver.service';
import { Location } from '@angular/common';
import { WINDOW } from 'app/service/window.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HelpService } from 'app/service/help.service';
import { HelpResponse } from 'app/model/help.model';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-navigation-breadcrumb',
  templateUrl: './navigation-breadcrumb.component.html',
  styleUrls: ['./navigation-breadcrumb.component.css']
})
export class NavigationBreadcrumbComponent implements OnInit, AfterViewInit {
  @Output() goBackCallback = new EventEmitter();

  repairServicesUrl: SafeUrl;
  claimsHub: SafeUrl;
  repairServices = 'https://www.statefarm.com/claims/claims-help/auto/repair-services';
  claimId: string;
  clientId: string;
  role: string;
  isValidRoute: boolean;
  isShopSelection: boolean;
  isRental: boolean;
  isPhotoEstimate: boolean;
  oneX: any;
  backed = false;
  claimsHubUrl: string;
  sanitisedRepairServicesUrl: string;
  path: string;
  sanitizedPath: string;

  // Help Topics
  displayClaimsHelp: boolean;
  helpData: HelpResponse;
  helpRespObs: Observable<HelpResponse>;

  constructor(
    @Inject(WINDOW) private window: Window,
    private helpService: HelpService,
    private urlResolverService: UrlResolverService,
    public location: Location,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {
    this.displayClaimsHelp = false;
    this.isValidRoute = false;
    this.oneX = window['oneX'];
  }

  ngOnInit() {
    this.claimsHubUrl = this.sanitizer.sanitize(SecurityContext.URL, this.urlResolverService.buildHubRedirectUrl());
    this.claimsHub = this.sanitizer.bypassSecurityTrustUrl(this.claimsHubUrl);
    this.sanitisedRepairServicesUrl = this.sanitizer.sanitize(SecurityContext.URL, this.repairServices);
    this.repairServicesUrl = this.sanitizer.bypassSecurityTrustUrl(this.sanitisedRepairServicesUrl);
    this.claimId = this.urlResolverService.getClaimId();
    this.clientId = this.urlResolverService.getClientId();
    this.role = this.urlResolverService.getRole();
    this.path = this.getPathname();
    this.sanitizedPath = this.sanitizer.sanitize(SecurityContext.URL, this.path);
    this.isShopSelection = this.sanitizedPath.includes('repairshops') ? true : false;
    this.isRental = this.sanitizedPath.includes('rental') ? true : false;
    this.isPhotoEstimate = this.sanitizedPath.includes('photoestimate') ? true : false;

    if (this.isShopSelection || this.isRental || this.isPhotoEstimate) {
      this.isValidRoute = true;
    }

    // Check if there is already help data in session storage
    this.helpData = this.helpService.getHelpDataFromSession();

    // No help data exists, make new call to Help API if PE route
    if (this.helpData == null) {
      if (this.isPhotoEstimate) {
        this.getHelpData();
      }
    } else {
      this.displayClaimsHelp = true;
    }

  }

  ngAfterViewInit() {
    this.oneX.Icons.init();
  }

  getPathname(): string {
    return window.location.pathname;
  }

  hasClaimAndClient() {
    return (this.clientId !== undefined && this.claimId !== undefined) ? true : false;
  }

  goBack(): void {
    if (this.goBackCallback.observers.length > 0) {
      this.goBackCallback.emit();
    } else {
      if (!this.backed) {
        this.backed = true;
        this.router.navigate(['shopsearch'], {queryParams: this.urlResolverService.getQueryParamsForUrl()});
      }
    }
  }

  getHelpData() {
    this.helpRespObs = this.helpService.getHelpData(this.claimId, this.role.toUpperCase(), 'AUTO', this.clientId);
    this.helpRespObs.subscribe((response) => {
      // Only show Claims Help if data successfully loaded
      if(response.categories != undefined) {
        this.displayClaimsHelp = true;
      }
    });
  }
}
