
export class Interactions {
  enterprise_interaction_identifier: string;
  producer_name: string;
  producer_interaction_timestamp: string;
  producer_interaction_timezone: string;
  enterprise_interaction_event_text: string;
  enterprise_interaction_intent_name?: string;
  state_farm_user_identifier?: string;
  CLM_ID?: string;
  CLNT_ID?: string;
  claims_role_name?: string;
  level_of_assurance_number?: string;
  participant_identifier?: string;
  vehicle_number?: string;
  vehicle_make_name?: string;
  vehicle_model_name?: string;
  vehicle_year_number?: string;
  claim_state_number?: string;
  repair_shop_identifier?: string;
  rental_location_identifier?: string;
  rental_coverage_indicator?: string;
  referral_page_url_text?: string;
  referral_page_name?: string;
  device_type_name?: string;

  constructor() {
    this.enterprise_interaction_identifier = '';
    this.producer_name = 'Claims_RepairAssistant';
    this.producer_interaction_timestamp = '';
    this.producer_interaction_timestamp = '';
    this.enterprise_interaction_event_text = '';
    this.enterprise_interaction_intent_name = '';
    this.state_farm_user_identifier = '';
    this.CLM_ID = '';
    this.CLNT_ID = '';
    this.claims_role_name = '';
    this.level_of_assurance_number = '';
    this.participant_identifier = '';
    this.vehicle_number = '';
    this.vehicle_make_name = '';
    this.vehicle_model_name = '';
    this.vehicle_year_number = '';
    this.claim_state_number = '';
    this.repair_shop_identifier = '';
    this.rental_location_identifier = '';
    this.rental_coverage_indicator = '';
    this.referral_page_url_text = '';
    this.referral_page_name = '';
    this.device_type_name = '';
  }
}
