<!-- Edit phone number -->
<div *ngIf="isEditingPhone">
    <app-photo-estimate-edit-contact
      [contactType]="'phone'"
      [initialContactValue]="(phone$ | async)"
      (saveContactCallback)="savePhoneContact($event)"
      (cancelEditCallback)="cancelEditContact()">
    </app-photo-estimate-edit-contact>
</div>

<!-- Edit email address -->
<div *ngIf="isEditingEmail">
    <app-photo-estimate-edit-contact
      [contactType]="'email'"
      [initialContactValue]="(email$ | async)"
      (saveContactCallback)="saveEmailContact($event)"
      (cancelEditCallback)="cancelEditContact()">
    </app-photo-estimate-edit-contact>
</div>

<!-- Main content -->
<!-- Must use [ngClass] instead of *ngIf to retain checkbox values after editing phone or email -->
<div [ngClass]="{ 'editing-screen': isEditingPhone || isEditingEmail }"
     class="pe-contact-page -oneX-container">
    <div class="-oneX-row">
        <div class="-oneX-col-xs-12 -oneX-col-md-12 -oneX-col-lg-12 -oneX-col-xl-12">
            <!-- Cancel Button -->
            <div class="cancel-btn">
                <a class="-oneX-link--inline--variant -oneX-link-secondary"
                   (click)="goToPhotoEstimateLanding()"
                   (keydown.enter)="goToPhotoEstimateLanding()"
                   tabindex="0">Cancel</a>
            </div>
        </div>
    </div>

    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">
            <!-- Photo Estimate Contact Title & Intro -->
            <div class="pe-contact-header-container">
                <div class="pe-contact-header -oneX-heading--h3 -oneX-font--semi">How do you want to get the link?</div>
                <div class="-oneX-body--intro-md">We’ll send a text or email with a link to the Photo Estimate tool.</div>
            </div>

            <!-- Contact Preference Checkboxes -->
            <div class="contact-checkbox-container">
                <fieldset class="checkbox-fieldset -oneX-fieldset">
                    <div class="-oneX-checkbox-group -oneX--stacked">
                        <!-- Phone Checkbox -->
                        <div class="pe-checkbox-item -oneX-checkbox-item" id="pe-text-me-checkbox">
                            <input id="text-me" 
                                type="checkbox" 
                                value="Text me" 
                                class="-oneX-checkbox"
                                (click)="togglePhone()"/>
                            <label for="text-me" id="text-me-label">Text me</label>
                            <!-- Phone Details -->
                            <div [@contactTrigger]="phoneView" class="phone-email-container">
                                <div class="phone-email -oneX-font--med">{{(phone$ | async)}}</div>
                                <a *ngIf="!isUserLoggedIn" 
                                class="-oneX-link--block -oneX-body--secondary"
                                id="log-in-to-edit"
                                (click)="goToProofing()"
                                (keydown.enter)="goToProofing()"
                                tabindex="0">Log in to edit</a>
                                <a *ngIf="isUserLoggedIn"
                                class="-oneX-link--block -oneX-body--secondary"
                                id = "edit-mobile-number"
                                (click)="editContactInfo('phone')"
                                (keydown.enter)="editContactInfo('phone')"
                                tabindex="0">Edit mobile number</a> 
                            </div>
                        </div>
                        <div class="checkbox-divider"></div>
                        <!-- Email Checkbox -->
                        <div class="pe-checkbox-item -oneX-checkbox-item" id="pe-email-me-checkbox">
                            <input id="email-me" 
                                type="checkbox" 
                                value="Email me" 
                                class="-oneX-checkbox"
                                (click)="toggleEmail()"/>
                            <label for="email-me" id="email-me-label">Email me</label>
                            <!-- Email Details -->
                            <div [@contactTrigger]="emailView" class="phone-email-container">
                                <div class="phone-email -oneX-font--med">{{(email$ | async)}}</div>
                                <a *ngIf="!isUserLoggedIn"
                                class="-oneX-link--block -oneX-body--secondary"
                                id="log-in-to-edit"
                                (click)="goToProofing()"
                                (keydown.enter)="goToProofing()"
                                tabindex="0">Log in to edit</a>
                                <a *ngIf="isUserLoggedIn"
                                class="-oneX-link--block -oneX-body--secondary"
                                id="edit-email-address"
                                (click)="editContactInfo('email')"
                                (keydown.enter)="editContactInfo('email')"
                                tabindex="0">Edit email address</a> 
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div [@errorTrigger]="errorView" class="error-message">
                    <div class="-oneX-widget__err-text">Select at least one contact method to continue.</div>
                </div>
            </div>

            <!-- Send Me The Link Button -->
            <div class="btn-container">
                <button id="send-pe-link-btn"
                        class="link-btn -oneX-btn-primary"
                        (click)="sendPhotoEstimateAssignment()">Send me the link</button>
            </div>

        </div>
    </div>

    <!-- Retry Modal -->
    <div id="retry-modal" class="-oneX-modal__container center" style="display:none" >
        <div class="-oneX-modal-content" role="dialog">
    
            <!-- Error Icon -->
            <div id="error-icon" class="-oneX-icon-container">
                <div class="-oneX-icon" data-icon="warning_fill_32" style="height:48px;width:48px"> </div>
            </div>

            <!-- Title -->
            <h2  class="retry-title -oneX-modal-heading" tabindex="-1">Request failed to send</h2>

            <!-- Body -->
            <div class="-oneX-body--intro-md">You can try resending your request or try again later.</div>

            <!-- Retry Btn -->
            <div class="-oneX-modal-footer">
                <div class="-oneX-row">
                <div class="-oneX-modal-primary__div -oneX-col" id="pe-contact-retry">
                    <button (click)="sendPhotoEstimateAssignment()" class="-oneX-btn-primary">Retry</button>
                </div>
                </div>
            </div>

            <!-- Close Btn -->
            <button onclick="oneX.Modal.hideModal()" class="-oneX-close-X-button">
                <span class="-oneX-icon--close"></span>
            </button>
        </div>
    </div>
</div>