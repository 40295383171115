export enum SFLoggerFields {
    // LOGLEVELS
    LOG_LEVEL = "logLevel",
    INFO = "info",
    WARN = "warn",
    ERROR = "error",

    // INIT DETAILS
    LANDING_PAGE = 'Landing Page',
    LANDING_PAGE_2 = 'Landing Page 2.0',
    SEARCH_RESULTS_PAGE = 'Search Results Page',

    USER_FLOW = 'userFlow',
    TRANSACTIONAL_FLOW = 'Transactional',
    SEARCH_ONLY_FLOW = 'Search Only',

    // REPORTING FILE 
    DEV_REPORTING_FILE = 'devReportingFile',

    // DOWNSTREAM CALLS
    HTTP_REQUEST = 'httpRequest',
    HTTP_RESPONSE = 'httpResponse',
    ERROR_MESSAGE = 'errorMessage',
    CALLED_SERVICE = 'calledService',
    ENDPOINT = 'endpoint',

    // DOWNSTREAM APIS
    REPAIR_EXPERIENCE_API = 'Repair Experience API',
    REP_EXP_USER = 'POST /v2/user',
    REP_EXP_REPAIR = 'POST /v3/repair',
    REP_EXP_REPAIR_ASSIGNMENT = 'POST /repairassignment', 
    REP_EXP_GET_RENTAL_ASSIGNMENT = 'GET /rentalassignment',
    REP_EXP_POST_RENTAL_ASSIGNMENT = 'POST /rentalassignment',
    REP_EXP_ESTIMATE_ASSIST = 'POST /estimateassist',
    REP_EXP_REPAIR_FACILITY_DETAILS = 'POST /repairfacilitydetails',

    PHOTO_ESTIMATE_API = 'Photo Estimate API',
    PHOTO_ESTIMATE_ASSIGNMENT = "POST /assignment",
    PHOTO_ESTIMATE_ELIGIBILITY = "GET /assignment/eligibility",

    PROVIDERS_API = 'Providers API',
    PROVIDERS_GET_PROVIDERS = 'GET /providers',

    AUTH_API = 'Auth API',
    AUTH_TOKEN = 'POST /token',
    AUTH_VERIFY = 'GET /verifyJwt',

    HELP_API = 'Help API',
    HELP_STATUS = 'GET /statusAndCat',

    // ASSIGNMENT
    ASSIGNMENT_TYPE = 'assignmentType',

    // SEARCH 
    SEARCH_ONLY = 'searchOnly',
    SEARCH_TYPE = 'searchType',
    VEHICLE_FILTER = 'vehicleFilter',

    // CUSTOMER DETAILS
    AUTHENTICATION = 'authentication',
    ROLE = 'role',
    CLAIM_NUMBER = 'claimNumber',
    CLAIM_STATE_CODE = 'claimStateCode',
    PARTICIPANT_DEFAULT_STATE = 'participantDefaultState',
    PARTICIPANT_ID = 'participantId',
    VEHICLE_NUM = 'vehicleNumber',
    REPAIR_ELIGIBLE = 'repairEligible',
    RENTAL_ELIGIBLE = 'rentalEligible',
    ESTIMATE_ELIGIBLE = 'estimateEligible',
    CAT_ELIGIBLE = 'catastropheEligible',
    ASSIGNMENT_COUNT = 'assignmentCount',
    CLOSED_CLAIM = 'closedClaim',

    // DEVICE 
    DEVICE_PLATFORM = 'devicePlatform',
    BROWSER = 'browser',
    BROWSER_VERSION = 'browserVersion',
    OS = 'os',
    OS_VERSION = 'osVersion',

    // RENTAL
    RENTAL_VENDOR = 'rentalVendor',
    RENTAL_DELIVERY_PREF = 'rentalDeliveryPref',
    RENTAL_VEHICLE_CLASS_CODE = 'rentalVehicleClassCode',

    // PHOTO ESTIMATE
    PE_REPLACE_EXISTING_ASSN = 'peReplaceExistingAssn',
    PE_CONTACT_PREF_EMAIL = 'peContactPrefEmail',
    PE_CONTACT_PREF_PHONE = 'peContactPrefPhone',

    // ROUTING
    ROUTING_TO = 'routingTo'
}