import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Certifications } from '../constant/make-certifications-constants';

// SERVICES
import { UrlResolverService } from './url-resolver.service';
import { SSOTokenService } from './sso.token.service';
import { JWTTokenService } from './jwt.token.service';
import { StorageService } from './storage.service';
import { SFLoggerService } from 'app/service/sf-logger.service';

// MODELS
import { RepairAssignment } from '../model/repair-assignment.model';
import { UserDetailsResponse } from 'app/model/user-details-response.model';
import { RepairResponse } from 'app/model/repair-response.model';
import { EstimateAssistResponse } from 'app/model/estimate-assist-response.model';
import { ProviderRequest } from 'app/model/provider-request.model';
import { SearchByCityOrZip } from 'app/model/search-by-city-or-zip.model';
import { SearchLocator } from 'app/model/search-locator.model';
import { SearchByName } from 'app/model/search-by-name.model';
import { CommonResponse } from 'app/model/common-response.model';
import { RentalAssignment } from 'app/model/rental-assignment.model';

// ENUMS
import { SFLoggerFields } from 'app/enums/sf-logger-fields';
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';

@Injectable({
  providedIn: 'root',
})
export class RepairExperienceService {
  private repairExperienceUrl: string;
  private rentalCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  headers: HttpHeaders;

  private certifications = Certifications.CERTIFICATIONS;
  private msoId = Certifications.MSO_ID;

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private ssoTokenService: SSOTokenService,
    private jwtTokenService: JWTTokenService,
    private storageService: StorageService,
    private sfLoggerService: SFLoggerService,
  ) {
    this.repairExperienceUrl = this.urlResolverService.getServiceUrl('repairExperience');
  }

  sendRepairAssignments(
    isEstimateAssist: boolean,
    extClaimId: string,
    extClientId: string,
    participantId: string,
    repairAssignmentModel: RepairAssignment
  ): Observable<string> {
    const url = `${this.repairExperienceUrl}/repairassignment`;
    
    const assignmentType = isEstimateAssist ? "estimate assist" : "select service";

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR_ASSIGNMENT,
      SFLoggerFields.ASSIGNMENT_TYPE, assignmentType,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /repairassignment", loggingDetails);

    const headers = this.setHttpHeaders();
    
    const body = {
      channelTypeCode: repairAssignmentModel.channelTypeCode,
      estimatingId: repairAssignmentModel.estimatingId,
      vehicleNumber: repairAssignmentModel.vehicleNumber,
      closedClaim: repairAssignmentModel.closedClaim,
      claimId: extClaimId,
      participantId: participantId,
      clientId: extClientId,
    };
    if (!!repairAssignmentModel.participantType) {
      body['participantType'] = repairAssignmentModel.participantType;
    }

    return new Observable(observer => {
      this.http
        .post<any>(url, body, { headers: headers })
        .subscribe(
          _ => {
            observer.next('1');
          },
          error => {
            if (error.status === 401) {
              error.statusText = 'Authorization Error';
            } else if (error.status === 500) {
              error.statusText = 'Error Calling Repair Assignment';
            } else if (error.status === 404) {
              error.statusText = 'Repair Assignment Service Not Found';
            } else {
              error.statusText = 'Unknown Error calling Repair Assignment';
            }
            observer.error(error);
          }
        );
    });
  }

  sendRentalAssignments(
    rentalAssignmentRequest: RentalAssignment
  ): Observable<string> {
    return new Observable(observer => {
      const url = `${this.repairExperienceUrl}/rentalassignment`;

      // SPARKL Logging
      let loggingDetails = [
        SFLoggerFields.ENDPOINT, url,
        SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
        SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_POST_RENTAL_ASSIGNMENT,
        SFLoggerFields.RENTAL_VENDOR, rentalAssignmentRequest.vendorType,
        SFLoggerFields.RENTAL_VEHICLE_CLASS_CODE, rentalAssignmentRequest.vehicleClassCode,
        SFLoggerFields.RENTAL_DELIVERY_PREF, rentalAssignmentRequest.rentalDeliveryPreference
      ];
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /rentalassignment", loggingDetails);

      const headers = this.setHttpHeaders();
  
      let body;
      body = {
        claimId: rentalAssignmentRequest.claimId,
        clientId: rentalAssignmentRequest.clientId,
        participantId: rentalAssignmentRequest.participantId,
        channelType: rentalAssignmentRequest.channelType,
        totalDays: rentalAssignmentRequest.totalDays,
        dailyMaxCoverage: rentalAssignmentRequest.dailyMaxCoverage,
        rentalCoveragePercent: rentalAssignmentRequest.rentalCoveragePercent,
        maxCoverage: rentalAssignmentRequest.maxCoverage,
        deliveryShopName: rentalAssignmentRequest.deliveryShopName,
        deliveryShopPhone: rentalAssignmentRequest.deliveryShopPhone,
        vehicleClassCode: rentalAssignmentRequest.vehicleClassCode,
        phoneNumber: rentalAssignmentRequest.phoneNumber,
        address: rentalAssignmentRequest.address,
        rentalDeliveryPreference: rentalAssignmentRequest.rentalDeliveryPreference,
        rentalVendor: rentalAssignmentRequest.vendorType.toUpperCase(),
      };

      this.http.post<CommonResponse<RentalAssignment>>(url, body, { headers: headers }).subscribe(
        (res) => {
          // SPARKL Logging
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - RE API - POST /rentalassignment", loggingDetails);
          observer.next('1');
          this.storageService.setSessionStorage(
            'rentalAssignmentRequestNumber',
            JSON.stringify(res.payload)
          );
        },
        (error) => {
          switch (error.status) {
            case 400:
              error.statusText = 'Rental assignments validation failed';
              break;
            case 401:
              error.statusText = 'Unauthorized access';
              break;
            case 404:
              error.statusText = 'Rental assignments resource not found';
              break;
            case 500:
              error.statusText = 'Rental assignments failed';
              break;
            default:
              error.statusText = 'Unknown Error calling rental assignment';
              break;
          }

          // SPARKL Logging
          loggingDetails.push(
            SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
            SFLoggerFields.ENDPOINT, error.url,
            SFLoggerFields.HTTP_RESPONSE, error.status.toString(),
            SFLoggerFields.ERROR_MESSAGE, error.statusText
          )
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - RE API - POST /rentalassignment", loggingDetails);

          observer.error(error);
        }
      );
    });
  }

  getUserDetails(
    externalClaimId: string,
    externalClientId: string
  ): Observable<HttpResponse<UserDetailsResponse>> {
    const url = `${this.repairExperienceUrl}/v2/user`;

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_USER,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /v2/user", loggingDetails);

    const headers = this.setHttpHeaders();
  
    const body = {
      claimId: externalClaimId,
      clientId: externalClientId,
    };

    return this.http.post<UserDetailsResponse>(url, body, {
      headers: headers,
      withCredentials: false,
      observe: 'response',
    });
  }

  getRepairDetails(
    externalClaimId: string,
    externalClientId: string,
    participantId?: string
  ): Observable<RepairResponse> {
    const url = `${this.repairExperienceUrl}/v3/repair`;

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /v3/repair", loggingDetails);

    const headers = this.setHttpHeaders();
    
    const body = {
      claimId: externalClaimId,
      clientId: externalClientId,
    };

    if (participantId) {
      body['participantId'] = participantId;
    }

    return this.http.post<RepairResponse>(url, body, {
      headers: headers,
    });
  }

  getRentalAssignment(
    externalClaimId: string,
    externalParticipantId: string,
    externalClientId: string
  ) {
    const url = `${this.repairExperienceUrl}/claims/${externalClaimId}/participants/${externalParticipantId}/clients/${externalClientId}/rentalassignment`;
    
    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_GET_RENTAL_ASSIGNMENT,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - GET /rentalassignment", loggingDetails);

    const headers = this.setHttpHeaders();
  
    this.http.get<RentalAssignment>(url, { headers: headers }).subscribe(
      _ => {
        // SPARKL Logging 
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - RE API - GET /rentalassignment", loggingDetails);
        this.rentalCompleted$.next(true);
      },
      error => {
        if (error.status == 404) {
          // 404 simply means no rental assignment exists - it's not really an 'error'
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "404 - SUCCESS - RE API - GET /rentalassignment", loggingDetails);
        } else {
          // SPARKL Logging
          loggingDetails.push(
            SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
            SFLoggerFields.ENDPOINT, error.url,
            SFLoggerFields.HTTP_RESPONSE, error.status.toString()
          )
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - RE API - GET /rentalassignment", loggingDetails);
        }
      }
    );
  }

  public isRentalCompleted(): BehaviorSubject<boolean> {
    return this.rentalCompleted$;
  }

  getEstimateAssistIndicator(
    externalClaimId: string,
    externalClientId: string,
    options: { zipcode?: string, city?: string, state?: string }
  ): Observable<EstimateAssistResponse> {
    const url = `${this.repairExperienceUrl}/estimateassist`;

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_ESTIMATE_ASSIST,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /estimateassist", loggingDetails);

    const headers = this.setHttpHeaders();
    
    const body = {
      claimId: externalClaimId,
      clientId: externalClientId,
    };

    if (options.zipcode) {
      body['zipcode'] = options.zipcode.replace(/^[ ]+/g, '');
    } else {
      if (!options.city || !options.state) {
        return of({
          estimateassist: false,
          ssotoken: this.ssoTokenService.getSSOToken(),
          jwttoken: this.jwtTokenService.getOktaJWT()
        });
      } else {
        body['city'] = options.city.replace(/^[ ]+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
        body['state'] = options.state;
      }
    }

    return this.http.post<EstimateAssistResponse>(url, body, {
      headers: headers,
    });
  }

  getEstimateAssistShopsFromThirdParties(
    externalClaimId: string,
    externalClientId: string,
    requestParams: ProviderRequest
  ): Observable<SearchByCityOrZip> {
    
    const url = `${this.repairExperienceUrl}/repairfacilitydetails`;

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR_FACILITY_DETAILS,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /repairfacilitydetails - Get Estimate Assist Shops", loggingDetails);

    const headers = this.setHttpHeaders();

    const body = {
      clientId: externalClientId,
      claimId: externalClaimId
    };
    if (requestParams.isZipCodeSearch && requestParams.postalCode) {
      body['zipcode'] = requestParams.postalCode.replace(/^[ ]+/g, '');
    } else if (requestParams.isCityStateSearch && requestParams.city && requestParams.state) {
      body['city'] = requestParams.city.replace(/^[ ]+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
      body['state'] = requestParams.state;
    } else {
      const tpr: SearchByCityOrZip = {
        statusCode: 400,
        errors: 'invalid input',
        message: [],
        data: [],
      };
      return of(tpr);
    }

    return this.http.post<SearchByCityOrZip>(url, body, { headers: headers });
  }

  getSearchLocatorFromThirdParties(
    externalClaimId: string,
    externalClientId: string,
    requestParams: ProviderRequest
  ): Observable<SearchLocator> {
    const url = `${this.repairExperienceUrl}/repairfacilitydetails`;

    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR_FACILITY_DETAILS,
    ];

    let headers = {};
    //  no token for search only
    if (externalClientId) {
      headers = this.setHttpHeaders();
    } else {
      headers = new HttpHeaders({
        'x-api-key': 'sf-repair-experience-api-consumer-repair-assistant',
        'correlation-id': this.sfLoggerService.getCorrelationId()
      })
    }

   const body = {
      clientId: externalClientId,
      claimId: externalClaimId,
      type: 'select_service_repair_facility'
    };

    if (requestParams.makeValue && requestParams.makeValue !== ''){
      loggingDetails.push(SFLoggerFields.VEHICLE_FILTER, requestParams.makeValue);
      if (requestParams.makeValue !== 'Other') {
        if(this.certifications.get(requestParams.makeValue)) {
          body['certification'] = this.certifications.get(requestParams.makeValue)
        } else {
          body['msoId'] = this.msoId.get(requestParams.makeValue)
        }
      }
    } else {
      loggingDetails.push(SFLoggerFields.VEHICLE_FILTER, 'None');
    }

    if (requestParams.radius === '75') {
      body['radius'] = '70';
      loggingDetails.push('radius', '70');
    } else {
      body['radius'] = requestParams.radius;
      loggingDetails.push('radius', requestParams.radius);
    }

    if (requestParams.isZipCodeSearch && requestParams.postalCode) {
      body['zipcode'] = requestParams.postalCode.replace(/^[ ]+/g, '');
      loggingDetails.push(SFLoggerFields.SEARCH_TYPE, 'zipcode');
    } else if (requestParams.isCityStateSearch && requestParams.city && requestParams.state) {
      body['city'] = requestParams.city.replace(/^[ ]+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
      body['state'] = requestParams.state;
      loggingDetails.push(SFLoggerFields.SEARCH_TYPE, 'city state');
    } else if (requestParams.isStreetAddressSearch && requestParams.city && requestParams.state && requestParams.streetAddress) {
      body['city'] = requestParams.city.replace(/^[ ]+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
      body['state'] = requestParams.state;
      body['street'] = requestParams.streetAddress.replace(/^[ ]+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
      loggingDetails.push(SFLoggerFields.SEARCH_TYPE, 'street address');
    } else if (requestParams.isLatLongSearch && requestParams.latitude && requestParams.longitude) {
      body['latitude'] = requestParams.latitude.toString();
      body['longitude'] = requestParams.longitude.toString();
      loggingDetails.push(SFLoggerFields.SEARCH_TYPE, 'lat long');
    } else {
      const tpr: SearchLocator = {
        statusCode: 400,
        errors: 'invalid input',
        message: [],
        data: [],
      };
      return of(tpr);
    }

    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /repairfacilitydetails - Search Locator", loggingDetails);

    return this.http.post<SearchLocator>(url, body, { headers: headers });
  }

  getSearchByNameFromThirdParties(
    externalClaimId: string,
    externalClientId: string,
    requestParams: ProviderRequest
  ): Observable<SearchByName> {
    const url = `${this.repairExperienceUrl}/repairfacilitydetails`;

    // SPARKL Logging
    const loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR_FACILITY_DETAILS,
      SFLoggerFields.SEARCH_TYPE, "name"
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling RE API - POST /repairfacilitydetails - Search By Name", loggingDetails);

    let headers = {}; 

    //  no token for search only
    if (externalClientId) {
      headers = this.setHttpHeaders();
    } else {
      headers = new HttpHeaders({
        'x-api-key': 'sf-repair-experience-api-consumer-repair-assistant',
        'correlation-id': this.sfLoggerService.getCorrelationId()
      })
    }

    const body = {
      clientId: externalClientId,
      claimId: externalClaimId,
      type: 'select_service_repair_facility'
    };

    if (requestParams.name && requestParams.filterBy) {
      body['name'] = requestParams.name.replace(/'/g, '').replace(/"/g, '').replace(/^[ ]+/g, '');
      body['zipcode'] = requestParams.filterBy;
    } else {
      const tpr: SearchByName = {
        statusCode: 400,
        errors: 'invalid input',
        message: [],
        data: [],
      };
      return of(tpr);
    }

    return this.http.post<SearchByName>(url, body, { headers: headers });
  }

  setHttpHeaders() {
    let ssoToken;
    let jwtToken;
    
    ssoToken = this.ssoTokenService.getSSOToken();
    jwtToken = this.jwtTokenService.getOktaJWT();

    this.headers = new HttpHeaders({
      'x-api-key': 'sf-repair-experience-api-consumer-repair-assistant',
      'correlation-id': this.sfLoggerService.getCorrelationId(),
      ...((ssoToken && ssoToken != '') && {'X-SF_SSO_TOKEN': ssoToken}),
      ...((jwtToken && jwtToken != '') && {Authorization: jwtToken}),
    });
    
    return this.headers;
  }
}
