<section class="-oneX-breadcrumbs help-breadcrumb-padding" *ngIf="subCategory">
  <div class="help-breadcrumb-margins">
    <nav aria-label="breadcrumb">
      <ul itemscope="" itemtype="http://schema.org/BreadcrumbList">
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" [routerLink]="['/', { outlets: { help: ['category'] } }]" queryParamsHandling="merge" aria-label="back to claims help">
            <span itemprop="name" class="help-large-breadcrumb-nowrap">
              Claims Help
            </span>
            <span class="-oneX-breadcrumbs-chevron"></span>
            <meta itemprop="position" content="0">
          </a>
        </li>
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item">
            <span itemprop="name" class="help-large-breadcrumb-nowrap">
              {{ displayHelpCategoriesInBreadcrumbs(subCategory) | titlecase }}
            </span>

            <meta itemprop="position" content="1">
          </a>
        </li>
      </ul>
    </nav>
  </div>
</section>

<div
  id="dialogLnkHdr"
  class="-oneX-heading--h2 help-subcategory-title"
  *ngIf="subCategory"
  role="heading"
>
  {{ displayHelpCategories(subCategory) }}
</div>

<div
  class="help-top-question-padding"
  *ngIf="
    subCategory &&
    subCategory.topQuestions &&
    subCategory.topQuestions.length > 0
  "
>
  <div class="-oneX-body--secondary">Top questions</div>
  <!-- Do not change the id below - it is being used by analytics to track clicks -->
  <button
    *ngFor="let question of subCategory.topQuestions | slice: 0:5"
    id="{{ question.analyticsId }}"
    class="help-status-question-links -oneX-link--inline -oneX-body--secondary"
    (click)="setAnalyticsIdScreenId(question.analyticsId)"
    [routerLink]="[
      '/',
      {
        outlets: {
          help: [
            'faq',
            question.majorGroup,
            question.subGroup,
            question.id,
            question.analyticsId
          ]
        }
      }
    ]"
    queryParamsHandling="merge"
    role="link"
  >
    {{ question.question }}
  </button>
</div>



<div class="-oneX">
  <div *ngFor="let subGroup of subCategory.subGroups; let index = index">
    <hr class="-oneX-hr"/>
    <div class="-oneX-panel--expansion clickable" >
      <div class="-oneX-panel-control" (click)="subGroup.isExpanded = !subGroup.isExpanded" >
        <button type="button" class="-oneX-panel-button clickable" [attr.aria-expanded]="subGroup.isExpanded">{{ displayHelpCategories(subGroup) }}</button>
        <span class="-oneX-panel__chevron-down" style="position: inherit"></span>
      </div>
      <div *ngIf="subGroup.isExpanded" id="{{subgroup}}" class="-oneX-panel-content help-subcategory-button-links-padding">
           <!-- Do not change the id below - it is being used by analytics to track clicks -->
           <button
           *ngFor="let question of subGroup.categoryHelpDocuments"
           class="help-status-question-links -oneX-link--inline -oneX-body--secondary help-buttons"
           id="{{ question.analyticsId }}"
           [routerLink]="['/', { outlets: { help: [ 'faq', question.majorGroup, question.subGroup, question.id, question.analyticsId ]}}]"
           queryParamsHandling="merge"
           role="link"
         >
           {{ question.question }}
         </button>
      </div>
    </div>
  </div>
</div>

<div class="help-borders">
  <hr class="-oneX-hr"/>
</div>

<ng-template [ngIf]="subCategory.name === 'DEDUCTIBLE_RECOVERY'">
  <div id="videos-container-id">
    <h5 class="-oneX-body--primary help-videosection-title" style="font-weight: 500" role="heading">
      Watch a video to learn more
    </h5>
    <div class="video-image-description-row-1">
      <button
        class="play-video-image"
        [routerLink]="[
          '/',
          { outlets: { help: ['faq-video', subCategory.name, 'subrogation'] } }
        ]"
        queryParamsHandling="merge"
        (click)="setAnalyticsIdScreenId('DED_REC_PROCESS_VIDEO')"
        aria-label="go to Subrogation/Deductible Refund Process video page"
      ></button>
      <a
        class="video-description -oneX-link--block -oneX-link-acquisition(16px)"
        [routerLink]="[
          '/',
          { outlets: { help: ['faq-video', subCategory.name, 'subrogation'] } }
        ]"
        queryParamsHandling="merge"
        (click)="setAnalyticsIdScreenId('DED_REC_PROCESS_VIDEO')"
        aria-label="go to Subrogation/Deductible Refund Process video page"
        >Subrogation/deductible refund process</a
      >
    </div>
    <div class="video-image-description-row-2">
      <button
        class="play-video-image"
        [routerLink]="[
          '/',
          { outlets: { help: ['faq-video', subCategory.name, 'arbitration'] } }
        ]"
        queryParamsHandling="merge"
        (click)="setAnalyticsIdScreenId('DED_REC_ARB-LIT_VIDEO')"
        aria-label="go to Arbitration and Litigation video page"
      ></button>
      <a
        class="video-description -oneX-link--block -oneX-link-acquisition(16px)"
        [routerLink]="[
          '/',
          { outlets: { help: ['faq-video', subCategory.name, 'arbitration'] } }
        ]"
        queryParamsHandling="merge"
        (click)="setAnalyticsIdScreenId('DED_REC_ARB-LIT_VIDEO')"
        aria-label="go to Arbitration and Litigation video page"
        >Arbitration and litigation</a
      >
    </div>
  </div>
  <div class="help-borders">
    <hr class="-oneX-hr"/>
  </div>
</ng-template>

<div class="help-drawer-padding still-have-questions-text">
  <app-help-still-have-questions></app-help-still-have-questions>
</div>
