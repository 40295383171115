import { Injectable } from '@angular/core';

@Injectable()
export class SearchRequest {
    name?: string;
    state?: string;
    city?: string;
    neighborhood?: string;
    streetNumber?: string;
    streetName?: string;
    streetAddress?: string;
    postalCode?: string;
    radius?: any;
    address: string;
    locationName: string;
    searchType: string;
    searchTerm?: string;
    latitude?: string;
    longitude?: string;
}
