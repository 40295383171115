<div class="pe-confirmation-page -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Success Icon -->
            <div id="success" class="icon-container -oneX-icon-container">
                <div class="-oneX-icon" data-icon="success_fill_32" style="height:48px;width:48px"> </div>
            </div>

            <!-- Header -->
            <h2 class="pe-confirmation-header -oneX-heading--h2">We’ll {{ contactPreference }} you the Photo Estimate link</h2>

            <!-- Description -->
            <div class="-oneX-body--intro-md">Once you receive the link you can start taking guided photos with your mobile device.</div>

            <!-- Send Me The Link Button -->
            <div class="btn-container">
                <button id="pe-confirm-claim-overview"
                        class="-oneX-btn-primary"
                        (click)="goToClaimOverview()">Go to Claim Overview</button>
            </div>
        </div>
    </div>
</div>