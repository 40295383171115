import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import { SearchResultsService } from 'app/service/search-results.service';
import { ServiceClientsService } from 'app/service/service-clients.service';
import { StorageService } from 'app/service/storage.service';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BookmarkRefreshGuard  {

    constructor(
        private router: Router,
        private storageService: StorageService,
        private searchResultsService: SearchResultsService,
        private clientService: ServiceClientsService,
        private urlResolverService: UrlResolverService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
        const searchedResultFromSessionStorage = this.storageService.getSessionStorage('searchResult');
        if (this.storageService.isRefreshed(searchedResultFromSessionStorage, this.searchResultsService)) {
            const pr = this.storageService.getSessionStorage('pr');
            if (pr && Object.keys(pr).length > 0) {
                this.searchResultsService.setProviderRequest(this.urlResolverService.getSearchTermFromParams(pr));
            }
            this.initiateClientService(route);
        } else if (this.storageService.isBookMarked(searchedResultFromSessionStorage, this.searchResultsService)) {
            this.router.navigate(['shopsearch'], { queryParams: route.queryParams});
        }
        return true;
    }

    private initiateClientService(route: ActivatedRouteSnapshot) {
        const externalClaimId = route.queryParamMap.get('claim');
        const externalClientId = route.queryParamMap.get('client');

        if (externalClaimId && externalClientId) {
            this.urlResolverService.populateParams(route.queryParams);
            this.clientService.initClientServices(externalClaimId, externalClientId);
        }
    }
}
