import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

// SERVICES
import { SFLoggerService } from './sf-logger.service';
import { SSOTokenService } from './sso.token.service';
import { JWTTokenService } from './jwt.token.service';

// MODELS
import { UrlResolverService } from './url-resolver.service';
import { HelpResponse } from 'app/model/help.model';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  public HELP_SERVICE_URL: string;
  headers: HttpHeaders;
  private helpDataKey = 'helpData';

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private sfLoggerService: SFLoggerService,
    private ssoTokenService: SSOTokenService,
    private jwtTokenService: JWTTokenService,
  ) {
    this.HELP_SERVICE_URL = this.urlResolverService.getServiceUrl('api_help');
  }

  public getHelpData(
    claimId: string,
    roleType: string,
    lob: string,
    extClientId: string,
  ): Observable<HelpResponse> {
    const helpUrl = `${this.HELP_SERVICE_URL}/claims/${claimId}?queryType=statusAndCategories&roleType=${roleType}&lob=${lob}&extClientId=${extClientId}&testEnvLane=env2`;

    // SPARKL Logging 
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, this.HELP_SERVICE_URL,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.HELP_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.HELP_STATUS,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling Help API - GET /statusAndCat", loggingDetails);

    let httpOptions = {}

    const ssoToken= this.ssoTokenService.getSSOToken();
    const jwtToken= this.jwtTokenService.getOktaJWT();

    this.headers = new HttpHeaders({
      'correlation-id': this.sfLoggerService.getCorrelationId(),
      ...((ssoToken && ssoToken != '') && {'X-SF_SSO_TOKEN': ssoToken}),
      ...((jwtToken && jwtToken != '') && {Authorization: jwtToken}),
    });

    httpOptions = {
      headers: this.headers, withCredentials: false
    }

    return this.http.get<HelpResponse>(helpUrl, httpOptions).pipe(
      catchError((error) => {
        // SPARKL Logging
        loggingDetails.push(
          SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
          SFLoggerFields.HTTP_RESPONSE, error.status.toString()
        );
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - Help API - GET /statusAndCat", loggingDetails);

        return this.handleHelpError(error.error);
    }),
      tap((data) => {
        this.storeHelpDataInSession(data);

        // SPARKL Logging
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - Help API - GET /statusAndCat", loggingDetails)      
      }),
    );
  }

  storeHelpDataInSession(helpResponse: HelpResponse) {
    sessionStorage.setItem(this.helpDataKey, JSON.stringify(helpResponse));
  }

  getHelpDataFromSession(): HelpResponse {
    return JSON.parse(sessionStorage.getItem(this.helpDataKey));
  }

  private handleHelpError(err: any) {
    return throwError(err);
  }

}
