<section class="container -oneX-breadcrumbs -oneX-container-fluid">
  <div class ="-oneX-container">
    <nav aria-label="breadcrumb">
      <ul itemscope itemtype="https://schema.org/BreadcrumbList">
        <li itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <!-- Authenticated Route -->
          <!-- Not Shop Selection or PE Contact -->
          <a *ngIf="hasClaimAndClient() && !isShopSelection" [href]=claimsHub itemprop="item">
            <span class="-oneX-breadcrumbs-link-name" itemprop="name">
              Claim Overview
            </span>
            <span class="-oneX-breadcrumbs-chevron"></span>
            <meta itemprop="position"/>
          </a>
          <!-- Search Only Route -->
          <a *ngIf="!hasClaimAndClient() && !isValidRoute" [href]=repairServicesUrl itemprop="item">
            <span class="-oneX-breadcrumbs-link-name" itemprop="name">
              Repair Services
            </span>
            <span class="-oneX-breadcrumbs-chevron"></span>
            <meta itemprop="position"/>
          </a>
        </li>

        <!-- Flow -->
        <li itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <!-- Repair Assistant -->
          <a *ngIf="!isValidRoute" itemprop="item">
            <span class="mobile-display" itemprop="name">Repair Assistant</span>
            <meta itemprop="position"/>
          </a>
          <!-- Rental -->
          <a *ngIf="isRental" itemprop="item">
            <span class="mobile-display" itemprop="name">Rental</span>
            <meta itemprop="position"/>
          </a>
          <!-- Photo Estimate -->
          <a *ngIf="isPhotoEstimate" itemprop="item">
            <span class="mobile-display" itemprop="name">Photo Estimate</span>
            <meta itemprop="position"/>
          </a>
        </li>
      </ul>

      <!-- Back / Cancel -->
      <ul *ngIf="isShopSelection">
        <li itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <a *ngIf="isShopSelection" 
             role="button" 
             class="btn-container" 
             (click)="goBack()" 
             (keydown.etner)="goBack()"
             itemprop="item"
             tabindex="0">
            <div class="back-arrow -oneX-icon" itemprop="name" data-icon="arrow_left_24"></div>
            <span id="backButton" class="back-text -oneX-body--secondary" itemprop="name">
              Back
            </span>
            <meta itemprop="position"/>
          </a>
          <!-- Cancel - Go to Claims Hub -->
          <a *ngIf="hasClaimAndClient() && isShopSelection" 
             class="cancel-padding" 
             [href]="claimsHub" 
             itemprop="item"
             tabindex="0">
            <span></span>
            <span class="-oneX-body--secondary" itemprop="name">
              Cancel
            </span>
            <meta itemprop="position"/>
          </a>
        </li>
      </ul>

      <!-- Claim Help -->
      <ul *ngIf="hasClaimAndClient() && isPhotoEstimate">
        <li class="right-alignment" itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <!-- Claims Help -->
          <a itemprop="item" 
          aria-current="page" 
          id="ra-claim-help" 
          queryParamsHandling="merge"
          [routerLink]="['/', { outlets: { help: ['category'] } }]"
          [attr.data-oneX-opensDrawer]="'claims-help-drawer'"
          onclick="oneX.Drawer.showDrawer(this.id)">
            <span *ngIf="displayClaimsHelp" class="-oneX-body--secondary" itemprop="name">
              Claims Help
            </span>
            <meta itemprop="position"/>
          </a>
        </li>
      </ul>

      <!--Claim Status and Coverage Buttons
          **Will add in a future story**
      -->
      <!--
      <ul>
        <li class="right-alignment -oneX-offset-xl-8 -oneX-offset-lg-7 -oneX-offset-md-6" itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <a href="#" itemprop="item">
            <span class="item-padding -oneX-breadcrumbs-link-name" itemprop="name">
              Claim Status
            </span>
            <meta itemprop="position"/>
          </a>
          <a href="#" itemprop="item">
            <span class="-oneX-breadcrumbs-link-name" itemprop="name">
              Coverages
            </span>
            <meta itemprop="position"/>
          </a>
        </li>
      </ul>
    -->
    </nav>
  </div>
</section>

