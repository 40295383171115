<div class="pe-edit-contact-page -oneX -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Photo Estimate Enter Contact Title -->
            <div class="pe-edit-contact-header-container">
                <div class="-oneX-heading--h3 -oneX-font--semi">Enter {{ contactTypeTitleDisplayText }}</div>
            </div>

            <!-- Edit Contact entry section -->
            <div class="edit-contact-container">
                <div class="-oneX-body--primary contact-info-text">This {{ contactTypeInfoDisplayText }} will be used as a one-time contact method.</div>
                <div #phoneInputContainer [style.display]="isPhone ? '' : 'none'" class="contact-container" id="phone-input-container">
                    <label #phoneInputLabel for="phone" class="-oneX-textfield__floatingLabel">Mobile number</label>
                    <input #phoneInput id="phone" type="tel" class="-oneX-textfield--floating-input" data-formatter="phone" aria-invalid="true" aria-describedby="phone-err">
                    <div id="phone-err" class="contact-error-text -oneX-widget__err-text" style="display:none" aria-live="assertive">Please enter a valid mobile number.</div>
                </div>
                <div #emailInputContainer [style.display]="isEmail ? '' : 'none'" class="contact-container" id="email-input-container">
                    <label #emailInputLabel for="email" class="-oneX-textfield__floatingLabel">Email address</label>
                    <input #emailInput id="email" type="text" class="-oneX-textfield--floating-input" aria-invalid="true" aria-describedby="email-err">
                    <div id="email-err" class="contact-error-text -oneX-widget__err-text" style="display:none" aria-live="assertive">Please enter a valid email address.</div>
                </div>

                <!-- Save Button -->
                <div class="btn-container">
                    <button id="pecontact-save-btn"
                            class="-oneX-btn-primary"
                            (click)="saveContact()">Save</button>
                </div>

                <!-- Cancel Save Button -->
                <div class="btn-container">
                    <a id="pecontact-dont-save-btn"
                       class="-oneX-link--block"
                       (click)="cancelSaveContact()"
                       (keydown.enter)="cancelSaveContact()"
                       tabindex="0">Don't save</a>
                </div>
            </div>

        </div>
    </div>
</div>