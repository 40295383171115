import { Phone } from './phone.model';
import { Address } from './address.model';
import { Error } from './error.model';

export class RentalAssignment {
    claimId: string;
    clientId: string;
    participantId: string;
    channelType: string;
    reservationNum?: string;
    totalDays: number;
    vendorOfficeID?: string;
    dailyMaxCoverage?: string;
    rentalCoveragePercent?: string;
    maxCoverage: string;
    deliveryShopName?: string;
    deliveryShopPhone?: string;
    rentalDateTime?: string;
    vehicleClassCode?: string;
    phoneNumber: Phone;
    address?: Address;
    rentalDeliveryPreference: string;
    rentalStatusCode?: string;
    vendorType?: string;
    error?: Error;
}
