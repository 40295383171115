import { Address } from './address.model';
import { Phone} from './phone.model';
import { Name } from './name.model';
import { RentalRate } from './rental-rate.model';

export class Provider {
    id: string;
    phones: Phone[];
    address: Address;
    name: Name;
    distance: string;
    performanceScore: number;
    estimatingId: string;
    rentalRates: RentalRate[];
    certifications: object[];
    nationalProvider?: boolean;
    unavailable?: boolean;
}
