import { Injectable, OnDestroy } from '@angular/core';
import { AdspModel } from '../model/adsp.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject, Subject } from 'rxjs';
import { StateCode } from '../constant/statecode-constants';
import { map, takeUntil } from 'rxjs/operators';

@Injectable()
export class AdspLanguageResolverService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  private adsp: AdspModel = {
    // ADSP language for Oregon(37)
    adsp_content1: 'Oregon law prohibits us from requiring you to obtain repairs to your vehicle at a particular repair facility. ' +
      'You have the right to select the repair facility that will repair your vehicle. Only you can authorize repairs to your vehicle.',
    adsp_content2: 'You have the right to select the repair facility of your choice ' +
      'for your vehicle repairs and only you can authorize repairs to your vehicle.',
    adsp_content3: 'You have the legal right to choose a repair facility to fix your vehicle. ' +
      'We will cover the reasonable costs of repairing your vehicle to its pre-accident condition no matter where you have repairs made.',
    adsp_content4: 'You have the ability to select a repairer of your choice that meets your vehicle repair needs. Only you can authorize' +
      ' repairs to your vehicle. The selection of a Select Service repair shop is not a waiver of any coverage issue that may exist.',
    adsp_default: ''
  };
  private adspModel = new BehaviorSubject(this.adsp);

  constructor(private http: HttpClient) {

    const url = '/assets/content/adsp-language.json';
    this.http.get<AdspModel>(url)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        l => this.adspModel.next(l),
        (err) => console.log(`Failed to resolve ADSP language: ${err}`));
  }

  public getAdspLanguage(stateCode: string): Observable<string> {
    return this.adspModel.pipe(
      // tap(adsp => console.log('language', adsp)),
      map(adsp => this.translate(adsp, stateCode)),
      takeUntil(this.ngUnsubscribe)
    );
  }

  private translate(adsp: AdspModel, stateCode: string): string {
    const adspCategories = [adsp.adsp_default, adsp.adsp_content1, adsp.adsp_content2, adsp.adsp_content3, adsp.adsp_content4];
    const contentCode = StateCode.getAdspContentCodeFromStateCode(stateCode);

    return adspCategories[contentCode];
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
