import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SSOTokenService {
  headers: HttpHeaders;

  constructor(private cookieService: CookieService) { }

  public getSSOToken() {
    return this.cookieService.check('sf-icp-sso') ? this.cookieService.get('sf-icp-sso') : '';
  }

  public isSSOTokenExist(): boolean {
    return this.cookieService.check('sf-icp-sso');
  }

  public deleteSSOToken(externalClaimId = '', externalClientId = '') {
    this.cookieService.delete('sf-icp-sso', '/', '.statefarm.com', true);
  }
}
