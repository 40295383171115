import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataAnalyticsService } from 'app/service/data-analytics.service';
import { HelpService } from 'app/service/help.service';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

@Component({
  selector: 'app-help-faq',
  templateUrl: './help-faq.component.html',
  styleUrls: ['./help-faq.component.css']
})
export class HelpFaqComponent implements OnInit, OnDestroy {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
subCategory: any = {};
subCategoryName = '';
categoryName = '';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
category: any = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
faq: any = {};
answer: string;
faqId = '';
analyticsId = '';
isResponseSent = false;
faqPage = true;
subsink = new SubSink();
helpData;

constructor(
  public route: ActivatedRoute,
  public location: Location,
  private router: Router,
  private urlResolver: UrlResolverService,
  private dataAnalyticsService: DataAnalyticsService,
  private helpService: HelpService
) {}

ngOnDestroy(): void {
  this.subsink.unsubscribe();
}

formatCategoryName(categoryName) {
  //  make milestone sentence - Keep first letter capiitalized, lowercase rest of words, replace ALL (global) underscores with a space
  return `${categoryName.charAt(0).toUpperCase()}${categoryName.slice(1).toLowerCase().replace(/_/g, ' ')}`;
}

displayBreadCrumbsTitle(categoryName) {
  if (categoryName) {
    return `${categoryName!.replace(/_/g, ' ')} Help`;
  }
}

ngOnInit() {
  this.subsink.add(
    this.route.params.subscribe(() => {
      this.helpData = this.helpService.getHelpDataFromSession();

      if (this.helpData) {
        this.route.params.subscribe((res) => {
          this.categoryName = res.majorGroup;
          this.subCategoryName = res.subGroup;
          this.faqId = res.id;
          this.analyticsId = res.analyticsId;
          this.category = this.helpData.categories.filter((x) => x.name === this.categoryName)[0];
          this.subCategory = this.category.subGroups.filter((x) => x.name === this.subCategoryName)[0];
          this.faq = this.subCategory.categoryHelpDocuments.filter((x) => x.id === this.faqId)[0];
          this.isResponseSent = false;
          document.getElementById('claims-help-drawer').scrollTo(0, 0);
        })
      }

      const routeArr = this.router.url.split('?claim');
      this.location.replaceState('/landing?claim'+routeArr[1]);
    })
  );

  this.setAnalytics(this.analyticsId);
}

setAnalytics(analyticsId) {
  this.dataAnalyticsService.sendPageData(`${analyticsId}-help`);
}

getRepairUrl(): string {
  return this.urlResolver.getRaRedirectURL();
}

setRepairUrl(faq) {
  // Commenting out PE until we decide to go out with PE
  // Do not change the ids included in the links below, as they will break analytics
  if (faq && faq.id) {
    //// Repair Assistant links////
    // How can I change my selected shop?
    if (faq.id === 'A_RP10') {
      return this.faq.answer.replace('You can change your selected shop online', `You can <a href="${this.getRepairUrl()}" class="helpdrawer-click-to-ra" target="_self" rel="noopener noreferrer">change your selected shop online</a>`);
    }
    //// RA and PE Links ////
    // What are my estimate options?
    if (faq.id === 'A_E1') {
      return this.faq.answer.replace('If you go with a Select Service shop', `If you go with a <a href="${this.getRepairUrl()}" class="helpdrawer-click-to-ra" target="_self" rel="noopener noreferrer">Select Service shop</a>`);
      // .replace(`can use our Photo Estimate tool`, `can use our <a href="${this.getPhotoEstimateUrl()}" class="helpdrawer-click-to-pe" target="_self" rel="noopener noreferrer">Photo Estimate tool</a>`);
    }
    // How long does an estimate take?
    if (faq.id === 'A_E2' || faq.id === 'A_CP8') {
      return this.faq.answer.replace('If you take the vehicle to a Select Service shop', `If you take the vehicle to a <a href="${this.getRepairUrl()}" class="helpdrawer-click-to-ra" target="_self" rel="noopener noreferrer">Select Service shop</a>`);
      // .replace('our Photo Estimate tool', `our <a href="${this.getPhotoEstimateUrl()}" class="helpdrawer-click-to-pe" target="_self" rel="noopener noreferrer">Photo Estimate tool</a>`);
    }
  }
  return faq.answer;
}

}
