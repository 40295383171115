export class Phone {
    area: string;
    type: string;
    local: string;
    number: string;
    extension: string;
    usage?: string;
    equipmentType?: string;

    constructor( area: string, local: string, number: string, usage?: string, equipmentType?: string ) {
        this.area = area;
        this.number = number;
        this.local = local;
        this.usage = usage;
        this.equipmentType = equipmentType;
    }
}
