<h2
  id="dialogLnkHdr"
  tabindex="-1"
  class="claims-help-heading -oneX-drawer-header -oneX-heading--h2"
>Claims FAQs</h2>

<div class="help-drawer-padding" *ngIf="statusArray">
  <div class="-oneX-body--secondary" id="help-drawer-recommended">Recommended</div>
  <!-- Do not change the id below - it is being used by analytics to track clicks -->
  <button
    *ngFor="let doc of statusArray | slice: 0:5"
    id={{doc.analyticsId}}
    class="help-status-question-links -oneX-link--inline -oneX-link-secondary"
    [routerLink]="['/', { outlets: { help: ['faq', doc.majorGroup, doc.subGroup, doc.id, doc.analyticsId] } }]"
    queryParamsHandling="merge"
    role="link"
  >
    {{ displayStatusQuestions(doc) }}
</button>
</div>

<div  *ngIf="categoryArray">
  <div *ngFor="let category of categoryArray" class="help-individual-category">
    <button
      class="-oneX-row help-categories-padding help-categories-text help-pointer"
      [routerLink]="['/', { outlets: { help: ['sub-category', category.name] } }]"
      queryParamsHandling="merge"
    >
      <div class="help-inner-category-padding -oneX-col-11 -oneX-body--primary">
        {{ displayHelpCategories(category) }}
      </div>
      <div class="help-icon-padding -oneX-col-1" >
        <div id="chevron-icon" class="help-chevron-icon -oneX-icon -oneX-icon--chevron--right"></div>
      </div>
    </button>
  </div>
</div>

<div class="help-borders">
  <hr class="-oneX-hr"/>
</div>

<div class="help-drawer-padding still-have-questions-text">
  <app-help-still-have-questions></app-help-still-have-questions>
</div>
