import { Injectable } from '@angular/core';
@Injectable()
export class ShopInfo {
    shopName: string;
    street: string;
    city: string;
    state: string;
    zipCode?: string;
    phone: string;
    placeId: string;
}
