import { PlacesDetails } from './places-details.model';

export class Shop {
  hover: boolean;
  clicked: boolean;
  phone: string;
  hours: string;
  distance: string;
  name: string;
  address: string;
  city: string;
  state?: string;
  postalCode?: string;
  tier: string;
  reviews: string;
  rating: string;
  latitude: string;
  longitude: string;
  placeDetails?: PlacesDetails;
  estimatingId: string;
  performanceScore: number;
  certifications?: object[];
  id: string;
  nationalProvider?: boolean;
  unavailable?: boolean;
}
