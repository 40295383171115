import { PipeTransform, Pipe } from '@angular/core';
import { Shop } from '../model/shop.model';

@Pipe({
    name: 'shopPosition'
})

export class ShopPositionPipe implements PipeTransform {
    transform(shop: Shop, args?: string): google.maps.LatLngLiteral {
        const shopPosition: google.maps.LatLngLiteral = { lat: Number(shop.latitude), lng: Number(shop.longitude) };
        return shopPosition;
    }
}
