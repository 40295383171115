import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { SSOTokenService } from 'app/service/sso.token.service';
import { AuthService } from 'app/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard  {

  constructor(
    private ssoTokenService: SSOTokenService,
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const extClaimId: string = this.getParams(next, 'claim');
    const extClientId: string = this.getParams(next, 'client');

    if (extClaimId && extClientId) {

      if (this.ssoTokenService.isSSOTokenExist()) {
        return this.authService.authenticateUser(extClaimId, extClientId).pipe(
          switchMap(response => {
            // response status is 200 mean the token is valid loa4 token only
            if (response.body['Identity'].loa === 4) {
              this.authService.setUserLoggedIn(true);
              return of(true);
            } // don't care about loa2 
            return of(true);
          })
        );
      }
    } else {
      return of(true);
    }
  }

  getParams(route: ActivatedRouteSnapshot, key: string) {
    return route.queryParams[key] || route.params[key];
  }

}
