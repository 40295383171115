<app-navigation-breadcrumb>
</app-navigation-breadcrumb>

<section>
  <div class="-oneX-font-Mecherle confirmation-container">
    <div class="heading-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">

      <!-- Icon & Request Sent -->
      <div class="icon-title-container">
        <img class="icon-check" src="assets/confirmation.svg" alt="" />
        <div class="title-header -oneX-heading--h1">Request sent</div>
      </div>
      <!-- Title Content -->
      <div class="title-content -oneX-body--intro">
        {{vendorType}} will contact you within 4 business hours to finalize your request.
        <a class="-onex-link--inline -oneX-link-intro claim-link" role="link"
        tabindex="0"
        (click)="redirect()"
        (keydown.enter)="redirect()">Continue to your claim</a> to view rental details.
      </div>

      <!-- Request Number-->
      <div *ngIf="this.rentalAssignmentRequestNumber" class="request-number -oneX-body--primary">
        Request Number: {{ rentalAssignmentRequestNumber.transactionContractID }}
      </div>

    </div>
  </div>
</section>
