import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  NgZone,
  ViewChild,
  HostListener,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { GoogleSearchService } from '../service/google-search.service';
import { SearchConstant } from '../constant/search-constants';
import { SearchResultsService } from '../service/search-results.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ChangeView } from '../model/change-view.model';
import { SearchResultsResponse } from '../model/search-results-response';
import { ProviderRequest } from '../model/provider-request.model';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { ShopSelectorManagementService } from 'app/service/shop-selector-management.service';
import { Maintenance } from '../model/maintenance.model';
import { ServiceClientsService } from '../service/service-clients.service';

import { StorageService } from 'app/service/storage.service';
import { WINDOW } from '../service/window.service';
import { UrlParams } from '../model/url-params.model';


/// <reference types="@types/googlemaps" />

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentPage: string;
  @Input() isViewStatic: Boolean;
  @Input() isSidebarVisabile: Boolean;
  @Input() searchResultsPage: Boolean;
  @Input() BLandingPage: Boolean;
  @Output() changetoResultView = new EventEmitter<ChangeView>();
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('searchComponent', { static: true }) public searchComponentRef: ElementRef;
  searchForm: any = {};
  notValidLocation = false;
  searchType: any;
  searchBarText: string;
  searchTerm: string;
  searchRadius: string;
  makeValue: string = '';
  radiusLabels: any;
  isSearching: boolean;
  isSearchInputEmpty: boolean;
  searchHasFocus: boolean;
  maintainedFocus: boolean;
  isPanMoved: boolean;
  searchResponse: SearchResultsResponse;
  maintenance$: ReplaySubject<Maintenance>;
  displayCertShopsNotFound: boolean;
  vehicleMakeOptions: string[] = ['', 'Tesla', 'Rivian', 'Other'];
  autocompleteBound: boolean;

  private ngUnsubscribe = new Subject();
  private ngUnsubscribeSubmit = new Subject();

  oneX: any;
  urlParams: UrlParams;

  constructor(
    @Inject(WINDOW) private window: Window,
    private __zone: NgZone,
    private fb: UntypedFormBuilder,
    private googleSearchService: GoogleSearchService,
    private dataAnalyticsService: DataAnalyticsService,
    private searchResultsService: SearchResultsService,
    private changeDetector: ChangeDetectorRef,
    private shopSelectorManagementService: ShopSelectorManagementService,
    private clientService: ServiceClientsService,
    private storageService: StorageService
  ) { 
    this.oneX = this.window['oneX'];
    this.urlParams = new UrlParams();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.defineDistanceDropdownLabel();
  }

  ngOnInit() {
    this.searchHasFocus = false;
    this.initForm();
    this.maintenance$ = this.clientService.callToMaintenanceService();
    this.autocompleteBound = false;

    this.googleSearchService.getAutocompleteSubject().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      this.submitFromAutoComplete(response);
    });

    this.shopSelectorManagementService.getIsPanMoved().subscribe(response => {
      this.isPanMoved = response;
    });

    // Change text based on if user is transactional or not
    if(window.location.search.includes('claim=')) {
      this.searchBarText = "Shop, address, or ZIP";
      // get the vehicle make only for transcational flow
      this.getVehicleDetail();
    } else {
      this.searchBarText = "Enter address";
      this.vehicleMakeOptions.splice(this.vehicleMakeOptions.length-1, 0, 'Camper/RV');
    }
  }

  ngAfterViewInit() {
    this.initForm();
  }

  private initForm() {
    this.searchForm = this.fb.group({
      address: ['', Validators.required],
      radius: ['', Validators.required],
    });
    this.defineDistanceDropdownLabel();
    this.searchForm.get('address').setErrors({ notValidLocation: false });
    this.searchForm
      .get('address')
      .setValue(this.searchResultsService.getSearchedCriteria().searchTerm);
    this.isSearching = false;

    this.isSearchInputEmpty =
      this.searchResultsService.getSearchedCriteria().searchTerm === ''
        ? true
        : false;
    if(!this.searchResultsPage && this.searchResultsService.getSearchedCriteria().makeValue){
      this.makeValue = this.searchResultsService.getSearchedCriteria().makeValue;
      this.dataAnalyticsService.setVehicleMake(this.makeValue);
    }    
    this.oneX.addElement(this.searchComponentRef.nativeElement);
  }

  onTextInput(event: any) {
    this.displayCertShopsNotFound = false;
    if (!this.autocompleteBound && event.target.value.length > 2) {
      this.googleSearchService.bindGoogleSearch(this.searchElementRef.nativeElement);
      this.autocompleteBound = true;
    } else if (this.autocompleteBound && event.target.value.length <= 3) {
      google.maps.event.clearInstanceListeners(this.searchElementRef.nativeElement);
      if(document.querySelector('.pac-container')) {
        document.querySelector('.pac-container').remove();
      }
      this.autocompleteBound = false;
    }
    return this.searchForm.get('address').value.length === 0 ? true : false;
  }


  clearSearch() {
    this.searchTerm = '';
  }

  selectVehicleMake(vehicleMake) {
    this.dataAnalyticsService.setVehicleMake(vehicleMake);
  }

  private defineDistanceDropdownLabel() {
    const labelType: string = 'short';
    this.radiusLabels = SearchConstant._DEFAULT_RADIUS_LABELS[labelType];
    const index = this.radiusLabels[
      this.searchResultsService.getSearchedCriteria().searchRadius.index
    ];
    this.searchForm.get('radius').setValue(index);
  }

  searchIfRadiusChanged() {
    if (this.searchForm.get('address').value.length !== 0) {
      this.submitFromForm();
    }
  }

  getVehicleDetail() {
    if(!this.searchResultsPage && !this.searchResultsService.getSearchedCriteria().makeValue && this.searchResultsService.getSearchedCriteria().makeValue !== ''){
      this.clientService
      .getVehicleDetail()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        vehicle  => {
          if (vehicle) {
            const make = vehicle.make.charAt(0).toUpperCase()+vehicle.make.slice(1).toLowerCase();
            if(make == 'Tesla' || make == 'Rivian') {
              if(!this.searchResultsPage && !this.searchResultsService.getSearchedCriteria().makeValue && this.searchResultsService.getSearchedCriteria().makeValue !== ''){ 
                this.makeValue = make;
                this.dataAnalyticsService.setVehicleMake(this.makeValue);
              }
            }
          } 
        }
      );
    }
  }

  submitFromAutoComplete(place) {
    this.displayCertShopsNotFound = false;
    if (this.searchForm.valid && place['types']) {
      this.searchTerm = this.searchElementRef.nativeElement.value;
      this.searchRadius = this.searchForm.get('radius').value;
      this.isSearching = true;
      this.changeDetector.detectChanges(); // used for triggering searching icon when user selects from autocomplete

      this.searchResultsService.setSearchType(place['types'][0]);
      this.searchResultsService.setValuesFromAddressComponents(
        place['address_components'],
        place['formatted_address']
      );
      this.searchResultsService.setYouAreHerePin(place['geometry']);
      this.searchResultsService.getSearchResults(
          this.searchTerm,
          this.searchRadius,
          this.makeValue
        );
      this.onSubmit();
    }

  }

  submitFromForm(){
    this.displayCertShopsNotFound = false;
    if (this.searchForm.valid) {
      this.searchTerm = this.searchElementRef.nativeElement.value;
      this.searchRadius = this.searchForm.get('radius').value;

      this.isSearching = true;
      this.changeDetector.detectChanges(); // used for triggering searching icon when user selects from autocomplete
      this.googleSearchService.performSearch().pipe(first(), takeUntil(this.ngUnsubscribeSubmit)).subscribe(
        response => {
          this.storageService.setSessionStorage('isEstimateAssist', 'false');
          this.searchResultsService.setSearchType(response['types'][0]);
          this.searchResultsService.setValuesFromAddressComponents(
            response['address_components'],
            response['formatted_address']
          );
          this.searchResultsService.setYouAreHerePin(response['geometry']);
          this.searchResultsService.getSearchResults(
              this.searchTerm,
              this.searchRadius,
              this.makeValue
            );
          this.onSubmit();
        },

        error => {
          this.__zone.run(() => {
            this.searchForm
              .get('address')
              .setErrors({ notValidLocation: true });
            this.dataAnalyticsService.sendPageData(
              'enter-valid-location-error'
            );
            this.isSearching = false;
          });
        }
      );
    }
  }

  onSubmit() {
    this.searchResultsService
      .isSearchReturned()
      .pipe(
        takeUntil(this.ngUnsubscribeSubmit)
      )
      .subscribe(changeView => {
        if (changeView) {
          if (this.searchResultsService.getSearchResultsResponse().shops.length === 0 &&
            this.searchResultsService.getSearchResultsResponse().zipCode) {
            if (changeView.destination === 'RA' && changeView.shouldChange) {
              changeView.shouldChange = false;
              if (this.searchResultsService.getSearchResultsResponse().displayCertShopsNotFound) {
                changeView.shouldChange = false;
                this.isSearching = false;
                this.maintainedFocus = true;
                this.displayCertShopsNotFound = true;
                this.makeValue ='';
                this.dataAnalyticsService.setVehicleMake(this.makeValue);
                this.searchForm.get('radius').setValue('75 mi');
                this.clearSearch();
                this.searchForm.get('address').reset();
                this.searchHasFocus = false;
                this.isSearchInputEmpty = true;
                this.searchElementRef.nativeElement.blur();
                if(this.searchForm.get('address').hasError('required')) {
                  this.searchForm.get('address').setErrors({'required': false});
                  this.searchForm.updateValueAndValidity();
                }
                this.searchForm.get('radius').setValue('75 mi');
              } else if (this.searchResultsService.getSearchResultsResponse().radius === '75'){
                console.log('TPAR might be down' + changeView.shouldChange);
                changeView.shouldChange = true;
              }else {
                this.callZipCodeSearch();
              }
            }
          } else if (this.searchResultsService.getSearchResultsResponse().shops.length === 0 && this.searchResultsService.getSearchResultsResponse().displayCertShopsNotFound) {
                changeView.shouldChange = false;
                this.isSearching = false;
                this.maintainedFocus = true;
                this.displayCertShopsNotFound = true;
                this.makeValue = '';
                this.dataAnalyticsService.setVehicleMake(this.makeValue);
                this.clearSearch();
                this.searchForm.get('address').reset();
                this.searchHasFocus = false;
                this.isSearchInputEmpty = true;
                this.searchElementRef.nativeElement.blur();
                if(this.searchForm.get('address').hasError('required')) {
                  this.searchForm.get('address').setErrors({'required': false});
                  this.searchForm.updateValueAndValidity();
                }
                this.searchForm.get('radius').setValue('75 mi');
          }
          this.callUnsubscribe();
          this.changetoResultView.emit(changeView);
        }
      });
  }

  callUnsubscribe(){
    this.ngUnsubscribeSubmit.next();
  }

  private callZipCodeSearch() {
    const response = this.searchResultsService.getSearchResultsResponse();
    this.searchResultsService.setRadius(response.radius);
    const request = new ProviderRequest();
    request.postalCode = response.zipCode;
    request.radius = response.radius;
    request.isZipCodeSearch = true;
    request.isNameSearch = false;
    request.searchTerm = this.searchResultsService.getSearchRequest().searchTerm;
    request.isSearchAgainByZipCode = this.searchResultsService.getSearchResultsResponse().displayShopsNotFound;
    this.searchResultsService.setProviderRequest(request);
    this.searchResultsService.getRepairShops(request);
    this.searchResultsService
      .isSearchReturned()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(changeView => {
        if (changeView) {
          this.changetoResultView.emit(changeView);
        }
      });
  }

  toggleIsPanMoved() {
    this.shopSelectorManagementService.setIsPanMoved(false);
  }

  ngOnDestroy() {
    this.oneX.removeElement(this.searchComponentRef.nativeElement);
    if(document.querySelector('.pac-container')) {
      document.querySelector('.pac-container').remove();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribeSubmit.complete();
  }
}
