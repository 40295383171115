<div class="-oneX-container -oneX center">
  <div class="-oneX-row-fluid">
    <div
      class="title-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8"
    >
      <div tabindex="0" class="-oneX-icon-container">
        <div
          class="-oneX-icon icon-warning"
          data-icon="warning_fill_32"
        ></div>
      </div>
      <div class="title-header -oneX-heading--h1">
        Sorry, we’re having trouble with your request
      </div>
    </div>
  </div>
  <div class="-oneX-row-fluid">
    <div
      class="error-content-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6"
    >
      <div class="error-content -oneX-heading--h4">
        Please contact us at 800-SF-CLAIM 
        <a href="tel:800-732-5246" class="-oneX-link--inline" *ngIf="isDeviceMobile; else deviceDesktop">
        (800-732-5246) </a>
        <ng-template #deviceDesktop>(800-732-5246)</ng-template>
        to choose a shop or try
        again later. 
      </div>
      <div class="error-content -oneX-heading--h4">
        You can also view a list of our in-network Select Service
        shops to choose from at a later time.
      </div>
      <div class="return-btn">
        <button
          class="-oneX-btn-primary clickable"
          (click)="redirectToSearchOnly()"
          (keydown.enter)="redirectToSearchOnly()"
        >
          View shops
        </button>
      </div>
    </div>
  </div>
</div>
