import { Injectable } from '@angular/core';
import { FeatureFlagResolverService } from 'app/service/feature-flag-resolver.service';
import { BehaviorSubject, ReplaySubject, Subject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// MODELS
import { PhotoEstimateEligibility } from '../model/photo-estimate.model';
import { RepairResponse } from 'app/model/repair-response.model';
import { CoverageInfo } from 'app/model/coverage-info.model';
import { VehicleInfo } from 'app/model/vehicle-info.model';
import { SecondaryAssignment } from 'app/model/secondary-assignment.model';
import { FeatureFlags } from '../model/feature-flags.model';
import { Maintenance } from '../model/maintenance.model';
import { Participant } from '../model/participant.model';

// SERVICES
import { ConversionService } from './conversion.service';
import { DataAnalyticsService } from './data-analytics.service';
import { StorageService } from 'app/service/storage.service';
import { AdspLanguageResolverService } from './adsp-language-resolver.service';
import { MaintenanceResolverService } from './maintenance-resolver.service';
import { SSOTokenService } from './sso.token.service';
import { JWTTokenService } from './jwt.token.service';
import { PhotoEstimateService } from './photo-estimate.service';
import { RepairExperienceService } from './repair-experience.service';
import { InteractionsService } from './interactions.service';
import { SFLoggerService } from './sf-logger.service';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

const defaultRepairDetail = { ComplianceVerbiage: 'estimate' };

@Injectable({providedIn: 'root'})
export class ServiceClientsService {
  private participantsResponse$: ReplaySubject<Participant[]> = new ReplaySubject<Participant[]>(1);
  private flags$: ReplaySubject<FeatureFlags> = new ReplaySubject<FeatureFlags>(1);
  private maintenance$: ReplaySubject<Maintenance> = new ReplaySubject<Maintenance>(1);
  private eligibleForEstimates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private eligibleForRepairAssignment$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private catastropheEligible$: Subject<boolean> = new Subject<boolean>();
  private eligibleForRentalAssignments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private eligibleForIIO$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private assignmentCount$: Subject<number> = new Subject<number>();
  public loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private photoEstimateEligibility: PhotoEstimateEligibility;

  private participantId: string;
  private closedClaimIndicator: boolean;
  private participantType = '';
  private vehicleNumber = '';
  private phone = '';
  private email = '';
  private participantDefaultState = '';
  private commercialVehicleIndicator: boolean;
  private rentalCompliance: boolean;
  private hasPEAssignment: boolean;
  public assignmentCount: number;

  private externalClaimId: string;
  private externalClientId: string;

  // By default, verbiage is estimate for search only
  private repairDetail$: BehaviorSubject<RepairResponse> = new BehaviorSubject(defaultRepairDetail);
  private repairDetailsErrorDefault$: ReplaySubject<RepairResponse> = new ReplaySubject<RepairResponse>(1);

  constructor(
    private adspLanguageResolverService: AdspLanguageResolverService,
    private featureFlagResolver: FeatureFlagResolverService,
    private maintenanceResolver: MaintenanceResolverService,
    private ssoTokenService: SSOTokenService,
    private jwtTokenService: JWTTokenService,
    private photoEstimateService: PhotoEstimateService,
    private repairExperienceService: RepairExperienceService,
    private interactionsService: InteractionsService,
    private conversionService: ConversionService,
    private dataAnalyticsService: DataAnalyticsService,
    private sfLoggerService: SFLoggerService,
    protected storageService: StorageService,
  ) { }

  public initClientServices(externalClaimId: string, externalClientId: string, participantId?: string) {
    this.externalClaimId = externalClaimId;
    this.externalClientId = externalClientId;
    this.participantId = participantId;
    
    if ((this.ssoTokenService.isSSOTokenExist() || this.jwtTokenService.isJWTTokenExist()) && externalClaimId) {
      if (externalClientId) {
        this.callToRepairExperienceToGetRepairDetail(externalClaimId, externalClientId, participantId);
      }
    }
  }

  /* NOTE - The underlying feature flags object is defined as a BehaviorSubject, so even though this is
     defined over here as a ReplaySubject the default value will still be emitted before the http request
     is made to retrieve the flags.
  */
  public callToFeatureFlagService(): ReplaySubject<FeatureFlags> {
    this.featureFlagResolver.getFeatureFlags().subscribe(l => {
      this.flags$.next(l);
    });
    return this.flags$;
  }

  /* This will first make the http request call before returning the feature flags. Default flags will be
     returned if there is an error with the http request call.
  */
  public callToFetaureFlagServiceWithErrorDefault(): Observable<FeatureFlags> {
    return this.featureFlagResolver.getFeatureFlagsWithErrorDefault();
  }

  public callToMaintenanceService(): ReplaySubject<Maintenance> {
    this.maintenanceResolver.getMaintenance().subscribe(l => {
      this.maintenance$.next(l);
    });
    return this.maintenance$;
  }

  public getADSPLanguage(): Observable<string> {
    return this.repairDetail$.pipe(
      switchMap(detail =>
        this.adspLanguageResolverService.getAdspLanguage(detail.ClaimStateCode)
      )
    );
  }

  public callToRepairExperienceToGetRepairDetail(
    externalClaimId: string,
    externalClientId: string,
    participantId?: string
  ) {
      this.repairExperienceService
      .getRepairDetails(externalClaimId, externalClientId, participantId)
      .subscribe({next: (res: RepairResponse) => {
        this.interactionsService.setProducerData(res.ParticipantID, res.VehicleNumber, res.Vehicle.make, res.Vehicle.model, res.Vehicle.year, res.ClaimStateCode);
        this.interactionsService.setRentalCoverageIndicator(res.Eligibility.Rental.toString());
        if (res.Eligibility.SecondaryAssignment) {
          this.interactionsService.setRepairShopId(res.Eligibility.SecondaryAssignment.ActorID);
          this.assignmentCount$.next(res.Eligibility.SecondaryAssignment.AssignmentCount);
          this.assignmentCount = res.Eligibility.SecondaryAssignment.AssignmentCount;

          if ((res.Eligibility.SecondaryAssignment.InspectionOption === 'Staff') && (res.Eligibility.SecondaryAssignment.EstimatorID === '00060836')) {
            this.hasPEAssignment = true;        
          } else {
            this.hasPEAssignment = false;
          }
        } else {
          this.assignmentCount$.next(0);
          this.assignmentCount = 0;
          this.hasPEAssignment = false;
        }
        this.repairDetail$.next(res);
        this.repairDetailsErrorDefault$.next(res);

        this.eligibleForRepairAssignment$.next(res.Eligibility.Repair);
        this.catastropheEligible$.next(res.Eligibility.Catastrophe);
        this.eligibleForEstimates$.next(res.Eligibility.Estimates);
        this.eligibleForRentalAssignments$.next(res.Eligibility.Rental);
        this.eligibleForIIO$.next(res.Eligibility.IIO);
        this.closedClaimIndicator = res.Eligibility.ClosedClaim;
        this.storageService.setSessionStorage('isRentalOptOut', res.Eligibility.RentalOptOut.toString());
        this.dataAnalyticsService.setClaimStatus(this.closedClaimIndicator);

        const p = [];
        p.push({
          id: res.ParticipantID,
        });
        this.participantsResponse$.next(p);
        this.participantId = res.ParticipantID;
        this.vehicleNumber = res.VehicleNumber ? res.VehicleNumber : '0';
        this.participantType = res.ParticipantType;
        this.commercialVehicleIndicator = (res.CommercialVehicleIndicator === 'true');

        // TODO: default state is not returned from RE, but RE has directive
        this.participantDefaultState = res.ParticipantDefaultState
          ? res.ParticipantDefaultState
          : '';

        this.rentalCompliance = res.RentalCompliance ? res.RentalCompliance : false;

        let customerDetails = [
          SFLoggerFields.CLAIM_NUMBER, res.ClaimNumber,
          SFLoggerFields.CLAIM_STATE_CODE, res.ClaimStateCode,
          SFLoggerFields.PARTICIPANT_ID, res.ParticipantID,
          SFLoggerFields.VEHICLE_NUM, res.VehicleNumber,
          SFLoggerFields.CLOSED_CLAIM, res.Eligibility.ClosedClaim,
          SFLoggerFields.REPAIR_ELIGIBLE, res.Eligibility.Repair,
          SFLoggerFields.RENTAL_ELIGIBLE, res.Eligibility.Rental,
          SFLoggerFields.ESTIMATE_ELIGIBLE, res.Eligibility.Estimates,
          SFLoggerFields.CAT_ELIGIBLE, res.Eligibility.Catastrophe,
          SFLoggerFields.ASSIGNMENT_COUNT, this.assignmentCount,
          SFLoggerFields.PARTICIPANT_DEFAULT_STATE, this.participantDefaultState
        ]
        this.sfLoggerService.setContext(customerDetails);
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_CUSTOMER_DETAILS, "Customer Details");

        // Call the get the Photo Estimate eligibility.
        this.callToPhotoEstimateEligibility(externalClaimId, this.vehicleNumber, this.participantId);

        // SPARKL Logging
        const loggingDetails = [
          SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
          SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR,
        ]
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - RE API - POST /v3/repair", loggingDetails)

        this.loaded$.next(true);
      },
      error: error => {
        this.repairDetailsErrorDefault$.next(defaultRepairDetail);
        // SPARKL Logging
        const loggingDetails = [
          SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
          SFLoggerFields.ENDPOINT, error.url,
          SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
          SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR,
          SFLoggerFields.HTTP_RESPONSE, error.status.toString()
        ];
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - RE API - POST /v3/repair", loggingDetails);
      }
      });
    }

  public callToPhotoEstimateEligibility(
    externalClaimId: string, vehicleNum: string, extParticipantId: string
  ) {
    this.photoEstimateService
      .getEligibility(externalClaimId, vehicleNum, extParticipantId)
      .subscribe((response: PhotoEstimateEligibility) => {
        this.photoEstimateEligibility = response;
      });
  }

  public isEligibleForEstimates(): BehaviorSubject<boolean> {
    return this.eligibleForEstimates$;
  }

  public isEligibleForRepairAssignment(): BehaviorSubject<boolean> {
    return this.eligibleForRepairAssignment$;
  }

  public isCatastropheEligible(): Subject<boolean> {
    return this.catastropheEligible$;
  }

  public isEligibleForRentalAssignments(): BehaviorSubject<boolean> {
    return this.eligibleForRentalAssignments$;
  }

  public isEligibleForIIO(): BehaviorSubject<boolean> {
    return this.eligibleForIIO$;
  }

  public getParticipantId(): string {
    return this.participantId;
  }

  public getClosedClaimIndicator(): boolean {
    return this.closedClaimIndicator;
  }

  public getParticipantType(): string {
    return this.participantType;
  }

  public getExternalClaimId(): string {
    return this.externalClaimId;
  }

  public getExternalClientId(): string {
    return this.externalClientId;
  }

  public getVehicleNumber(): string {
    return this.vehicleNumber;
  }

  public getCommercialVehicleIndicator(): boolean {
    return this.commercialVehicleIndicator;
  }

  public getParticipantDefaultState(): string {
    return this.participantDefaultState;
  }

  public getRentalCompliance(): boolean {
    return this.rentalCompliance;
  }

  public isSearchPageHasBeenVisited(): boolean {
    console.log('i have a participant id', this.participantId);
    if (this.participantId && this.externalClaimId && this.externalClientId) {
      return true;
    }
    return false;
  }

  public getPhone(): Observable<string> {
    return this.repairDetail$.pipe(map(res => res.Phone));
  }

  public getEmail(): Observable<string> {
    return this.repairDetail$.pipe(map(res => res.Email));
  }

  public getClaimNumber(): Observable<string> {
    return this.repairDetail$.pipe(map(res => res.ClaimNumber));
  }

  public getComplianceVerbiage(): Observable<string> {
    return this.repairDetail$.pipe(
      map(detail => detail.ComplianceVerbiage));
  }

  public getAssignmentCount(): Subject<number> {
    return this.assignmentCount$;
  }

  public getHasPEAssignment(): boolean {
    return this.hasPEAssignment;
  }

  public getParticipantsResponse(): ReplaySubject<Participant[]> {
    return this.participantsResponse$;
  }

  public getCoverageDetail(): Observable<CoverageInfo> {
    return this.repairDetail$.pipe(
      map(detail => detail.Eligibility.Coverages)
    );
  }

  public getVehicleDetail(): Observable<VehicleInfo> {
    return this.repairDetail$.pipe(
      map(detail => detail.Vehicle));
  }

  public getCurrentShopDetail(): Observable<SecondaryAssignment> {
    return this.repairDetail$.pipe(
      map(
        detail => {
          if (detail && detail.Eligibility) {
            return detail.Eligibility.SecondaryAssignment;
          }
          else {
            return {}
          }
        }
      )
    );
  }

  public getPhotoEstimateEligibility(): PhotoEstimateEligibility {
    return this.photoEstimateEligibility;
  }

  public getRepairDetailsWithErrorDefault(): ReplaySubject<RepairResponse> {
    return this.repairDetailsErrorDefault$;
  }
}
