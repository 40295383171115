export class SearchConstant {
    public static _DEFAULT_RADIUS_LABELS_INDEX = 0;

    public static _DEFAULT_RADIUS_LABELS = {
        long: [
            '5 Miles', '10 Miles', '15 Miles', '25 Miles', '50 Miles', '75 Miles'
        ],
        short: [
            '5 mi', '10 mi', '15 mi', '25 mi', '50 mi', '75 mi'
        ]
    };
}
