import { PipeTransform, Pipe } from '@angular/core';
import { Shop } from '../model/shop.model';

@Pipe({
    name: 'shopMarker'
})

export class ShopMarkerPipe implements PipeTransform {
    transform(shop: Shop, shopIndexStart: number, i: number, selectedShopIndex: number): google.maps.MarkerOptions {
        let icon: google.maps.Icon;
        
        if ((i + shopIndexStart) === selectedShopIndex) {
            icon = {
                url:  './assets/pin_selected.svg',
                scaledSize: new google.maps.Size(48, 48)
            };
        } else {
            icon = {
                url: './assets/pin_' + (i + 1 + shopIndexStart) + '.svg',
                scaledSize: new google.maps.Size(25, 36)
            };
        }

        const shopMarkerOptions: google.maps.MarkerOptions = {
            animation: google.maps.Animation.DROP,
            icon: icon,
            zIndex: 150 - i
        };

        return shopMarkerOptions;
    }
}
