import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public getSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  public setSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  public getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  public setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public clearSessionStorage(key: string) {
    if (sessionStorage.getItem(key)) {
     sessionStorage.removeItem(key);
    }
  }

  public isRefreshed(searchedResultFromSessionStorage, searchResultsService): boolean {
    return (
      searchedResultFromSessionStorage &&
      !searchResultsService.getSearchResultsResponse()
    );
  }

  public isBookMarked(searchedResultFromSessionStorage, searchResultsService): boolean {
    return (
        !searchedResultFromSessionStorage &&
        !searchResultsService.getSearchResultsResponse()
    );
  }
}
