<!DOCTYPE html>

<app-navigation-breadcrumb>
</app-navigation-breadcrumb>

<div *ngIf="(maintenance$ | async)?.maintenanceOutage_during_flag" class="maintenance-border -oneX -oneX-notification">
  <section class="banner -oneX-notification__icon -oneX-notification--warning">
    <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
  </section>
  <section class="notif-background -oneX-notification__text">
    {{(maintenance$ | async)?.maintenanceOutage_during}}
  </section>
</div>
<div *ngIf="(maintenance$ | async)?.maintenanceOutage_prior_flag" class="maintenance-border -oneX -oneX-notification">
  <section class="banner -oneX-notification__icon -oneX-notification--warning">
    <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
  </section>
  <section class="notif-background -oneX-notification__text">
    {{(maintenance$ | async)?.maintenanceOutage_prior}}
  </section>
</div>


<!-- Staffing Challenge Banner / Modal -->
<!-- <div class="maintenance-border -oneX -oneX-notification">
  <section class="banner -oneX-notification__icon -oneX-notification--warning">
    <span class="-oneX-icon--warning--inverse" aria-label="warning" role='img'></span>
  </section>
  <section class="notif-background -oneX-notification__text">
    Customers may <a id="inline-link" href="javascript:void(0);" onclick="oneX.Modal.showModal(this, 'challenge-modal')" class="-oneX-link--inline">experience longer repair times</a> due to parts delays and labor shortages. Contact your repair facility before dropping off your vehicle.
  </section>
</div> -->

<div id="challenge-modal" class="-oneX -oneX-modal__container" style="display:none" >
  <div class="-oneX-modal-content" style="text-align: center;" aria-labelledby="dialogNoAction" role="dialog">
    <div class="icon-header" style="display: inline-flex; flex-direction: row;">
      <div tabindex="0" class="-oneX-icon-container">
        <div class="-oneX-icon challenge-modal-icon" data-icon="alert_fill_32" style="height: 48px; width: 48px; margin-top: 40px;"></div>
      </div>
      <div id="dialogNoAction" class="-oneX-modal-heading -oneX-heading--h2 challenge-modal-title" role="heading" aria-level="2" tabindex="-1">Additional details</div>
    </div>
    <div class="-oneX-modal-body" style="text-align: left;">
      <ul style="padding-left: 20px;">
        <li style="margin: 16px 0;">Some essential auto parts remain in short supply and delivery and repair times can be adversely impacted.</li>
        <li style="margin: 16px 0;">Extended repair times and limited availability of rental cars can result in higher rental cost.</li>
        <li style="margin: 16px 0;">State Farm is only responsible for rental costs up to your coverage limit.</li>
      </ul>
    </div>
      <button type="button" onclick="oneX.Modal.hideModal()" class="-oneX-close-X-button" aria-label="Close Modal">
        <span class="-oneX-icon--close">
        </span>
      </button>
  </div>
</div>


<!--Title/Subtitle-->
<div class="landing-container -oneX-container-fluid -oneX">
  <div class="-oneX-row-fluid">
    <div class="flex-container -oneX-container">
      <div class="title -oneX-heading--h1 -oneX-offset-xl-1 -oneX-offset-lg-1 -oneX-col-lg-5 -oneX-col-xl-5 -oneX-col-md-5 -oneX-col-sm-5">State Farm<br> Select Service<sup>®</sup></div>
      <div class="search-container -oneX-col-lg-5 -oneX-col-xl-5 -oneX-col-md-7 -oneX-offset-md-0 -oneX-col-sm-8 -oneX-offset-sm-0">
        <div class="subtitle -oneX-body--intro">{{(title$ | async)}}<br class="mobile tablet desktop">{{(title3$ | async)}}<span
          *ngIf="this.adspLanguage$ | async" (click)="scrollBottom()"><sup class="one-top">1</sup></span>
        </div>
        <!--Shop Search Component-->
        <div class="search-bar-location">
          <div>
            <app-search [currentPage]="currentPage" [isViewStatic]="isViewStatic" (changetoResultView)="changetoResultView($event)">
            </app-search>
          </div>
        </div>
        <!-- Current Shop Link -->
        <div *ngIf="(currentShop$ | async)?.ShopInfo as shop">
          <!-- Contextual Help -->
          <span *ngIf="shop.name" class="-oneX-contextual-help-container -oneX-standalone-help -oneX-help-bottom">
            <button class="-oneX-contextual-help -oneX-link--inline -oneX-link-primary"
                    (focus)="initializeContextualHelp()"
                    (mouseenter)="initializeContextualHelp()">
              Current shop selection
              <div class="-oneX-notch"></div>
            </button>
            <span class="-oneX-contextual-help--text">
              <!-- Shop Details -->
              <div class="-oneX-body--secondary">
                <div>{{ shop.name| titlecase }}</div>
                <div>{{ this.shopInfoService.correctShopAddressFormat(shop.address) }},
                     {{ shop.state }}
                     {{ shop.zipcode | slice:0:5 }}
                </div>
                <div>{{ shop.phone | slice: 0:3 }}-{{ shop.phone | slice:3:6 }}-{{ shop.phone | slice:6 }}
                </div>
              </div>
              <button type="button" aria-label="Close Help" class="-oneX-close-X-button">
                <span class="-oneX-icon--close"></span>
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- Vertical Indicator -->
  <div *ngIf="onDesktop()">
    {{changeFont(getScrolled())}}
    <div id="myPosition" class="vertical-indicator-container-fixed hide">
      <div class="flex-indicator -oneX-row-fluid -oneX-container -oneX-offset-xl-0 -oneX-offset-lg-0 -oneX-offset-md-0">
        <div class="navigation-container -oneX-font-Mecherle ">
          <div class="process-info">
            <a id="process" (click)="scrollToDestination('how-it-works')" onclick="return false;">PROCESS</a>
          </div>
          <div class="benefits-info">
            <a id="benefits" (click)="scrollToDestination('select-service')" onclick="return false;">BENEFITS</a>
          </div>
          <div class="help-info">
            <a id="help" (click)="scrollToDestination('help-topics')" onclick="return false;">HELP</a>
          </div>
        </div>
        <div class="bar-container">
          <div id="progress-bar"></div>
        </div>
      </div>
    </div>
  </div>

  <!--How it works section title / section content-->
  <div class="-oneX-row-fluid -oneX-offset-xl-3 -oneX-offset-lg-3 -oneX-offset-md-3 -oneX-offset-sm-3">
    <div id="how-it-works" class="how-it-works-container -oneX-container">
      <div role="heading" aria-level="2" class="how-section-title -oneX-heading--h3 -oneX-col-xl-5 -oneX-col-lg-5 -oneX-col-md-5 -oneX-col-sm-5">How it works</div>
      <div>
        <div class="flex-down">
          <div>
            <div class ="how-content-1 -oneX-body--primary -oneX-col-xl-2 -oneX-col-lg-2 -oneX-col-md-7 -oneX-col-sm-7"><span class="section-subtitle">01
            </span><div><br class="desktop"><br class="desktop">Search for and confirm <br class="desktop">shop online.</div>
            </div>
          </div>
          <div>
            <div class ="how-content-2 -oneX-body--primary -oneX-col-xl-2 -oneX-col-lg-2 -oneX-col-md-7 -oneX-col-sm-7"><span class="section-subtitle">02
            </span><div><br class="desktop"><br class="desktop">Schedule an {{(complianceVerbiage$ | async)}} <br class="desktop">with your shop.</div>
            </div>
          </div>
          <div>
            <div class ="how-content-3 -oneX-body--primary -oneX-col-xl-2 -oneX-col-lg-2 -oneX-col-md-7 -oneX-col-sm-7"><span class="section-subtitle">03
            </span><div><br class="desktop"><br class="desktop">Approve the {{(complianceVerbiage$ | async)}} <br class="desktop">and authorize repairs.</div>
            </div>
          </div>
          <div>
            <div class ="how-content-4 -oneX-body--primary -oneX-col-xl-2 -oneX-col-lg-2 -oneX-col-md-7 -oneX-col-sm-7"><span class="section-subtitle">04
            </span><div><br class="desktop"><br class="desktop">Pay your deductible to <br class="desktop">the shop (if applicable).</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Select Service benefits section title / section content-->
    <div id="select-service" class="select-service-container -oneX-container">
      <div role="heading" aria-level="2" class="select-section-title -oneX-heading--h3 -oneX-col-xl-5 -oneX-col-lg-5 -oneX-col-md-8 -oneX-col-sm-8">Why Select Service?</div>
      <div class="flex-section -oneX-body--primary">
        <div class="select-service-horizontal-padding -oneX-col-xl-3 -oneX-col-lg-3 -oneX-col-md-5 -oneX-col-sm-6">
          <div class="before-text -oneX-icon-container">
            <div class="icon-dimensions -oneX-pictogram" data-pictogram="get-a-quote"> </div>
          </div>
          <div class="select-service-content1">Hassle-free {{(complianceVerbiage$ | async)}}—no <br class="tablet"><br class="desktop">further approval needed.
          </div>
        </div>
        <div class="select-service-horizontal-padding -oneX-col-xl-3 -oneX-col-lg-3 -oneX-col-md-4 -oneX-col-sm-6">
          <div class="before-text -oneX-icon-container">
            <div class="icon-dimensions -oneX-pictogram" data-pictogram="suv"> </div>
          </div>
          <div class="select-service-content2"><span class="align-select-service-text3">Vehicle pick up/delivery <br class="tablet">upon request.</span>
          </div>
        </div>
        <div class="select-service-horizontal-padding -oneX-col-xl-3 -oneX-col-lg-3 -oneX-col-md-5 -oneX-col-sm-6">
          <div class="before-text -oneX-icon-container">
            <div class="icon-dimensions -oneX-pictogram" data-pictogram="calendar"> </div>
          </div>
          <div class="select-service-content3"><span class="align-select-service-text1">Guaranteed completion <br class="tablet"><br class="desktop">date.</span>
          </div>
        </div>
        <div class="select-service-horizontal-padding -oneX-col-xl-3 -oneX-col-lg-3 -oneX-col-md-5 -oneX-col-sm-6">
          <div class="before-text -oneX-icon-container">
            <div class="icon-dimensions -oneX-pictogram" data-pictogram="education-savings"> </div>
          </div>
          <div class="select-service-content4"><span class="align-select-service-text2">Limited lifetime warranty <br class="tablet"><br class="desktop">from the shop.</span>
          </div>
        </div>
        <div class="select-service-horizontal-padding -oneX-col-xl-3 -oneX-col-lg-3 -oneX-col-md-4 -oneX-col-sm-6">
          <div class="before-text -oneX-icon-container">
            <div class="icon-dimensions -oneX-pictogram" data-pictogram="funds-transfer"> </div>
          </div>
          <div class="select-service-content5"><span class="align-select-service-text1">We pay the shop directly <br class="tablet">for repairs.</span>
          </div>
        </div>
      </div>
    </div>

    <!--Help Topics section title / section content-->
    <div id="help-topics" class="help-topics-container -oneX-container">
      <div role="heading" aria-level="2" class="help-section-title -oneX-heading--h3 -oneX-col-xl-5 -oneX-col-lg-5 -oneX-col-md-8 -oneX-col-sm-8">Need help?</div>
      <div>
        <div class="help-topics-content">
          <div>
            <div>
              <p data-onex-opensdrawer="help-drawer" class="help-links -oneX-link--inline">
                <a id="btn-preferred-shop"
                  class=" -oneX-link--inline clickable"
                  data-oneX-opensDrawer="help-drawer"
                  onclick="oneX.Drawer.showDrawer(this.id)"
                  tabIndex="0"
                  role="link">What if my preferred shop is not listed?</a>
              </p>
            </div>
            <div>
              <p data-onex-opensdrawer="help-drawer" class="help-links -oneX-link--inline" >
                <a id="btn-repair-process"
                  class="-oneX-link--inline clickable"
                  data-oneX-opensDrawer="help-drawer"
                  onclick="oneX.Drawer.showDrawer(this.id)"
                  (click)="scrollToHelpTopic('repair-process')"
                  tabIndex="0"
                  role="link">What's the repair process?</a>
              </p>
            </div>
            <div>
              <p data-onex-opensdrawer="help-drawer" class="help-links -oneX-link--inline" >
                <a id="btn-repairs-now"
                  class="-oneX-link--inline clickable"
                  data-oneX-opensDrawer="help-drawer"
                  onclick="oneX.Drawer.showDrawer(this.id)"
                  (click)="scrollToHelpTopic('repairs-now')"
                  tabIndex="0"
                  role="link">What if I don't need repairs right now?</a>
              </p>
            </div>
          </div>

          <div class="-oneX-offset-xl-1 -oneX-offset-lg-1">
            <div>
              <p data-onex-opensdrawer="help-drawer" class="help-links -oneX-link--inline" >
                <a id="btn-selected-shop"
                  class="-oneX-link--inline clickable"
                  data-oneX-opensDrawer="help-drawer"
                  onclick="oneX.Drawer.showDrawer(this.id)"
                  (click)="scrollToHelpTopic('selected-shop')"
                  tabIndex="0"
                  role="link">How can I change my selected shop?</a>
              </p>
            </div>
            <div>
              <p data-onex-opensdrawer="help-drawer" class="help-links -oneX-link--inline">
                <a id="btn-repair-costs"
                  class="-oneX-link--inline clickable"
                  data-oneX-opensDrawer="help-drawer"
                  onclick="oneX.Drawer.showDrawer(this.id)"
                  (click)="scrollToHelpTopic('repair-costs')"
                  tabIndex="0"
                  role="link">What are my repair costs?</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Back to top arrow -->
    <div id="adsp" class="arrow-padding -oneX-col-xl-2 -oneX-col-lg-2 -oneX-offset-xl-8 -oneX-offset-lg-10 -oneX-col-md-3 -oneX-col-sm-3 -oneX-offset-md-9 -oneX-offset-sm-9">
      <button class="text-btn" (click) ="mainTop()">Back to top</button>
      <div class="-oneX-icon-container">
        <div class="icon-arrow -oneX-icon" (click) ="mainTop()"> </div>
      </div>
    </div>
  </div>
</div>

<!-- ADSP Legal Language -->
<div *ngIf="this.adspLanguage$ | async" class="adsp-language -oneX-body--footnote -oneX-col-xl-12 -oneX-col-lg-12 -oneX-col-md-12 -oneX-col-sm-12">
  <span>
    <span class="one-down" (click)= "mainTop()">1</span>{{ this.adspLanguage$ | async }}
  </span>
</div>

<section id="help-drawer" class=" -oneX-drawer-container">

  <div class="help-panel">
    <hr class="-oneX-hr"/>
    <div class="-oneX-panel--expansion">
      <div class="-oneX-panel-control">
        <button type="button" aria-expanded="false" aria-controls="detailsDiv" class="-oneX-panel-button ">Help Topics</button>
        <span class="collapse-button -oneX-panel__chevron-down"></span>
      </div>
      <div id="detailsDiv" tabindex="0" class="-oneX-panel-content -oneX-hidden">
        <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('preferred-shop')">What if my preferred shop is not listed?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('repair-process')">What’s the repair process?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('repairs-now')">What if I don’t need repairs right now?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('selected-shop')">How can I change my selected shop?</a></p>
        <p><a class="-oneX-link--inline help-topics-panel" (click)="scrollToHelpTopic('repair-costs')">What are my repair costs?</a></p>
      </div>
    </div>
    <hr class="-oneX-hr"/>
  </div>

  <div role="dialog" aria-labelledby="dialogLnkHdr">

    <div id="dialogLnkHdrPreferredShop" class="drawer-headings -oneX-header--h3">
      <a id="preferred-shop">What if my preferred shop is not listed?</a>
    </div>
    <div class="-oneX-body--primary help-drawer-body">
      Our Select Service program offers you many exclusive conveniences—plus, the peace of mind of a warranty provided by the repairer. You may select any repair facility that meets your needs. If you prefer to work with a non-participating shop, please contact your claim handler to set up an {{(complianceVerbiage$ | async)}}. Not all repair facilities charge the same amount for vehicle repairs. You may be responsible for any amounts not agreed to by State Farm. This may include, but may not be limited to, storage fees, and rental expenses.    </div>
    <div id="dialogLnkHdrRepairProcess" class="drawer-headings -oneX-header--h3 repair-process-text">
      <a id="repair-process">What’s the repair process?</a>
    </div>
    <div class="-oneX-body--primary repair-process-list">
      <ol class="help-drawer-body">
        <li><p class="-oneX-body--primary">Choose a repair shop online and submit a request for an {{complianceVerbiage$ | async}}. Contact your State Farm handler if you prefer to work with a shop that’s not part of the Select Service program.</p></li>
        <li><p class="-oneX-body--primary">Schedule an {{complianceVerbiage$ | async}} with the repair shop and/or schedule to have your non-drivable vehicle transported to the shop. </p></li>
        <li><p class="-oneX-body--primary">Approve the {{complianceVerbiage$ | async}} and authorize repairs.</p></li>
        <li><p class="-oneX-body--primary">You can pay your deductible, if applicable, to the repair facility when repairs are complete. </p></li>
      </ol>

    </div>
    <div id="dialogLnkHdrRepairsNow"  class="drawer-headings -oneX-header--h3">
      <a id="repairs-now">What if I don’t need repairs right now?</a>
    </div>
    <div class="-oneX-body--primary help-drawer-body">
      You may receive reminders about scheduling repairs, but don’t worry—you can schedule them when it’s convenient for you.
    </div>
    <div id="dialogLnkHdr selected-shop" class="drawer-headings -oneX-header--h3">
      <a id="selected-shop">How can I change my selected shop?</a>
    </div>
    <div class="-oneX-body--primary help-drawer-body">
      If you need to change your selected shop, you can change it online, contact your claim handler, or use the online chat feature.
    </div>
    <div id="dialogLnkHdrRepairCosts"  class="drawer-headings -oneX-header--h3">
      <a id="repair-costs">What are my repair costs?</a>
    </div>
    <div class="-oneX-body--primary repair-shop-drawer-body">
      After confirming your repairer, the Select Service shop will provide you with an {{complianceVerbiage$ | async}} for the claim-related damages. Keep in mind that if they identify additional damage during the repair, the shop will bill us directly for the approved repair {{(complianceVerbiage$ | async)}} balance. You will pay your deductible to the shop, if applicable, when repairs are complete.
    </div>
    <button onclick="oneX.Drawer.hideDrawer()" class="-oneX-close-X-button" aria-label="Close Drawer">
      <span class="-oneX-icon--close help-close">
      </span>
    </button>
  </div>

</section>
