import { State } from '../model/directive-state.model';

export class DirectiveStateConstant {

   public static directive_states = [
        new State('AK', 'Alaska'),
        new State('AL', 'Alabama'),
        new State('AZ', 'Arizona'),
        new State('DC', 'District of Columbia'),
        new State('FL', 'Florida'),
        new State('ID', 'Idaho'),
        new State('IL', 'Illinois'),
        new State('IN', 'Indiana'),
        new State('KS', 'Kansas'),
        new State('KY', 'Kentucky'),
        new State('MO', 'Missouri'),
        new State('NE', 'Nebraska'),
        new State('NM', 'New Mexico'),
        new State('NV', 'Nevada'),
        new State('OH', 'Ohio'),
        new State('SC', 'South Carolina'),
        new State('SD', 'South Dakota'),
        new State('TN', 'Tennessee'),
        new State('UT', 'Utah'),
        new State('VA', 'Virginia'),
        new State('WA', 'Washington'),
        new State('WY', 'Wyoming'),
     ];
}
