import { trigger, transition, animate, style, state } from '@angular/animations';

export const shopHoursToggle =
trigger('shopHoursToggle', [
  state('open', style({
    transform: 'rotate(180deg)',
  })),
  state('closed', style({
    transform: 'rotate(0deg)',
  })),
  transition('open => closed', [animate('0.2s linear')]),
  transition('closed => open', [animate('0.2s linear')]),
  transition('* => closed', [animate('0.2s linear')]),
  transition('* => open', [animate('0.2s linear')]),
]);

export const shopHours =
trigger('shopHours', [
  state('open', style({
    transform: 'translateY(0%)',
    height: 'auto',
    opacity: 1,
    display: 'flex',
  })),
  state('closed', style({
    transform: 'translateY(0%)',
    height: 0,
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('0.2s ease-out')]),
  transition('* => closed', [animate('0.2s ease-out')]),
]);
