export class StateCode {
  public static AL = '01';
  public static AK = '02';
  public static AZ = '03';
  public static AR = '04';
  public static CA_NC = '05';
  public static CO = '06';
  public static CT = '07';
  public static DE = '08';
  public static DC = '09';
  public static GA = '11';
  public static ID = '12';
  public static IL = '13';
  public static IN = '14';
  public static IA = '15';
  public static KS = '16';
  public static KY = '17';
  public static LA = '18';
  public static ME = '19';
  public static MD = '20';
  public static MA = '21';
  public static MI = '22';
  public static MN = '23';
  public static MS = '24';
  public static MO = '25';
  public static MT = '26';
  public static NE = '27';
  public static NV = '28';
  public static NJ = '30';
  public static NM = '31';
  public static NY_1 = '32';
  public static NC = '33';
  public static ND = '34';
  public static OH = '35';
  public static OK = '36';
  public static OR = '37';
  public static PA = '38';
  public static RI = '39';
  public static SC = '40';
  public static SD = '41';
  public static TN = '42';
  public static TX_N = '43';
  public static UT = '44';
  public static VT = '45';
  public static VA = '46';
  public static WA = '47';
  public static WV = '48';
  public static WI = '49';
  public static WY = '50';
  public static HI = '51';
  public static NY_2 = '52';
  public static TX_S = '53';
  public static CA_G = '55';
  public static FL = '59';
  public static CA_SC = '75';
  private static PCA_NOT_OFFERED_STATES = [
    StateCode.NC,
    StateCode.SC,
    StateCode.MA,
    StateCode.RI,
    StateCode.CT,
  ];
  private static ADSP_CATEGORY_2 = [
    StateCode.CA_SC,
    StateCode.CA_G,
    StateCode.CA_NC,
    StateCode.NY_1,
    StateCode.NY_2,
    StateCode.NJ,
  ];

  private static STATE_CODE_MAP: Map<string, string> = new Map<string, string>([
    ['01', 'AL'],
    ['02', 'AK'],
    ['03', 'AZ'],
    ['04', 'AR'],
    ['05', 'CA_NC'],
    ['06', 'CO'],
    ['07', 'CT'],
    ['08', 'DE'],
    ['09', 'DC'],
    ['11', 'GA'],
    ['12', 'ID'],
    ['13', 'IL'],
    ['14', 'IN'],
    ['15', 'IA'],
    ['16', 'KS'],
    ['17', 'KY'],
    ['18', 'LA'],
    ['19', 'ME'],
    ['20', 'MD'],
    ['21', 'MA'],
    ['22', 'MI'],
    ['23', 'MN'],
    ['24', 'MS'],
    ['25', 'MO'],
    ['26', 'MT'],
    ['27', 'NE'],
    ['28', 'NV'],
    ['30', 'NJ'],
    ['31', 'NM'],
    ['32', 'NY_1'],
    ['33', 'NC'],
    ['34', 'ND'],
    ['35', 'OH'],
    ['36', 'OK'],
    ['37', 'OR'],
    ['38', 'PA'],
    ['39', 'RI'],
    ['40', 'SC'],
    ['41', 'SD'],
    ['42', 'TN'],
    ['43', 'TX_N'],
    ['44', 'UT'],
    ['45', 'VT'],
    ['46', 'VA'],
    ['47', 'WA'],
    ['48', 'WV'],
    ['49', 'WI'],
    ['50', 'WY'],
    ['51', 'HI'],
    ['52', 'NY_2'],
    ['53', 'TX_S'],
    ['55', 'CA_G'],
    ['59', 'FL'],
    ['75', 'CA_SC'],
  ]);

  public static getAdspContentCodeFromStateCode(stateCode: string): number {
    let code = 0;
    if (stateCode === StateCode.OR) {
      code = 1;
    } else if (StateCode.ADSP_CATEGORY_2.indexOf(stateCode) !== -1) {
      // stateCode === StateCode.CA_SC || stateCode === StateCode.CA_G || stateCode === StateCode.CA_NC ||
      //             stateCode === StateCode.NY_1 || stateCode === StateCode.NY_2 || stateCode === StateCode.NJ) {
      code = 2;
    } else if (stateCode === StateCode.MN) {
      code = 3;
    } else if (stateCode !== undefined) {
      code = 4;
    }
    return code;
  }

  public static getStateNameFromStateCode(stateCode: string): string {
    return StateCode.STATE_CODE_MAP.get(stateCode);
  }

  // public static isPCAOfferedForStateCode(stateCode: string): boolean {
  //     return StateCode.PCA_NOT_OFFERED_STATES.indexOf(stateCode) === -1;
  // }
}
