<div class="-oneX-container -oneX center">
  <div class="-oneX-row-fluid">
    <div class="title-container -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-3 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <div tabindex="0" class="-oneX-icon-container">
        <div class="-oneX-icon icon-error" data-icon="warning_fill_32"></div>
      </div>
      <div class="icon-padding -oneX-heading--h1">There’s Still a Problem</div>
    </div>
  </div>
  <div class="-oneX-row-fluid">
    <div class="error-content-container -oneX-col-xs-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-3 -oneX-col-xl-6">
      <div class="error-content -oneX-body--intro">
          We’re unable to process your rental request at this time. Contact your claim handler for assistance.
      </div>
      <div class="return-btn">
        <button class="-oneX-btn-primary clickable"
                (click)="redirect()"
                (keydown.enter)="redirect()">
                Return to claims details
        </button>
      </div>
    </div>
  </div>
</div>
