import { trigger, transition, animate, style, state, keyframes } from '@angular/animations';

export const searchRadius =
trigger('searchRadius', [
  state('open', style({
    transform: 'translateY(0%)',
    height: '145px',
    opacity: 1,
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #CCCCCC'
  })),
  state('closed', style({
    transform: 'translateY(0%)',
    height: 0,
    opacity: 0,
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #CCCCCC'
  })),
  transition('open => closed', [animate('.2s ease-out')]),
  transition('closed => open', [animate('.2s ease-in')]),
  transition('* => open', [animate('.2s ease-in')]),
  transition('open <=> closed', [animate('.2s ease-in-out')]),
  transition('* => open', [
    animate ('.2s ease-in',
      style ({ opacity: '*' }),
    ),
  ]),
  transition('* => *', [
    animate('.2s')
  ]),
]);

/*animation filter to open and closed sort by Featured/Distance*/
export const filter =
  trigger('filter', [
    state('open', style({
      transform: 'translateY(0%)',
      height: '83px',
      opacity: 1,
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #CCCCCC',
      display: 'block'
    })),
    state('closed', style({
      transform: 'translateY(0%)',
      height: 0,
      opacity: 0,
      backgroundColor: '#FFFFFF',
      borderBottom: '0px solid #CCCCCC',
      display: 'none'
    })),
    transition('open => closed', [animate('.2s ease-out')]),
    transition('closed => open', [animate('.2s ease-in')]),
    transition('* => open', [animate('.2s ease-in')]),
    transition('open <=> closed', [animate('.2s ease-in-out')]),
    transition('* => open', [
      animate ('.2s ease-in',
        style ({ opacity: '*' }),
      ),
    ]),
    transition('* => *', [
      animate('.2s')
    ]),
  ]);

export const shops =
trigger('shops', [
  state('mobile_portrait_splitscreen', style({
    transform: 'translateY(0)',
    width: 'auto',
    height: 'calc(60vh - (64px / 2))',
    opacity: 1,
    // border: '10px solid red'
  })),
  state('mobile_portrait_mapintermediate', style({
    transform: 'translateY(0%)',
    width: 'auto',
    height: 'calc(60vh - (64px / 2))',
    opacity: 1,
  })),
  state('mobile_portrait_maponly', style({
    overflow: 'hidden',
    height: '15px',
    paddingBottom: '28px',
    width: '100vw',
    opacity: 1,
  })),
  state('mobile_portrait_listonly', style({
    paddingTop: 'calc(10vh)',
    top: '126px',
    width: 'calc(100vw)',
    height: 'calc(92vh)',
    opacity: 1,
  })),
  state('mobile_landscape_splitscreen', style({
    transform: 'translateX(0)',
    width: '100vw',
    opacity: 1,
  })),
  state('mobile_landscape_maponly', style({
    transform: 'translateX(-100%)',
    width: '100vw',
    opacity: 1,
  })),
  state('desktop_splitscreen', style({
    transform: 'translateX(0)',
    width: '375px',
    opacity: 1,
  })),
  state('desktop_maponly', style({
    transform: 'translateX(-100%)',
    width: '375px',
    opacity: 1,
  })),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_mapintermediate', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_mapintermediate <=> mobile_portrait_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_listonly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_landscape_splitscreen => mobile_landscape_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_landscape_maponly => mobile_landscape_splitscreen', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('desktop_splitscreen => desktop_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
  transition('desktop_maponly => desktop_splitscreen', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
]);

export const map =
trigger('map', [
  state('mobile_portrait_splitscreen', style({
    paddingLeft: '0',
    paddingBottom: '0',
    height: 'calc(40vh - (64px / 2))',
    // border: '10px solid orange',
  })),
  state('mobile_portrait_mapintermediate', style({
    paddingLeft: '0',
    paddingBottom: '0',
    height: 'calc(100vh - 64px)',
  })),
  state('mobile_portrait_maponly', style({
    paddingLeft: '0',
    paddingBottom: '0',
    height: 'calc(100vh - 64px - 30px)',
  })),
  state('mobile_portrait_listonly', style({
    transform: 'translateY(-100%)',
    paddingLeft: '0',
    paddingBottom: '0',
  })),
  state('mobile_landscape_splitscreen', style({
    paddingLeft: '350px',
    paddingBottom: '0',
    width: '100%',
    height: 'calc(100vh)',
  })),
  state('mobile_landscape_maponly', style({
    paddingLeft: '0',
    paddingBottom: '0',
    width: '100%',
  })),
  state('desktop_splitscreen', style({
    paddingLeft: '375px',
    paddingBottom: '0',
    width: '100%',
  })),
  state('desktop_maponly', style({
    paddingLeft: '0',
    paddingBottom: '0',
    width: '100%',
  })),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_mapintermediate', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_mapintermediate <=> mobile_portrait_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_portrait_splitscreen <=> mobile_portrait_listonly', [animate('.2s cubic-bezier(.25, .46, .45, .94)' )]),
  transition('mobile_landscape_splitscreen => mobile_landscape_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
  transition('mobile_landscape_maponly => mobile_landscape_splitscreen', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
  transition('desktop_splitscreen => desktop_maponly', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
  transition('desktop_maponly => desktop_splitscreen', [animate('.2s cubic-bezier(.25, .46, .45, .94)')]),
]);
