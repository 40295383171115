import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Service to toggle footer dynamically */
@Injectable()
export class FooterDisplayService {
  footerDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  changeFooterDisplay(display: boolean) {
    this.footerDisplay$.next(display);
  }
}
