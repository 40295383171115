
<div id="sticky-footer-container">
  <app-one-x-header-full *ngIf="!isHeaderLimited"></app-one-x-header-full>
  <app-one-x-header-limited *ngIf="isHeaderLimited"></app-one-x-header-limited>
  <div class="container" id="main-container">
    <div id="enableGlobalChatId" [hidden]="isHidden">{{ (featureFlags$ | async)?.click_to_chat_enabled }}</div>
    <div id="enableConfirmationChatId" [hidden]="isHidden">{{ (featureFlags$ | async)?.click_to_chat_confirmation }}</div>
    <div id="chatButtonScriptSrc" [hidden]="isHidden">{{ chatButtonScriptSrc }}</div>
    <div id="claimNum" [hidden]="isHidden">{{ claimNumber$ | async }}</div>
    <router-outlet></router-outlet>
  </div>
  <app-one-x-footer *ngIf="isLimited" [limited]="true" [hidden]="!(footerDisplayService.footerDisplay$ | async)" id="sticky-footer"></app-one-x-footer>
  <app-one-x-footer *ngIf="!isLimited" [limited]="false" [hidden]="!(footerDisplayService.footerDisplay$ | async)" id="sticky-footer"></app-one-x-footer>
  <app-privacy-banner></app-privacy-banner>
</div>


<!-- Drawer for Claims Help -->
<app-help-drawer [drawerid]="'claims-help-drawer'" [isClaimsHelp]="true" (drawerClose)="closeHelpDrawer()">
  <router-outlet name="help"></router-outlet>
</app-help-drawer>


<app-modal #sessionExpired>
  <div class="-oneX modal-body center">
    <h2 class="session-title -oneX-heading--h2" aria-level="2">Are you still there?</h2>
    <div class="-oneX-modal-body">
      Your session will expire in <span class="-oneX-font--bold">{{ count }}</span> seconds due to inactivity. Any information you've entered will not be saved.
    <h5 class="session-secondary-title">Would you like to continue or end your session?</h5>
    </div>
    <div class="session-container -oneX-row">
      <button (click)="endSession()" class="-oneX-btn-secondary session-btn">End Session</button>
      <button (click)="continueSession()" class="-oneX-btn-primary session-btn">Continue</button>
    </div>
  </div>
</app-modal>
