import { Component, Inject, NgZone, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ModalComponent } from 'app/modal/modal.component';
import { Router } from '@angular/router';
import { BaseComponent } from 'app/base/base.component';
import { first } from 'rxjs/operators';

// SERVICES
import { DataAnalyticsService } from '../service/data-analytics.service';
import { DeviceService } from '../service/device.service';
import { SearchResultsService } from '../service/search-results.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { SFLoggerService } from 'app/service/sf-logger.service';
import { StorageService } from 'app/service/storage.service';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { WINDOW } from '../service/window.service';

// MODELS 
import { PhotoEstimateEligibility } from 'app/model/photo-estimate.model';
import { WindowReference } from 'app/model/window-reference.model';
import { UrlParams } from 'app/model/url-params.model';

// ENUMS
import { SFLoggerFields } from 'app/enums/sf-logger-fields';
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';

@Component({
  selector: 'app-estimate-options',
  templateUrl: './estimate-options.component.html',
  styleUrls: ['./estimate-options.component.css']
})
export class EstimateOptionsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('EstimateAssistDisclaimer', {static: false}) estimateAssistDisclaimer: ModalComponent;
  currentPage = 'estimate-option-damage-question';
  fileName = 'estimate-options.component.ts';
  isDeviceMobile: boolean;
  isViewStatic: boolean;
  complianceVerbiage$: Observable<string>;
  urlParams: UrlParams;
  role: string;
  oneX: any;
  disableButton = false;
  searchTerm: string;
  isDrawerOpen: boolean;
  damageQuestionsScreen: boolean;
  minorDamageScreen: boolean;
  majorDamageScreen: boolean;
  inLocationText$: Observable<string>;
  learnMoreText: string;
  loggingDetails: any;
  photoEstimateEligibility: PhotoEstimateEligibility;

  constructor(
    @Inject (WINDOW) private window: Window,
    __zone: NgZone,
    private deviceService: DeviceService,
    private dataAnalyticsService: DataAnalyticsService,
    clientService: ServiceClientsService,
    router: Router,
    windowRef: WindowReference,
    sfLoggerService: SFLoggerService,
    storageService: StorageService,
    urlResolver: UrlResolverService,
    searchResultsService: SearchResultsService,
    ) {
    super(
      __zone,
      router,
      urlResolver,
      clientService,
      searchResultsService,
      windowRef,
      storageService,
      sfLoggerService,
    );

    this.dataAnalyticsService.sendPageData(this.currentPage);
    this.oneX = window['oneX'];

    if (this.storageService.getSessionStorage('eaRetryCount') === null) {
      this.storageService.setSessionStorage('eaRetryCount', '0');
    }

    this.loggingDetails = [
      SFLoggerFields.DEV_REPORTING_FILE, this.fileName
    ]
  }

  ngOnInit() {
    this.externalClaimId = this.urlResolver.getClaimId();
    this.externalClientId = this.urlResolver.getClientId();
    this.role = this.urlResolver.getRole();
    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);
    this.complianceVerbiage$ = this.clientService.getComplianceVerbiage();
    this.photoEstimateEligibility = this.clientService.getPhotoEstimateEligibility();

    // It reset view default to RA, so it could return to map result page if needed; without it, search would stick on estimate options page
    this.searchResultsService.resetChangeView();
    this.role = this.urlResolver.getRole();
    this.getSearchTerm();
    this.damageQuestionsScreen = true;
  }

  ngAfterViewInit() {
    this.oneX.Help.init();
    this.oneX.Icons.init();
  }

  setAnalyticsScreenId(screenId: string) {
    this.dataAnalyticsService.sendPageData(screenId);
    this.isDrawerOpen = true;
  }

  setBackScreenId() {
    this.dataAnalyticsService.sendPageData('estimate-option-pe');
    this.isDrawerOpen = false;
  }

  showEstimateAssistDisclaimer() {
    this.dataAnalyticsService.sendPageData('estimate-assist-disclaimer');
    this.estimateAssistDisclaimer.showAlternateModal('ea-disclaimer-modal');
  }

  changeScreen(ans: String) {
    // If user searches by city,state, both isCityStateSearch and isZipCodeSearch are true
    if (this.searchResultsService.getProviderRequest().isCityStateSearch && 
        this.searchResultsService.getProviderRequest() !== undefined &&
        this.searchResultsService.getProviderRequest().city !== undefined && 
        this.searchResultsService.getProviderRequest().state !== undefined) {
      this.inLocationText$ = of(" in " + this.searchResultsService.getProviderRequest().city + ", " + this.searchResultsService.getProviderRequest().state)
    } else if (this.searchResultsService.getProviderRequest().isZipCodeSearch && 
               this.searchResultsService.getSearchResultsResponse() !== undefined &&
               this.searchResultsService.getSearchResultsResponse().zipCode !== undefined) {
      this.inLocationText$ = of(" in " + this.searchResultsService.getSearchResultsResponse().zipCode);
    } else {
      this.inLocationText$ = of("");
    }

    if (ans === 'yes') {
      this.damageQuestionsScreen = false;
      this.minorDamageScreen = false;
      this.majorDamageScreen = true;
      this.learnMoreText = 'Learn more about Estimate Assist';
      this.dataAnalyticsService.sendPageData('estimate-assist-maj-damage')
    } else if (ans === 'no') {
      this.damageQuestionsScreen = false;
      this.minorDamageScreen = true;
      this.majorDamageScreen = false;
      this.learnMoreText = 'Learn more';
      this.dataAnalyticsService.sendPageData('estimate-option-minor-damage');
    }
  }

  routeToEstimateAssistMap() {
    this.increaseRetryCount();
    this.disableButton = true;
    this.searchResultsService.getEstimateAssistShops().pipe(
      first()
    )
    .subscribe(
      res => {
        this.storageService.setSessionStorage('isEstimateAssist', 'true');
        this.router.navigate(['repairshops'], {
          queryParams: {
            claim: this.urlResolver.getClaimId(),
            client: this.urlResolver.getClientId(),
            role: this.urlResolver.getRole(),
            lat: res.lat,
            lng: res.lng,
            term: this.urlResolver.getStringifiedProviderRequest(
              this.searchResultsService.getProviderRequest()
            ),
          },
        });
      },
      err => {
        this.disableButton = false;
        if (!this.isEaRetryAvailable()) {
          this.router.navigate(['autherrorpage']);
        }
      },
    );
  }

  routeToPhotoEstimate() {
    if (this.photoEstimateEligibility) {

      if (this.photoEstimateEligibility.eligible === true) {
        // New PE
        this.loggingDetails.push(SFLoggerFields.ROUTING_TO, 'New PE');
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_ROUTE_TO_PE, "Routing to NEW PE", this.loggingDetails);
        this.router.navigate([
          'photoestimate/claim/' + this.externalClaimId + '/role/' + this.role + '/client/' + this.externalClientId
        ]);
      } else {
        // Old PE
        this.loggingDetails.push(SFLoggerFields.ROUTING_TO, 'Old PE');
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_ROUTE_TO_PE, "Routing to OLD PE", this.loggingDetails);
        this.windowRef.navigateTo(
          this.urlResolver.getPELandingUrl(
            this.clientService.getVehicleNumber()
          )
        );
      }
    }
  }

  increaseRetryCount() {
    let retryCount = Number(this.storageService.getSessionStorage('eaRetryCount'));
    retryCount++;
    this.storageService.setSessionStorage('eaRetryCount', retryCount.toString());
  }

  isEaRetryAvailable(): boolean {
    if (Number(this.storageService.getSessionStorage('eaRetryCount')) < 3) {
      return true;
    }
    return false;
  }

  getSearchTerm() {
    const searchResultsFromSessionStorage = JSON.parse(this.storageService.getSessionStorage('searchResult'));
    if (searchResultsFromSessionStorage) {
      this.searchTerm = searchResultsFromSessionStorage.searchTerm;
    }
  }
}
