import { Component, HostListener, OnInit } from '@angular/core';
import { DataAnalyticsService } from 'app/service/data-analytics.service';
import { WindowReference } from 'app/model/window-reference.model';
import { DeviceService } from 'app/service/device.service';
import { InteractionsService } from '../service/interactions.service';

@Component({
  selector: 'app-auth-error-page',
  templateUrl: './auth-error-page.component.html',
  styleUrls: ['./auth-error-page.component.css'],
})
export class AuthErrorPageComponent implements OnInit {
  oneX: any;
  currentPage = 'auth-error-page';
  isDeviceMobile = false;

  constructor(
    private dataAnalyticsService: DataAnalyticsService,
    private windowRef: WindowReference,
    private deviceService: DeviceService,
    private interactionsService: InteractionsService,
  ) {
    this.oneX = this.windowRef.nativeWindow['oneX'];
  }

  ngOnInit(): void {
    this.oneX.Icons.init();
    this.isDeviceMobile = this.isMobile();
    this.dataAnalyticsService.sendPageData('ra-auth-error');
    this.interactionsService.sendInteractions('Repair Assistant technical error', 'Auto Repair');
  }

  redirectToSearchOnly() {
    this.windowRef.nativeWindow.open('/', '_self');
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDeviceMobile = this.isMobile();
  }

  isMobile() {
    return this.deviceService.isDeviceMobile(
      this.windowRef.nativeWindow.innerWidth,
      this.windowRef.nativeWindow.innerHeight
    );
  }
}
