import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { WINDOW } from 'app/service/window.service';
import { RentalService } from '../service/rental.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { InteractionsService } from '../service/interactions.service';

@Component({
  selector: 'app-error-page', // what should this be?
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit, AfterViewInit {
  currentPage = 'error-page';
  oneX: any;
  medallia: any;
  vendorType: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private urlResolverService: UrlResolverService,
    private dataAnalyticsService: DataAnalyticsService,
    private rentalService: RentalService,
    private interactionsService: InteractionsService,
  ) {
    this.oneX = window['oneX'];
    this.medallia = window['KAMPYLE_ONSITE_SDK'];
  }

  ngOnInit() {
    this.oneX.Icons.init();
    this.vendorType = this.rentalService.getVendorType();
    if (this.vendorType === 'Hertz') {
      this.dataAnalyticsService.sendPageData('ra-second-submit-error-hertz');
    } else {
      this.dataAnalyticsService.sendPageData('ra-second-submit-error-erac');
    }
    this.interactionsService.sendInteractions('Repair Assistant technical error', 'Auto Repair');
  }

  ngAfterViewInit() {
    // medallia survey support
    this.medallia.updatePageView();
  }

  redirect() {

    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }
}
