import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

// SERVICES 
import { PhotoEstimateService } from 'app/service/photo-estimate.service';
import { WINDOW } from '../service/window.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

@Component({
  selector: 'app-photo-estimate-confirmation',
  templateUrl: './photo-estimate-confirmation.component.html',
  styleUrls: ['./photo-estimate-confirmation.component.css']
})
export class PhotoEstimateConfirmationComponent implements AfterViewInit, OnInit, OnDestroy {
  contactPreference: string;
  
  constructor(
    @Inject(WINDOW) private window: Window,
    private photoEstimateService: PhotoEstimateService,
    private urlResolverService: UrlResolverService,
    private dataAnalyticsService: DataAnalyticsService
  ) {  }

  ngOnInit(): void {
    this.contactPreference = this.photoEstimateService.getContactPreference();
  }
  
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector(`#success`));
    this.dataAnalyticsService.sendPageData('photo-estimate-confirmation');
  }

  goToClaimOverview() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector(`#success`));
  }
}
