import { Component, Input } from '@angular/core';

// SERVICES
import { SFLoggerService } from 'app/service/sf-logger.service';

// MODELS
import { PlacesDetails } from '../model/places-details.model';

// ENUMS 
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';

@Component({
  selector: 'app-shop-star-rating',
  templateUrl: './shop-star-rating.component.html',
  styleUrls: ['./shop-star-rating.component.css'],
})
export class ShopStarRatingComponent {
  @Input() index: number;
  @Input() type: string;
  @Input() location: PlacesDetails;
  @Input() isSidebarVisabile: boolean;
  @Input() areReviewsClickable: boolean = true;

  constructor(private sfLoggerService: SFLoggerService) {}

  onReviewsClick() {
    // SPARKL Logging
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_GOOGLE_REVIEWS_CLICKED, 'Customer clicked Google reviews link');
  }

  getFormattedRating(rating: number): string {
    try {
      if (Number.isInteger(rating)) {
        return rating === undefined ? '' : ((rating / 100) * 5).toFixed(1);
      } else {
        return '';
      }
    } catch (error) {}
  }

  getRating() {
    try {
      return this.location.rating;
    } catch (error) {}
    return null;
  }

  getPlaceId() {
    try {
      return this.location.placeId;
    } catch (error) {}
    return null;
  }

  getUserRatings() {
    try {
      return this.location.user_ratings_total;
    } catch (error) {}
    return null;
  }
}
