import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-privacy-banner',
  templateUrl: './privacy-banner.component.html',
  styleUrls: ['./privacy-banner.component.css']
})
export class PrivacyBannerComponent implements OnInit {
  hideNotice: boolean;
  hideNoticeExpand: boolean;

  constructor(
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    const closing = this.cookieService.get('optCCPAClosedCookie');
    if (closing === 'yes') {
      this.hideNotice = true;
      this.hideNoticeExpand = true;
    } else {
      this.hideNotice = false;
      this.hideNoticeExpand = true;
    }
  }

  expand(): void {
    this.hideNoticeExpand = false;
    this.hideNotice = true;
  }

  collapse(): void {
    this.hideNotice = false;
    this.hideNoticeExpand = true;
  }

  close(): void {
    this.hideNotice = true;
    this.hideNoticeExpand = true;
    document.cookie = 'optCCPAClosedCookie=yes;;domain=.statefarm.com;path=/';
  }
}
