import { trigger, transition, animate, style, state } from '@angular/animations';

export const contactTrigger =
trigger('contactTrigger', [
  state('open', style({
    opacity: 1,
    display: 'block'
  })),
  state('closed', style({
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('0.2s ease-out')]),
  transition('closed => open', [animate('0.2s ease-in')]),
]);

export const errorTrigger =
trigger('errorTrigger', [
  state('open', style({
    opacity: 1,
    display: 'flex'
  })),
  state('closed', style({
    opacity: 0,
    display: 'none',
  })),
  transition('open => closed', [animate('0.5s ease-out')]),
  transition('closed => open', [animate('0.2s ease-in')]),
]);
