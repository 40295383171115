import { AfterViewChecked, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '../service/window.service';

@Component({
  selector: 'app-help-still-have-questions',
  templateUrl: './help-still-have-questions.component.html',
  styleUrls: ['./help-still-have-questions.component.css']
})
export class HelpStillHaveQuestionsComponent implements AfterViewChecked {
  @Input() faqPage;

  constructor(
    public router: Router,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngAfterViewChecked() {
    this.window['oneX'].addElement(
      document.querySelector(`#help-phone-icon-container`)
    );
    this.window['oneX'].addElement(
      document.querySelector(`#help-phone-icon-container-mobile`)
    );
  }
}
