<section class="-oneX-breadcrumbs help-breadcrumb-padding">
  <div class="help-breadcrumb-margins">
    <nav aria-label="breadcrumb">
      <ul itemscope="" itemtype="http://schema.org/BreadcrumbList">
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" [routerLink]="['/', { outlets: { help: ['sub-category', category.name] } }]"
            queryParamsHandling="merge" aria-label="back" class="help-large-breadcrumb-nowrap">
            <span class="-oneX-breadcrumbs-chevron" style="transform: scaleX(-1)"></span>
            <span itemprop="name">
              {{ displayBreadCrumbsTitle(category.name) | titlecase }}
            </span>
            <meta itemprop="position" content="0">
          </a>
        </li>
        <!-- The following "empty" <li> is needed so the breadcrumbs do not disappear in mobile view -->
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" [routerLink]="['/', { outlets: { help: ['sub-category', category.name] } }]"
            queryParamsHandling="merge" aria-label="back" tabindex="-1">
            <span itemprop="name">
            </span>
            <meta itemprop="position" content="1">
          </a>
        </li>
      </ul>
    </nav>
  </div>
</section>

<div>
  <div class="-oneX-heading--h3 help-question-title">
    {{ faq.question }}
  </div>
  <div class="-oneX-body--primary faq-question-padding" [innerHTML]="setRepairUrl(faq)"></div>
</div>

<div class="help-page-helpful-borders">
  <div>
    <div class="help-faq-padding">
      <div class="was-this-page-helpful -oneX-heading--h4">
        Was this page helpful?
        <div class="faq-yes-no -oneX-row" *ngIf="!isResponseSent">
          <div>
            <input
            class="-oneX-btn-auto-advance-selector faq-yes-no-buttons clickable"
            (click)="isResponseSent = true"
            id="help-analytics-yes"
            aria-label="was this page helpful yes"
            value="Yes">
          </div>
          <div class="faq-button-padding-left">
            <input
            class="-oneX-btn-auto-advance-selector faq-yes-no-buttons clickable"
            (click)="isResponseSent = true"
            id="help-analytics-no"
            aria-label="was this page helpful no"
            value="No">
          </div>
        </div>
      </div>
      <div class="-oneX-body--primary thanks-for-your-resp" *ngIf="isResponseSent">
        Thank you for your response.
      </div>
    </div>
  </div>
</div>

<div class="help-drawer-padding still-have-questions-text">
  <app-help-still-have-questions [faqPage]="faqPage"></app-help-still-have-questions>
</div>
