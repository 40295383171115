<div class="card-skeleton">
  <div class="animated-background">
    <div class="skeleton-mask-container">
      <div class="skeleton-mask skeleton-mask-1"></div>
      <div class="skeleton-mask skeleton-mask-2"></div>
      <div class="skeleton-mask skeleton-mask-3"></div>
      <div class="skeleton-mask skeleton-mask-4"></div>
      <div class="skeleton-mask skeleton-mask-5"></div>
    </div>
  </div>
</div>
