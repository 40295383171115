<!-- Back Button -->
<div class="-oneX-container">
    <div class="-oneX-row">
        <div class="-oneX-col-xs-12 -oneX-col-md-12 -oneX-col-lg-12 -oneX-col-xl-12">
            <!-- Back Button -->
            <div class="back-btn">
                <div id="go-back" class="-oneX-icon-container">
                    <div class="back-chevron -oneX-icon" data-icon="chevron_24"></div>
                </div>
                <a class="back-text -oneX-link--inline--variant -oneX-link-secondary"
                    (click)="back()"
                    (keydown.enter)="back()"
                    tabindex="0">Back
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Non Program Page -->
<div *ngIf="screen === 'non-program'" class="page -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Non Program Header & Subheading -->
            <h3 class="np-header -oneX-heading--h3 -oneX-font--semi">{{ shopName }} isn’t in our Select Service® network. That’s OK!</h3>
            <div class="-oneX-body--intro-md">If you want to use this shop for repairs, you’ll need to get an {{complianceVerbiage$ | async}} using one of our approved options.</div>

        </div>
    </div>
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-4 -oneX-col-xl-4">

            <!-- What Would You Link To Do -->
            <h4 class="np-selector-header -oneX-heading--h4 -oneX-font--semi">What would you like to do?</h4>

            <!-- Auto Advance Selectors -->
            <button class="np-selector -oneX-btn-auto-advance-selector-2-line" id="non-program-search-again-for-ss-shop" (click)="goToRepairLanding()">
                <div class="-oneX-font--semi">Search again for a Select Service shop</div>
                <div>Our network of shops can help make {{(complianceVerbiage$ | async)}}s, repairs and claim payments faster and easier.</div>
            </button>
            <button class="np-selector -oneX-btn-auto-advance-selector-2-line" id="non-program-continue-with-this-shop" (click)="goToDamageQuestion()">
                <div class="-oneX-font--semi">Continue with this shop</div>
                <div>Tell us about your vehicle damage and we'll help find the right {{(complianceVerbiage$ | async)}} option for you.</div>
            </button>

        </div>
    </div>
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Question -->
            <a id="non-program-out-of-pocket-link"
               class="-oneX-link--inline -oneX-link-primary" 
               data-onex-opensdrawer="out-of-pocket-drawer" 
               onclick="oneX.Drawer.showDrawer(this.id)" 
               tabindex="0">Will my shop choice cause any out of pocket costs?</a>

            <!-- Legal Disclaimer -->
            <div class="legal-footnote -oneX-body--footnote">You have the ability to select a repairer of your choice that meets your vehicle repair needs. Only you can authorize repairs to your vehicle. The selection of a Select Service repair shop is not a waiver of any coverage issue that may exist.</div>

        </div>
    </div>
</div>

<!-- Out of Pocket Costs Drawer -->
<section id="out-of-pocket-drawer" class="-oneX-drawer-container">
    <div role="dialog" aria-labelledby="dialogLnkHdr">
      <h3 class="-oneX-heading--h3 -oneX-font--bold -oneX-drawer-header">
        Will my shop choice cause any out of pocket costs?
      </h3>
      <div class="oop-text -oneX-body--primary -oneX-drawer-body">
        You are free to select repairers who do not have agreements with State Farm®. Not all repair facilities charge the same amount for vehicle repairs. You may be responsible for any amounts not agreed to by State Farm. This may include, but may not be limited to, storage fees, and rental expenses.
      </div>
      <button onclick="oneX.Drawer.hideDrawer()" class="-oneX-close-X-button" aria-label="Close Drawer">
        <span class="-oneX-icon--close"></span>
      </button>
    </div>
</section>

<!-- Damage Question -->
<div *ngIf="screen === 'damage-question'" class="page -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Damage Question Header -->
            <h3 class="damage-header -oneX-heading--h3 -oneX-font--semi">Does your vehicle have any of the damages below?</h3>

        </div>
    </div>
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-3 -oneX-col-md-6 -oneX-offset-lg-4 -oneX-col-lg-4 -oneX-offset-xl-4 -oneX-col-xl-4">

            <!-- Damages Bullets -->
            <ul class="damage-bullets">
                <li class="damage-bullet">Leaking fluid</li>
                <li class="damage-bullet">Damage under the vehicle</li>
                <li class="damage-bullet">Doors, hood or trunk no longer open and close freely</li>
            </ul>

            <!-- Auto Advance Selectors -->
            <div class="damage-selectors-container">
                <button class="damage-selector -oneX-btn-auto-advance-selector" id="damage-selector-yes" (click)="goToMajorDamage()">
                    Yes
                </button>
                <button class="damage-selector -oneX-btn-auto-advance-selector" id="damage-selector-no" (click)="goToPhotoEstimateLanding()">
                    No
                </button>
            </div>

        </div>
    </div>
</div>

<!-- Major Damage -->
<!-- [ngClass] used instead of *ngIf to retain 1x icon initialization -->
<div [ngClass]="{ 'hide-major-damage': screen !== 'major-damage' }" class="page -oneX-container">
    <div class="-oneX-row">
        <div class="center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">

            <!-- Major Damage Header & Subheading -->
            <h3 class="major-damage-header -oneX-heading--h3 -oneX-font--semi">Your damage is more than minor</h3>
            <div class="major-damage-sub -oneX-body--intro-md">To get an accurate {{(complianceVerbiage$ | async)}}, we recommend using one of the options below.</div>

            <hr class="-oneX-hr">

            <!-- Use A Select Service Shop -->
            <div class="ss-container">
                <div class="icon-header-container">
                    <div id="repair-tool" class="-oneX-icon-container">
                        <div class="repair-icon -oneX-icon" data-icon="repair_32"></div>
                    </div>
                    <div class="ss-header -oneX-heading--h4">Use a Select Service® shop</div>
                </div>
        
                <div class="get-benefits -oneX-heading--h6">Get benefits like:</div>
                <ul class="ss-details">
                    <li class="ss-detail">{{(complianceVerbiage$ | async | titlecase )}} and repairs in one location.</li>
                    <li class="ss-detail">An {{(complianceVerbiage$ | async)}} in less than 30 minutes.</li>
                    <li class="ss-detail">We pay the shop directly.</li>
                    <li class="ss-detail">We work with the repair shop directly if they find more damage.</li>
                    <li class="ss-detail">Nationally accepted, limited-lifetime warranties.</li>
                </ul>
            </div>
              
            <!-- Choose a shop -->
            <div class="ss-btn-container">
                <button id="choose-select-service" 
                        class="-oneX-btn-primary"
                        (click)="goToRepairLanding()">Choose a shop
                </button>
            </div>

        </div>
    </div>
</div>