import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { exchangeOauth2Tokens } from "customer-oauth2-token-management-utility";
import { environment } from 'environments/environment';

// SERVICES 
import { CookieService } from 'ngx-cookie-service';
import { InteractionsService } from './interactions.service';
import { SFLoggerService } from './sf-logger.service';
import { UrlResolverService } from 'app/service/url-resolver.service';

// MODELS
import { AuthJwtTokenResponse, AuthJwtVerifyResponse } from 'app/model/auth-token-response.model';
import { WindowReference } from 'app/model/window-reference.model';

// ENUMS
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';

@Injectable()
export class JWTTokenService {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private windowRef: WindowReference,
    private sfLoggerService: SFLoggerService,
    private urlResolverService: UrlResolverService,
    private interactionsService: InteractionsService,
  ) {
  }

  public getOktaJWT() {
    return this.cookieService.get('sf-cauth1') ? `Bearer ${this.cookieService.get('sf-cauth1')}` :
      this.cookieService.get('sf-cauth-lite') ? `Bearer ${this.cookieService.get('sf-cauth-lite')}` : '';
  }

  public isJWTTokenExist(): boolean {
    return this.cookieService.check('sf-cauth1') || this.cookieService.check('sf-cauth-lite');
  }

  public async refreshJWTtoken() {
    let extClaimId = this.urlResolverService.getClaimId();
    let extClientId = this.urlResolverService.getClientId();

    if ((this.cookieService.get('sf-cauth1') && this.cookieService.get('sf-cauth2'))) {
      let jwtToken = await exchangeOauth2Tokens();
      if (!jwtToken) {
        this.windowRef.navigateTo(this.urlResolverService.buildProofingRedirectUrl("logout"));
      }
    } else {
      if (this.cookieService.get('sf-cauth-lite')) {
        return this.getJwtToken(extClaimId, extClientId).subscribe(
          (authJwtTokenResponse) => {
            this.setJWTToken('sf-cauth-lite', authJwtTokenResponse['token'], extClaimId, extClientId);
          },
          catchError(error => {
            this.windowRef.navigateTo(this.urlResolverService.buildProofingRedirectUrl("logout"));
            return throwError(
              new Error(`Error Status is ${error.status.toString()}`)
            );
          }),
        );
      }
    }
  }

  public deleteJWTToken(externalClaimId = '', externalClientId = '') {
    this.cookieService.delete('sf-cauth1', '/', '.statefarm.com', true);
    this.cookieService.delete('sf-cauth2', '/', '.statefarm.com', true);
    this.cookieService.delete('sf-cauth-lite', '/', '.statefarm.com', true);
  }

  public getJwtToken(externalClaimId: string, externalClientId: string): Observable<AuthJwtTokenResponse> {
    const jwtLoa2TokenUrl = this.windowRef.nativeWindow._config['jwtLoa2'].url;

    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': 'sf-authentication-api-consumer-repair-assistant-ui',
        ...((!environment.production) && {'environment': 2})
      })
    };

    const url = `${jwtLoa2TokenUrl}/oauth2/token`.replace(/\s/g, '');

    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.AUTH_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.AUTH_TOKEN
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling Auth API - POST /token", loggingDetails);

    const body = {
      extClaimId: externalClaimId,
      extClientId: externalClientId
    };

    return this.http.post<AuthJwtTokenResponse>(url, body, httpOptions).pipe(
      map(response => {
        this.interactionsService.setLOA(response['achievedLoa']);

        // SPARKL Logging
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - Auth API - POST /token", loggingDetails)

        return response;
      }),
      catchError(error => {
        // SPARKL Logging
        loggingDetails.push(
          SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
          SFLoggerFields.ENDPOINT, error.url,
          SFLoggerFields.HTTP_RESPONSE, error.status.toString()
        )
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - Auth API - POST /token", loggingDetails);

        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      }),
    );
  }

  public setJWTToken(tokenType: string, token: string, externalClaimId = '', externalClientId = '') {
    this.cookieService.set(tokenType, token, undefined, '/', '.statefarm.com', true);
  }

  public getJwtVerify(token: string): Observable<AuthJwtVerifyResponse> {
    const jwtLoa2TokenUrl = this.windowRef.nativeWindow._config['jwtLoa2'].url;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'x-api-key': 'sf-authentication-api-consumer-repair-assistant-ui',
        ...((!environment.production) && {'environment': 2})
      }),
    };

    const url = `${jwtLoa2TokenUrl}/verifyJwt`;

    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.AUTH_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.AUTH_VERIFY
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling Auth API - GET /verifyJwt", loggingDetails);

    return this.http.get<AuthJwtVerifyResponse>(url, httpOptions).pipe(
      map(response => {
        // SPARKL Logging
        const loggingDetails = [
          SFLoggerFields.CALLED_SERVICE, SFLoggerFields.AUTH_API,
          SFLoggerFields.HTTP_REQUEST, SFLoggerFields.AUTH_VERIFY,
        ]
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - Auth API - GET /verifyJwt", loggingDetails)

        return response;
      }),
      catchError(error => {
        // SPARKL Logging
        loggingDetails.push(
          SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
          SFLoggerFields.ENDPOINT, error.url,
          SFLoggerFields.HTTP_RESPONSE, error.status.toString()
        )
        this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - Auth API - GET /verifyJwt", loggingDetails);

        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      }),
    );
  }
}
