import { AfterViewInit, Inject, Component, OnDestroy, OnInit } from '@angular/core';

// SERVICES 
import { WINDOW } from '../service/window.service';
import { DeviceService } from 'app/service/device.service';
import { PhotoEstimateService } from 'app/service/photo-estimate.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

@Component({
  selector: 'app-photo-estimate-error-page',
  templateUrl: './photo-estimate-error-page.component.html',
  styleUrls: ['./photo-estimate-error-page.component.css']
})
export class PhotoEstimateErrorPageComponent implements OnInit, AfterViewInit, OnDestroy {
  isDeviceMobile: boolean;
  peError: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private deviceService: DeviceService,
    private urlResolverService: UrlResolverService,
    private photoEstimateService: PhotoEstimateService,
    private dataAnalyticsService: DataAnalyticsService
  ) {  }

  ngOnInit(): void {
    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);
    this.peError = this.photoEstimateService.getPhotoEstimateError();
  }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector(`#error`));
    if(this.peError == 'assignment failed') {
      this.dataAnalyticsService.sendPageData('photo-estimate-second-error');
    } else if(this.peError === 'assignment exists'){
      this.dataAnalyticsService.sendPageData('photo-estimate-link-exists');
    }
  }

  goToClaimOverview() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector(`#error`));
  }

}
