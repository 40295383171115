import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DirectiveStateConstant } from '../constant/directive-state-constants';
import { ServiceClientsService } from './service-clients.service';

@Injectable({
  providedIn: 'root'
})
export class IioService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  private repairState: string;
  private directiveStates = DirectiveStateConstant.directive_states;

  constructor(
    private clientsService: ServiceClientsService
    ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isDirectiveState(stateCode?: string) {
    let isIIOEligible = false;
    
    if (stateCode && stateCode !== '') {
      this.repairState = stateCode;
    } else {
      this.repairState = this.clientsService.getParticipantDefaultState();
    }

    if (this.repairState !== '') {
     isIIOEligible = this.directiveStates.some(state => state.stateCode === this.repairState);
    }

    return isIIOEligible;
  }

}
