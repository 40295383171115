<!-- The below HTML can be placed within another component, and then displayed within a modal using this component -->

<!-- ERROR
<app-modal #modal-error>
  <div class="error-image" alt=""></div>
  <p>Sorry were having technical problems. Please try again or call 1-800-SATEFARM for other ways to get your estimate.</p>
  <button type="submit" class="-oneX-btn-secondary" name="isTotalLoss" (click)="isTotalLoss()">Yes</button>
</app-modal>
-->

<!-- USE THIS SHOP
<app-modal #modal-use-this-shop>
  <p>We will notify your shop and update Claim {claimNumber} {autoMake}.</p>
  <p>{autoShopName}<br>
     {autoShopAddress1}<br>
     {autoShopAddress2}
  </p>
  <button type="submit" class="-oneX-btn-secondary" name="isTotalLoss" (click)="confirmSelectShop()">Confirm</button>
  <a href="#" (click)="cancelSelectShop()"></a>
</app-modal>
-->

<div id="app-modal"
     class="modal fade -oneX"
     tabindex="-1"
     role="dialog"
     (click)="onContainerClicked($event)"
     [ngClass]="{'in': visibleAnimate}"
     [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
  <div id="{{modalId}}" [ngClass]="{'-oneX-modal__container' : isAlternateModal, 'modal-dialog' : !isAlternateModal}">
    <div id="{{modalId}}" [ngClass]="{'-oneX-modal-content' : isAlternateModal, 'modal-content' : !isAlternateModal}" cdkTrapFocus>
      <button *ngIf="isAlternateModal" class="-oneX-close-X-button" (click)="hide()" onclick="return false;">
        <span class="-oneX-icon--close"></span>
      </button>
      <div class="modal-body">
        <ng-content select=".modal-body"></ng-content>
      </div>
    </div>
  </div>
</div>
