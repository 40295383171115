import { ProviderRequest } from './provider-request.model';
import { Injectable } from '@angular/core';

@Injectable()
export class UrlParams {
  externalClaimId: string;
  externalClientId: string;
  internalClaimId: string;
  internalClientId: string;
  role: string;
  authentication: string;
  isBookmarked?: boolean;
  isRefreshed?: boolean;
  participantId?: string;
  zipCode?: string;
  radius?: string;
  searchTerm?: ProviderRequest;
  lat?: number;
  lng?: number;
  driverCity?: string;
  driverState?: string;
  vehicle?: number;
}
