<div id="notice" class="-oneX" [ngClass]="{'-oneX-d-none' : hideNotice}" tabindex="-1">

    <div id="privacyptext63523" class="-oneX-global-alert-body optccpatext">
        <p id="optpara" class="privacy-para">
            <strong class="privacy-strong">We value your privacy.</strong> We may collect personal information
            from
            you for business, marketing, and commercial purposes.

            <a href="javascript:void(0)" id="optccpaReadmore" class="optcollapsible -oneX-link--block active"
                aria-label="privacy Read more" (click)="expand()"> Read
                more
            </a>

            <br>
            <br>
            <a href="https://www.statefarm.com/customer-care/privacy-security/privacy/california-privacy-rights/do-not-sell"
                class="-oneX-link--block">Do Not Sell My Personal Information (CA residents only)</a>
        </p>

    </div>

    <div class="close-div">
        <button id="optclosebtn" class="-oneX-close-X-button" aria-label="privacy close" (click)="close()"> <span
                class="">X</span></button>
    </div>

</div>
<div id="notice-expand" class="-oneX" [ngClass]="{'-oneX-d-none' : hideNoticeExpand}" tabindex="-1">

    <div id="privacyptext25170" class="-oneX-global-alert-body optccpatext">

        <div id="opttestexpand" class="optcontent">
            <p><strong class="expand-strong">We value your privacy.</strong> We may collect personal information
                from you such as identifying information (name, address, driver's license number), transactional
                information (products or services purchased and payment history), digital network activity
                (interactions
                with our website, IP address), geo-location data, audio recordings and other forms of personal
                information. We use this information for business, marketing and commercial purposes, including but
                not
                limited to, providing the products and services you request, processing your claims, protecting
                against
                fraud, maintaining security, confirming your identity and offering you other insurance and financial
                products.</p>
            <p>For California residents, click <a id="optccpacaliflink-"
                    href="https://www.statefarm.com/customer-care/privacy-security/privacy/state-privacy-rights"
                    name="optccpacaliflink" class="-oneX-link--block active">here</a> to view the full version of
                the
                California Consumer Privacy Notice.</p>
            <span>Para español, haga clic <a id="optccpaSpanishlink-"
                    href="https://es.statefarm.com/atencion-al-cliente/privacidad-seguridad/privacidad/derechos-de-privacidad-del-estado"
                    name="optccpaSpanishlink" class="-oneX-link--block active">aquí</a> para ver la versión completa
                del
                Aviso de Privacidad del Consumidor de California.</span>

            <a href="javascript:void(0)" id="optccpaReadless" class="optcollapsible -oneX-link--block active"
                aria-label="privacy Read less" (click)="collapse()">Read
                less
            </a>


        </div>
    </div>

    <div class="close-div">
        <button id="optclosebtnexpand" class="-oneX-close-X-button" aria-label="privacy close" (click)="close()">
            <span class="" role="img">X</span></button>
    </div>

</div>