<google-map width="100%" height="100%" [zoom]="youAreHereMarker?.radius" [center]="youAreHereMarker?.latlng"
  [options]="options" (tilesloaded)="setFitBounds()">
  <map-marker *ngIf="youAreHereMarker" id="centroid" [position]="youAreHereMarker?.latlng" [clickable]="false"
    [title]="'title'" [options]="youAreHereMarkeroptions">
  </map-marker>
  <map-marker #shopMarker="mapMarker" *ngFor="let shop of sortedShops | slice:shopIndexStart:shopIndexEnd; let i=index"
    [position]="shop | shopPosition" [options]="shop | shopMarker:shopIndexStart:i:selectedShopIndex"
    (mapClick)="openInfoWindow(shopMarker, i)">
  </map-marker>
  <map-info-window>
    <div>
      <span style="font-weight: bold;">{{shopInfo?.name | titlecase}} </span>
      <span>({{shopInfo?.distance | number : '1.0-1' }} miles)</span>
    </div>
    <div>
      <app-shop-star-rating [location]=shopInfo?.placeDetails [index]="starIndex" [type]="'map'"></app-shop-star-rating>
    </div>
  </map-info-window>
</google-map>