import {
  Component,
  OnInit,
  AfterViewInit,
  Inject
} from '@angular/core';
import { WINDOW } from '../service/window.service';
import { DeviceService } from '../service/device.service';
import { RentalService } from '../service/rental.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { StorageService } from 'app/service/storage.service';
import { InteractionsService } from '../service/interactions.service';

@Component({
  selector: 'app-rental-confirmation',
  templateUrl: './rental-confirmation.component.html',
  styleUrls: ['./rental-confirmation.component.css']
})
export class RentalConfirmationComponent implements OnInit, AfterViewInit {
  currentPage = 'rental-confirmation';
  isDeviceMobile: boolean;
  vendorType: string;

  rentalAssignmentRequestNumber: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private deviceService: DeviceService,
    private rentalService: RentalService,
    private dataAnalyticsService: DataAnalyticsService,
    private urlResolverService: UrlResolverService,
    private storageService: StorageService,
    private interactionsService: InteractionsService,
  ) {  }

  ngOnInit() {
    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);
    this.vendorType = this.rentalService.getVendorType();
    this.rentalAssignmentRequestNumber = JSON.parse(this.storageService.getSessionStorage('rentalAssignmentRequestNumber') || null);
  }

  ngAfterViewInit() {
    let screenId = '';
    if (this.vendorType === 'Hertz') {
      screenId = 'ra-confirmation-hertz';
      this.dataAnalyticsService.sendPageData(screenId);
    } else {
      screenId = 'ra-confirmation-erac'
      this.dataAnalyticsService.sendPageData(screenId);
    }
    this.interactionsService.sendInteractions('Rental Self Service One Touch complete', 'Auto Rental');
  }

  redirect() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }
}
