import { Injectable } from '@angular/core';
import { Address } from '../model/address.model';
import { Data } from '../model/data.model';
import { Name } from '../model/name.model';
import { Phone } from '../model/phone.model';
import { Provider } from '../model/provider.model';
import { SearchByCityOrZip } from '../model/search-by-city-or-zip.model';
import { SearchLocator } from '../model/search-locator.model';
import { WalletDetails } from '../model/wallet-details.model';
import { MsoConstant } from '../constant/mso-constants';
import { SearchByName } from 'app/model/search-by-name.model';
import { SecondaryAssignment } from 'app/model/secondary-assignment.model';
import { UrlResolverService } from './url-resolver.service';

@Injectable()
export class ConversionService {

    constructor (
      private urlResolverService: UrlResolverService
    ) { }

    convertThirdPartiesDataToProviders(searchByCityOrZipResult: SearchByCityOrZip) {
        const providers_arr: Provider[] = [];
        let lat = '0';
        let lng = '0';

        if (searchByCityOrZipResult.statusCode === 200 && searchByCityOrZipResult.message.length > 0 && searchByCityOrZipResult.message[0].info === 'Success') {
            searchByCityOrZipResult.data.forEach(shop_data => {
                const provider: Provider = new Provider();
                provider.id = this.getRoleThirdPartyId(shop_data);
                provider.estimatingId = this.getProviderShopEstimateId(shop_data);
                if (shop_data.walletDetails.length > 0) {
                    const detail = shop_data.walletDetails[0];
                    provider.name = this.getShopName(detail);
                    provider.address = this.getShopAddress(detail);
                    provider.phones = this.getShopPhone(detail);
                    provider.distance = '0';
                    provider.performanceScore = 0;
                    if (provider.address.latitude !== '0' || provider.address.longitude !== '0') {
                        lat = provider.address.latitude;
                        lng = provider.address.longitude;
                    }
                }
                providers_arr.push(provider);
            });
        }
        return {
            providers: providers_arr,
            lat: lat,
            lng: lng
        };
    }

    convertSearchLocatorDataToProviders(searchLocator: SearchLocator) {
        const providers_arr: Provider[] = [];
        const serachOnlyFlow = (this.urlResolverService.getClaimId() != undefined && this.urlResolverService.getClientId() != undefined);

        if (searchLocator.statusCode === 200 && searchLocator.message.length > 0 && searchLocator.message[0].info === 'Success') {
            searchLocator.data.forEach(shop_data => {
                const provider: Provider = new Provider();
                provider.id = shop_data.thirdPartyId;
                provider.estimatingId = this.getProviderShopEstimateId(shop_data);
                provider.certifications = this.getShopCertifications(shop_data);
                if (shop_data.walletDetails && shop_data.walletDetails.length > 0) {
                    const detail = shop_data.walletDetails[0];
                    provider.name = this.getProviderShopName(detail);
                    provider.address = this.getShopAddress(detail);
                    provider.phones = this.getShopPhone(detail);
                    provider.unavailable = this.getProviderAvailability(detail);
                    if (this.blockThisShop(shop_data) && serachOnlyFlow){
                      return;
                    }
                }
                if (shop_data.locationDetails && shop_data.locationDetails.length > 0) {
                    provider.distance = shop_data.locationDetails[0].mileage;
                    provider.performanceScore = parseInt(shop_data.locationDetails[0].performanceScore, 10);
                }
                if (MsoConstant.mso_ids.includes(this.getMsoId(shop_data)) && this.getSelectServiceRankNumber(shop_data) > 0.505) {
                    provider.nationalProvider = true;
                }
                providers_arr.push(provider);
            });
        }
        return providers_arr;
    }

    convertSearchByNameDataToProviders(searchByName: SearchByName) {
        const providers_arr: Provider[] = [];
        const serachOnlyFlow = (this.urlResolverService.getClaimId() != undefined && this.urlResolverService.getClientId() != undefined);

        if (searchByName.statusCode === 200 && searchByName.message.length > 0 && searchByName.message[0].info === 'Success') {
            searchByName.data.forEach(shop_data => {
                const provider: Provider = new Provider();
                provider.id = shop_data.thirdPartyId;
                provider.estimatingId = this.getProviderShopEstimateId(shop_data);
                provider.certifications = this.getShopCertifications(shop_data);
                if (shop_data.walletDetails && shop_data.walletDetails.length > 0) {
                    const detail = shop_data.walletDetails[0];
                    provider.name = this.getProviderShopName(detail);
                    provider.address = this.getShopAddress(detail);
                    provider.phones = this.getShopPhone(detail);
                    provider.unavailable = this.getProviderAvailability(detail);
                    if (this.blockThisShop(shop_data) && serachOnlyFlow){
                      return;
                    }
                }
                if (shop_data.locationDetails && shop_data.locationDetails.length > 0) {
                    provider.distance = shop_data.locationDetails[0].mileage;
                    provider.performanceScore = parseInt(shop_data.locationDetails[0].performanceScore, 10);
                }
                if (MsoConstant.mso_ids.includes(this.getMsoId(shop_data)) && this.getSelectServiceRankNumber(shop_data) > 0.505) {
                    provider.nationalProvider = true;
                }
                providers_arr.push(provider);
            });
        }
        return providers_arr;
    }

    private getRoleThirdPartyId(data: Data): string {
        if (data.repairFacilityDetails.length > 0 && data.repairFacilityDetails[0].repairFacility.length > 0) {
            return data.repairFacilityDetails[0].repairFacility[0].roleThirdPartyId;
        }
        return '';
    }

    private getMsoId(data: Data): string {
        if (data.repairFacilityDetails && data.repairFacilityDetails.length > 0 && (data.repairFacilityDetails[0].msoData && data.repairFacilityDetails[0].msoData.length > 0)) {
            return data.repairFacilityDetails[0].msoData[0].msoId;
        }
        return '';
    }

    private getSelectServiceRankNumber(data: Data): number {
        if (data.locationDetails && data.locationDetails.length > 0) {
            return parseFloat(data.locationDetails[0].selectServiceRankNumber);
        }
        return 0;
    }

    private getProviderShopEstimateId(data: Data): string {
        if (data.repairFacilityDetails && data.repairFacilityDetails.length > 0 && data.repairFacilityDetails[0].repairFacility && data.repairFacilityDetails[0].repairFacility.length > 0) {
            for (let i = 0; i < data.repairFacilityDetails[0].repairFacility.length; i++) {
                const repairFacility = data.repairFacilityDetails[0].repairFacility[i];
                if (repairFacility.externalReferences && repairFacility.externalReferences.length > 0) {
                    for (let j = 0; j < repairFacility.externalReferences.length; j++) {
                        const ref = repairFacility.externalReferences[j];
                        if ('Estimating ID' === ref.externalReferenceType) {
                            return ref.externalReferenceValue;
                        }
                    }
                }
            }
        }
        return '';
    }

    private getShopName(walletDetail: WalletDetails): Name {
        const name: Name = {
            first: '',
            last: ''
        };

        if (walletDetail.names.length > 0) {
            name.last = walletDetail.names[0].lastName;
        }
        return name;
    }

    private getProviderShopName(walletDetail: WalletDetails): Name {
        const name: Name = {
            first: '',
            last: ''
        };
        if (walletDetail.names && walletDetail.names.length > 0) {
            for (let i = 0; i < walletDetail.names.length; i++) {
                if (walletDetail.names[i].usage === 'Common') {
                    name.last = walletDetail.names[i].lastName;
                    break;
                }
            }

        }
        return name;
    }

    private getShopInfoShopName(walletDetail: WalletDetails) {
        if (walletDetail.names && walletDetail.names.length > 0) {
            for (let i = 0; i < walletDetail.names.length; i++) {
                if (walletDetail.names[i].usage === 'Common') {
                    return walletDetail.names[i].lastName;
                }
            }
        }
        return '';
    }

    // b2b field is assigned true if shop is not available for selection
    private getProviderAvailability(walletDetail: WalletDetails) {
        let unavailable = false;
        if (walletDetail.roles && walletDetail.roles.length > 0) {
            for (let i = 0; i < walletDetail.roles.length; i++) {
                if (walletDetail.roles[i].roleId === 'ROLE0010118') {
                    unavailable = true;
                    break;
                }
            }
        }
        return unavailable;

    }

    //shop is blocked from search results if shop matches with particular MSO Id
    private blockThisShop(data: Data): boolean{
      let blockShop = false;
      const msoId = this.getMsoId(data);
      if (msoId === '00001131') {
        blockShop = true;
      }
      return blockShop;
    }

    private getShopAddress(walletDetail: WalletDetails): Address {
        const address = new Address('', '', '', '');
        if (walletDetail.postalAddresses && walletDetail.postalAddresses.length > 0) {
            for (let i = 0; i < walletDetail.postalAddresses.length; i++) {
                if (walletDetail.postalAddresses[i].addressUsage === 'Business') {
                    const addr = walletDetail.postalAddresses[i];
                    address.line = addr.address;
                    address.city = addr.city;
                    address.state = addr.state;
                    address.postalCode = addr.postalZipCode;
                    address.latitude = addr.latitude;
                    address.longitude = addr.longitude;
                    break;
                }
            }
        }
        return address;
    }

    private getShopPhone(walletDetail: WalletDetails): Phone[] {
        const phone = [];
        if (walletDetail.telephones && walletDetail.telephones.length > 0) {
            for (let i = 0; i < walletDetail.telephones.length; i++) {
                const ph = walletDetail.telephones[i];
                const p = new Phone(ph.areaCode, '', ph.lineNumber, ph.usage, ph.equipmentType);
                phone.push(p);
            }
        }
        return phone;
    }

    private getShopCertifications(data: Data): object[] {
        const certifications = [];
        if (data.repairFacilityDetails && data.repairFacilityDetails.length > 0 && data.repairFacilityDetails[0].repairFacility && data.repairFacilityDetails[0].repairFacility.length > 0) {

            for (let i = 0; i < data.repairFacilityDetails[0].repairFacility.length; i++) {
                const repairFacility = data.repairFacilityDetails[0].repairFacility[i];
                if (repairFacility.providedServices && repairFacility.providedServices.length > 0) {
                    for (let j = 0; j < repairFacility.providedServices.length; j++) {
                        const ref = repairFacility.providedServices[j];
                        if (repairFacility.providedServices[j].serviceTypeCode && parseInt(repairFacility.providedServices[j].serviceTypeCode, 10) > 199 && parseInt(repairFacility.providedServices[j].serviceTypeCode, 10) < 300) {
                            certifications.push({ servType: repairFacility.providedServices[j].serviceType });
                        }
                    }
                }
            }
        }
        return certifications;
    }

}
