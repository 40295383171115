import { Injectable } from '@angular/core';
import { FeatureFlags } from '../model/feature-flags.model';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';


@Injectable()
export class FeatureFlagResolverService implements OnDestroy {

  // default flags to return if there is an error with the http get call
  defaultFeatureFlags: FeatureFlags = {
    redirect_to_pca: true,
    click_to_chat_enabled: true,
    click_to_chat_search: true,
    click_to_chat_results: true,
    click_to_chat_confirmation: true
  };

  private flags = new BehaviorSubject(this.defaultFeatureFlags);
  private flagsWithErrorDefault = new ReplaySubject<FeatureFlags>(1)
  private ngUnsubscribe = new Subject();
  constructor(private http: HttpClient) {
    const url = '/assets/maintenance/feature-flags.json';

    this.http.get<FeatureFlags>(url)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        l => {this.flags.next(l);
              this.flagsWithErrorDefault.next(l)},
        (err) => {console.log('error', `Failed to resolve Feature flags: ${err.toString()}`)
                  this.flagsWithErrorDefault.next(this.defaultFeatureFlags)});
  }
  /**
  * Makes call to retrieve feature flags and returns the Observable.
  * The default object with the flags defined above is emitted first (Per the definition of BehaviorSubject).
  * If there is an error with the http get call, no other values are emitted.
  */
  public getFeatureFlags(): Observable<FeatureFlags> {
    return this.flags.pipe(takeUntil(this.ngUnsubscribe));
  }

  /**
  * Makes call to retrieve feature flags and returns the Observable. On response the flags object is emitted.
  * If there is an error with the http get call, the default object with the feature flags defined above
  * is emitted instead.
  */
  public getFeatureFlagsWithErrorDefault(): Observable<FeatureFlags> {
    return this.flagsWithErrorDefault.pipe(takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

  }
}
