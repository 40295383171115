export class ProviderRequest {
  name?: string;
  state?: string;
  city?: string;
  postalCode?: string;
  streetAddress?: string;
  radius?: string;
  searchTerm?: string;
  isZipCodeSearch?: boolean;
  isStreetAddressSearch?: boolean;
  isNameSearch?: boolean;
  isSearchAgain?: boolean;
  isSearchAgainByZipCode?: boolean;
  isCityStateSearch?: boolean;
  isLatLongSearch?: boolean;
  filterBy?: string;
  type?: string;
  latitude?: string;
  longitude?: string;
  vendorType?: string;
  makeValue?: string;
}
