import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from 'app/service/auth.service';
import { JWTTokenService } from 'app/service/jwt.token.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class JWTTokenGuard  {

  constructor(
    private jwtTokenService: JWTTokenService,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const extClaimId: string = this.getParams(next, 'claim');
    const extClientId: string = this.getParams(next, 'client');
    const path: string = next.url[0].path;

    if (extClaimId && extClientId) {

      if (this.jwtTokenService.isJWTTokenExist()) {
        /**
       * Below snippet indicates that if the user is redirected from other sites and we are
       * unsure how much time they have been in and redirected to RA.
       * Hence irrespective of the time they are in other sites, we are refreshing the OKTA
       * token once they come into RA to track the user activity
       * As the userIdle watching activity will start monitoring in our app.component.ts
       */
        if (this.cookieService.get('sf-cauth1') && this.cookieService.get('sf-cauth2')) {
          return this.authService.authenticateUser(extClaimId, extClientId).pipe(           
            switchMap(response => {
              // if response status is 201, it means a okta jwt loa2 token has been created. It implies that the original okta jwt token is invalid/expired
              if (response.status === 201) {
                this.jwtTokenService.deleteJWTToken(extClaimId, extClientId);
                this.jwtTokenService.setJWTToken('sf-cauth-lite', response.body['Identity'].bearerToken.substring(7), extClaimId, extClientId);
                return of(true);
              } else {
                if (response.body['Identity'].bearerToken) {
                  if (path === 'shopsearch' || 'landing') {
                    this.jwtTokenService.refreshJWTtoken();
                  }
                  this.authService.setUserLoggedIn(true);
                  return of(true);
                }
              }
            }),
            catchError(() => {
              // when there is an error to identify user authentication
              this.jwtTokenService.deleteJWTToken();
              return this.setJWTLiteAuth(extClaimId, extClientId);
            })
          );
        } else {
          if (this.cookieService.get('sf-cauth-lite')) {
            const token = this.cookieService.get('sf-cauth-lite');
            return this.jwtTokenService.getJwtVerify(token).pipe(
              mergeMap((res) => {
                if (res.loa === 2) {
                  this.authService.setUserLoggedIn(false);
                  return of(true);
                } else {
                  return this.setJWTLiteAuth(extClaimId, extClientId);
                }
              }),
              catchError(() => {
                // when there is an error to verify token
                return this.setJWTLiteAuth(extClaimId, extClientId);
              })
            );
          }
        }
      } else {
        // when there is no jwt token exist
        return this.setJWTLiteAuth(extClaimId, extClientId);
      }
    } else {
      return of(true);
    }
  }

  getParams(route: ActivatedRouteSnapshot, key: string) {
    return route.queryParams[key] || route.params[key];
  }


  setJWTLiteAuth(extClaimId: string, extClientId: string): Observable<boolean | UrlTree> {
    return this.jwtTokenService.getJwtToken(extClaimId, extClientId).pipe(
      switchMap(authJwtTokenResponse => {
        this.jwtTokenService.setJWTToken('sf-cauth-lite', authJwtTokenResponse['token'], extClaimId, extClientId);
        // while getting a jwt lite token, it implies that the user is not logged in
        this.authService.setUserLoggedIn(false);
        return of(true);
      }),
      catchError(() => {
        return of(this.router.createUrlTree(['/autherrorpage']));
      })
    );
  }
}



